import { FC, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";

import { Document } from "../../../../store/modules/documents/types";
import EyeIcon from "../../../assets/icons/EyeIcon";
import EditIcon from "../../../assets/icons/EditIcon";
import DownloadIcon from "../../../assets/icons/DownloadIcon";
import TrashIcon from "../../../assets/icons/TrashIcon";

// import fileDownload from "js-file-download";
import { useDeleteBorrowerDocumentMutation } from "../../../../services/rtkQuery/documents";
import { CreateBorrowerDocumentModal } from "./СreateBorrowerDocumentModal";
import { useEditBorrowerDocument } from "../../../../hooks/useEditBorrowerDocument";
import { DeleteConfirmationModal } from "../../../components/DeleteConfirmationModal";
import { prepareDownloadAttributes } from "../../../../utill/helpers";

interface BorrowerDocumentsListTableRowProps {
  documentItem: Document;
}

export const BorrowerDocumentsListTableRow: FC<BorrowerDocumentsListTableRowProps> = ({
  documentItem,
}) => {
  const [isOpenEditModal, setIsEditModalOpen] = useState(false);
  const [file, setFile] = useState({
    url: documentItem.url,
    name: documentItem.title,
  });
  const [showDelete, setShowDelete] = useState(false);
  const { href, download } = prepareDownloadAttributes(documentItem);

  const [deleteBorrowerDocument, { isSuccess: isDeletingDocumentSuccess }] =
    useDeleteBorrowerDocumentMutation();

  const openInNewTab = useCallback(() => {
    if (documentItem.url) {
      const newWindow = window.open(documentItem.url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    }
  }, [documentItem.url]);

  // const downloadFile = useCallback(() => {
  //   if (documentItem.url) {
  //     const fileExtension = documentItem.url.split(".").pop();
  //     fileDownload(documentItem.url, documentItem.title + `.${fileExtension}`);
  //   }
  // }, [documentItem]);

  const deleteDocument = () => {
    if (documentItem?.id) {
      deleteBorrowerDocument(documentItem.id);
    }
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const openEditModal = () => {
    setIsEditModalOpen(true);
  };

  const { control, handleSubmit, categoriesOptions, errors } = useEditBorrowerDocument(
    documentItem.id,
    closeEditModal
  );

  useEffect(() => {
    if (isDeletingDocumentSuccess) {
      toast.success("Document was deleted");
    }
  }, [isDeletingDocumentSuccess]);

  return (
    <tr className="flex w-full justify-between gap-[10px] my-3">
      <td className="flex items-center gap-1 w-[25%]">
        <div className="flex flex-col items-start ml-2">
          <p className="text-[15px] font-medium text-[#474747] text-left">{documentItem.title}</p>
        </div>
      </td>
      <td className="flex items-center justify-left gap-1 w-[25%]">
        <p className="text-left  text-[15px] text-[#474747]">{documentItem?.Category?.name}</p>
      </td>
      <td className="flex items-center justify-left gap-1 w-[25%]">
        <p className="text-start text-[15px] w-full text-[#474747]">
          {moment(documentItem.createdAt).format("dddd")}
        </p>
      </td>

      <td className="flex items-center justify-end gap-2 w-[25%]">
        <button
          onClick={openInNewTab}
          className="flex w-[30px] h-[30px] p-1 justify-center items-center gap-8 bg-[#EE8C58] rounded-lg"
        >
          <EyeIcon />
        </button>
        <button
          onClick={openEditModal}
          className="flex  w-[30px] h-[30px] p-1 justify-center items-center gap-8 bg-[#434784] rounded-lg"
        >
          <EditIcon />
        </button>
        <a
          href={href}
          download={download || undefined}
          target="_blank"
          rel="noopener noreferrer"
          // onClick={downloadFile}
          className="flex  w-[30px] h-[30px] p-1 justify-center items-center gap-8 bg-[#434784] rounded-lg"
        >
          <DownloadIcon />
        </a>
        <button
          onClick={() => setShowDelete(true)}
          className="flex w-[30px] h-[30px] p-1 justify-center items-center gap-8 bg-[#E04D4D] rounded-lg"
        >
          <TrashIcon />
        </button>
      </td>
      {showDelete && (
        <DeleteConfirmationModal
          isOpenModal={showDelete}
          onDelete={deleteDocument}
          title="Confirmation of Document Deletion"
          text="Are you sure you want to delete this document? Deletion is an irreversible action and will result in the removal of all saved data for this document. Please confirm your decision."
          closeModal={() => setShowDelete(false)}
        />
      )}
      {isOpenEditModal && (
        <CreateBorrowerDocumentModal
          isOpenModal={isOpenEditModal}
          closeModal={closeEditModal}
          action="edit"
          control={control}
          handleSubmit={handleSubmit}
          categories={categoriesOptions}
          file={file}
          setFile={setFile}
          errors={errors}
        />
      )}
    </tr>
  );
};
