import React, { FC } from "react";
import { SearchInput } from "../../../components/SearchInput";
import { Option, SelectBox } from "../../../ui/common/SelectBox";
import DataPiker from "../../../components/DatePicker";
import moment from "moment";

interface CharterSchoolsFiltersProps {
  value: string;
  withAllSelection?: boolean;
  setValue: (value: string | number) => void;
  options: Option[];
  onSelect: (e: Option | Option[]) => void;
  selectedValue: Option;
  assignedDate: Date | null;
  setAssignedDate: (date: any) => void;
}

export const CharterSchoolsFilters: FC<CharterSchoolsFiltersProps> = ({
  value,
  setValue,
  options,
  onSelect,
  selectedValue,
  withAllSelection,
  assignedDate,
  setAssignedDate,
}) => {
  return (
    <div className="flex justify-between items-center gap-2">
      <div className="w-[70%]">
        <SearchInput
          value={value}
          placeholder="Search by School Partner"
          type="text"
          onChange={setValue}
          icon={false}
        />
      </div>
      <div className="w-[40%] flex justify-between">
        <SelectBox
          withAllSelection={withAllSelection}
          value={selectedValue}
          onChange={onSelect}
          options={options}
          placeholder="All Assigned School Partners"
          multiple={false}
        />
        {/* <DataPiker
          value={assignedDate}
          label="Assigned Date"
          onChange={(date) => setAssignedDate(moment(date).format("YYYY-MM-DD"))}
        /> */}
      </div>
    </div>
  );
};
