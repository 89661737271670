import React from "react";

const CheckIcon = ({ color }: { color?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="check" clip-path="url(#clip0_578_25482)">
        <path
          id="Vector"
          d="M7.50001 13.4751L4.02501 10.0001L2.84167 11.1751L7.50001 15.8334L17.5 5.83345L16.325 4.65845L7.50001 13.4751Z"
          fill={color || "#95B7A2"}
        />
      </g>
      <defs>
        <clipPath id="clip0_578_25482">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckIcon;
