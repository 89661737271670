import React from "react";

const DownloadIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M10.3715 7.9696L7.99983 10.3413L5.62817 7.9696"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99998 3.22696V10.3413"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3354 11.5263C13.3354 12.8361 12.2736 13.898 10.9638 13.898H5.03596C3.72613 13.898 2.66431 12.8361 2.66431 11.5263"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownloadIcon;
