import { useForm } from "react-hook-form";
import { Option } from "../view/ui/common/SelectBox";
import {
  useCreateBorrowerAsBorrowerMutation,
  useCreateBorrowerMutation,
} from "../services/rtkQuery/borrower";
import { useEffect, useMemo } from "react";

import { yupResolver } from "@hookform/resolvers/yup";

import { getGeneralSelectBoxSchema, getUserSchema } from "../utill/validation/validationSchemas";
import { useGetOrganizationsQuery } from "../services/rtkQuery/organization";
import { useAppSelector } from "../store/hooks";
import { selectOrganizationUsers } from "../store/modules/organization/selectors";
import { getOptions } from "../utill/helpers";
import { selectUser } from "../store/modules/user/selectors";

const borrowerUserSchema = getUserSchema().shape({});

export interface CreateBorrowerData {
  fullName: string;
  email: string;
  password: string;
  role: Option;
  organization: Option;
}
export const useCreateBorrowerUser = (onSuccess: () => void) => {
  const user = useAppSelector(selectUser);
  const isBorrowerAdmin = user?.type === "borrower_admin";
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fullName: "",
      email: "",
      password: "",
      role: { value: "", label: "" },
      organization: { value: "", label: "" },
    },
  });
  useGetOrganizationsQuery({ getAll: true });
  const [createBorrower, { isSuccess }] = useCreateBorrowerMutation();
  const [createBorrowerAsBorrower, { isSuccess: isSuccessBorrower }] =
    useCreateBorrowerAsBorrowerMutation();

  const organizationList = useAppSelector(selectOrganizationUsers);

  const organizationOptions = useMemo(() => {
    if (organizationList && organizationList.rows) {
      return getOptions(organizationList?.rows, "name", "id");
    }
    return [];
  }, [organizationList?.rows]);

  useEffect(() => {
    if (isSuccess) {
      onSuccess?.();
    }
  }, [isSuccess]);

  const onSubmit = (data: CreateBorrowerData) => {
    const payload = {
      fullName: data.fullName,
      email: data.email,
      password: data.password,
      role: data.role.value,
    };
    if (isBorrowerAdmin) {
      createBorrowerAsBorrower(payload);
    } else {
      createBorrower({ ...payload, organizationId: data.organization.value });
    }
  };

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    organizationOptions,
  };
};
