import React from "react";

const TrashIcon = ({ fill }: { fill?: any }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66675 3.0625C6.62254 3.0625 6.58015 3.08006 6.5489 3.11132C6.51764 3.14257 6.50008 3.18496 6.50008 3.22917V4.72917H9.50008V3.22917C9.50008 3.18496 9.48252 3.14257 9.45127 3.11132C9.42001 3.08006 9.37762 3.0625 9.33341 3.0625H6.66675ZM3.87681 5.72917L4.49835 13.1876C4.49951 13.2015 4.50008 13.2153 4.50008 13.2292C4.50008 13.4502 4.58788 13.6621 4.74416 13.8184C4.90044 13.9747 5.1124 14.0625 5.33341 14.0625H10.6667C10.8878 14.0625 11.0997 13.9747 11.256 13.8184C11.4123 13.6621 11.5001 13.4502 11.5001 13.2292C11.5001 13.2153 11.5007 13.2015 11.5018 13.1876L12.1233 5.72917H3.87681ZM13.1268 5.72917L12.4999 13.2516C12.4941 13.7297 12.3016 14.187 11.9631 14.5255C11.6193 14.8693 11.153 15.0625 10.6667 15.0625H5.33341C4.84718 15.0625 4.38087 14.8693 4.03705 14.5255C3.69853 14.187 3.50606 13.7297 3.50022 13.2516L2.87335 5.72917H2.66675C2.39061 5.72917 2.16675 5.50531 2.16675 5.22917C2.16675 4.95302 2.39061 4.72917 2.66675 4.72917H5.50008V3.22917C5.50008 2.91975 5.623 2.623 5.84179 2.40421C6.06058 2.18542 6.35733 2.0625 6.66675 2.0625H9.33341C9.64283 2.0625 9.93958 2.18542 10.1584 2.40421C10.3772 2.623 10.5001 2.91975 10.5001 3.22917V4.72917H13.3334C13.6096 4.72917 13.8334 4.95302 13.8334 5.22917C13.8334 5.50531 13.6096 5.72917 13.3334 5.72917H13.1268ZM6.66675 7.39583C6.94289 7.39583 7.16675 7.61969 7.16675 7.89583V11.8958C7.16675 12.172 6.94289 12.3958 6.66675 12.3958C6.39061 12.3958 6.16675 12.172 6.16675 11.8958V7.89583C6.16675 7.61969 6.39061 7.39583 6.66675 7.39583ZM9.33341 7.39583C9.60956 7.39583 9.83341 7.61969 9.83341 7.89583V11.8958C9.83341 12.172 9.60956 12.3958 9.33341 12.3958C9.05727 12.3958 8.83341 12.172 8.83341 11.8958V7.89583C8.83341 7.61969 9.05727 7.39583 9.33341 7.39583Z"
        fill={fill ? fill : "white"}
      />
    </svg>
  );
};

export default TrashIcon;
