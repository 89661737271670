import React, { FC } from "react";
import { Organization } from "../../../../store/modules/employee/types";
import { DocumentTableRow } from "./DocumentTableRow";

interface DocumentsTableBodyProps {
  documentsList: any[];
}

export const DocumentsTableBody: FC<DocumentsTableBodyProps> = ({
  documentsList,
}) => {
  return (
    <tbody className="w-full gap-[10px] items-center text-center">
      {(documentsList || []).map((document: Organization) => (
        <DocumentTableRow document={document} />
      ))}
    </tbody>
  );
};
