import React, { FC, useEffect, useState } from "react";
import CheckIcon from "../../../assets/icons/CheckIcon";

interface PasswordRulesProsp {
  password: string;
}
export const PasswordRules: FC<PasswordRulesProsp> = ({ password }) => {
  const [ruleChecks, setRuleChecks] = useState({
    minLength: false,
    hasUpperCase: false,
    hasNumber: false,
    hasSpecialChar: false,
  });

  useEffect(() => {
    if (password.length >= 8) {
      setRuleChecks((prevChecks) => ({
        ...prevChecks,
        minLength: true,
      }));
    } else {
      setRuleChecks((prevChecks) => ({
        ...prevChecks,
        minLength: false,
      }));
    }

    if (/[A-Z]/.test(password)) {
      setRuleChecks((prevChecks) => ({
        ...prevChecks,
        hasUpperCase: true,
      }));
    } else {
      setRuleChecks((prevChecks) => ({
        ...prevChecks,
        hasUpperCase: false,
      }));
    }

    if (/\d/.test(password)) {
      setRuleChecks((prevChecks) => ({
        ...prevChecks,
        hasNumber: true,
      }));
    } else {
      setRuleChecks((prevChecks) => ({
        ...prevChecks,
        hasNumber: false,
      }));
    }

    if (/[!@#%^&*]/.test(password)) {
      setRuleChecks((prevChecks) => ({
        ...prevChecks,
        hasSpecialChar: true,
      }));
    } else {
      setRuleChecks((prevChecks) => ({
        ...prevChecks,
        hasSpecialChar: false,
      }));
    }
  }, [password]);
  return (
    <div>
      <div className="flex w-full items-center">
        {ruleChecks.minLength && <CheckIcon />}
        <p className="ml-2">Min 8 Characters</p>
      </div>
      <div className="flex w-full items-center">
        {ruleChecks.hasUpperCase && <CheckIcon />}
        <p className="ml-2">Contains an uppercase letter</p>
      </div>
      <div className="flex w-full items-center">
        {ruleChecks.hasNumber && <CheckIcon />}
        <p className="ml-2"> Contains a number (0-9)</p>
      </div>
      <div className="flex w-full items-center">
        {ruleChecks.hasSpecialChar && <CheckIcon />}
        <p className="ml-2"> Contains spacial characters (!@#%%^&*.)</p>
      </div>
    </div>
  );
};
