import React, { FC, useState } from "react";
import CalendarIcon from "../../../assets/icons/CalendarIcon";
import moment from "moment";
import { Organization } from "../../../../store/modules/organization/types";
import { Button } from "../../../ui/common/Button";
import EditIcon from "../../../assets/icons/EditIcon";
import TrashIcon from "../../../assets/icons/TrashIcon";
import { UserPlaceholder } from "../../../components/UserPlaceholder";
import { AvatarsList } from "../../../components/AvatarsList/AvatarsList";
import { AssignBorrowerModal } from "../../Organization/components/AssignBorrowerModal";
import { DeleteConfirmationModal } from "../../../components/DeleteConfirmationModal";
import { CreateDocumentModal } from "../../Documents/components/CreateDocumentModal";
import { useCreateDocument } from "../../../../hooks/useCreateDocument";
import { AssignLenderModal } from "../../Organization/components/AssignLenderModal";

interface OrganizationInfoProps {
  organization: Organization | null;
  onDelete: () => void;
  openEdit: () => void;
  organizationId?: string;
  totalDocuments?: number;
}
export const OrganizationInfo: FC<OrganizationInfoProps> = ({
  organization,
  onDelete,
  openEdit,
  organizationId,
  totalDocuments,
}) => {
  const [showDelete, setShowDelete] = useState(false);
  const [assignBorrower, setAssignBorrower] = useState(false);
  const [showAssignLender, setShowAssignLender] = useState(false);
  const [assignLender, setAssignLender] = useState(false);
  const [isOpenOrganizationModal, setOpenOrganizationModal] = useState(false);
  const [file, setFile] = useState();

  const {
    control,
    handleSubmit,
    organizationOptions,
    categoriesOptions,
    errorUploading,
    errors,
    isFileLoading,
  } = useCreateDocument(file, closeOrganizationModal, organization?.id);

  const avatars =
    (organization && organization?.Users.map((user: any) => user.fullName)) ||
    [];

  const lenderAvatars =
    (organization &&
      organization.AssignedUser !== null && [
        organization?.AssignedUser.fullName,
      ]) ||
    [];

  const toggleShowAssignBorrower = () => {
    setAssignBorrower(!assignBorrower);
  };

  const toggleShowAssignLender = () => {
    setAssignLender(!assignLender);
  };

  const toggleDelete = () => {
    setShowDelete(!showDelete);
  };

  const openOrganizationModal = () => {
    setOpenOrganizationModal(true);
  };

  function closeOrganizationModal() {
    setOpenOrganizationModal(false);
  }

  return (
    <div className="flex gap-5 justify-between items-center">
      <div className="flex w-[75%]">
        <div className="w-[40%]">
          <div className="flex">
            <p className="text-[24px] font-medium">{organization?.name}</p>
            <div className="bg-[#E8F0EB] flex items-center px-[16px] py-[4px] h-[30px] ml-[20px]">
              <p className="text-[#95B7A2]  text-[12px]">ACTIVE</p>
            </div>
          </div>
          <p className="text-[16px]">{organization?.AssignedUser?.email}</p>
        </div>
        <div className="w-[40%] flex">
          <div className="w-[50%] flex items-start flex-col">
            <p className="mb-[8px] text-[12px] text-[#717984]">
              School Partner Contact
            </p>
            <AvatarsList
              avatarsUrl={avatars as any[]}
              onPlusClick={toggleShowAssignBorrower}
            />
          </div>
          <div className="w-[50%]">
            <p className="mb-[8px] text-[12px] text-[#717984]">Carewolf</p>
            <AvatarsList
              avatarsUrl={lenderAvatars as any[]}
              onPlusClick={toggleShowAssignLender}
              showPlus={lenderAvatars.length < 1}
            />
          </div>
        </div>
        <div className="flex justify-between w-[30%]">
          <div>
            <p className="text-[12px] text-[#717984]">Total Documents</p>
            <p className="text-[16px] font-bold mt-[8px]">{totalDocuments}</p>
          </div>
          <div>
            <p className="text-[12px] text-[#717984]">Fiscal Year End</p>
            <div className="flex items-center gap-2">
              <CalendarIcon />
              <p className="text-[16px] font-bold mt-[8px]">
                {moment(organization?.fiscalYearEnd).format("MMMM DD")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center w-[25%] justify-between">
        <div className="w-[70%]">
          <Button
            type="button"
            text="+ Upload New Document"
            onClick={openOrganizationModal}
            className="text-[15.5px] w-[60%] px-[12px] py-[12px] items-center"
          />
        </div>

        <div className="flex gap-[12px]">
          <button
            onClick={openEdit}
            className="w-[40px] h-[40px] p-[12px] justify-center items-center gap-8 bg-[#434784] rounded-lg"
          >
            <EditIcon />
          </button>
          <button
            onClick={toggleDelete}
            className=" w-[40px] h-[40px] p-[12px] justify-center items-center gap-8 bg-[#E04D4D] rounded-lg"
          >
            <TrashIcon />
          </button>
        </div>
        {showDelete && (
          <DeleteConfirmationModal
            title="Confirmation of School Partner Deletion"
            text="Are you sure you want to delete this School Partner?"
            onDelete={onDelete}
            isOpenModal={showDelete}
            closeModal={toggleDelete}
          />
        )}
        {assignBorrower && (
          <AssignBorrowerModal
            isOpenModal={assignBorrower}
            closeModal={toggleShowAssignBorrower}
            organizationId={organization?.id?.toString() ?? ""}
          />
        )}
        {assignLender && (
          <AssignLenderModal
            isOpenModal={assignLender}
            closeModal={toggleShowAssignLender}
            organizationId={(organizationId && organizationId.toString()) || ""}
          />
        )}
        {isOpenOrganizationModal && (
          <CreateDocumentModal
            isOpenModal={isOpenOrganizationModal}
            closeModal={closeOrganizationModal}
            control={control}
            handleSubmit={handleSubmit}
            organizationOptions={organizationOptions}
            categoriesOptions={categoriesOptions}
            file={file}
            setFile={setFile}
            action={"create"}
            errorUploading={errorUploading}
            errors={errors}
            isFileLoading={isFileLoading}
          />
        )}
      </div>
    </div>
  );
};
