import { FC } from "react";
import moment from "moment";

import { Organization } from "../../../../store/modules/employee/types";
import { useUnassignUserMutation } from "../../../../services/rtkQuery/organization";

interface EmployeeDetailsTableRowProps {
  organization: Organization;
}

export const EmployeeDetailsTableRow: FC<EmployeeDetailsTableRowProps> = ({ organization }) => {
  const [unassignUser] = useUnassignUserMutation();

  const unassignUserHandler = () => {
    unassignUser(organization.id);
  };
  return (
    <tr className="flex w-[100%] justify-between gap-[10px] my-3 mt-[5px] ">
      <td className="flex items-center justify-left gap-1 w-[25%]">
        <div className="flex flex-col items-start ml-2">
          <p className="flex items-center justify-center text-[15px] font-bold text-[#474747]">
            {organization.name}
          </p>
          <p className="text-[13px] text-[#717984]">{organization.AssignedUser.email}</p>
        </div>
      </td>

      <td className="flex items-center justify-left gap-1 w-[25%]">
        <p className="text-left  text-[15px] text-[#474747]">
          {moment(organization.createdAt).format("LL")}
        </p>
      </td>
      <td className="flex items-center justify-left gap-1 w-[25%]">
        <p className="text-left  text-[15px] text-[#474747]">
          {moment(organization.updatedAt).format("LL")}
        </p>
      </td>
      <td className="flex items-center text-center justify-end gap-1 w-[25%]">
        <button
          onClick={unassignUserHandler}
          className="w-[40%] h-[40px] p-[21px] justify-center items-center bg-[#434784] rounded-lg flex"
        >
          <p className="text-[#FFF]">Unassign</p>
        </button>
      </td>
    </tr>
  );
};
