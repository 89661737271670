import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ChevronIcon from "../../../assets/icons/ChevronIcon";
import CheckIcon from "../../../assets/icons/CheckIcon";

export interface Option {
  value: string;
  label: string;
}
interface SelectBoxProps {
  label?: string;
  withAllSelection?: boolean;
  placeholder: string;
  multiple?: boolean;
  options: Option[];
  value?: Option | Option[];
  onChange: (value: Option | Option[]) => void;
  placement?: "bottom" | "top";
  icon?: ReactNode;
  disabled?: boolean;
  searchEnabled?: boolean;
}

const allSelectionOption = {
  value: "",
  label: "All",
};

export const SelectBox: FC<SelectBoxProps> = ({
  options,
  multiple = true,
  value,
  onChange,
  placeholder = "Select",
  label,
  withAllSelection,
  placement = "bottom",
  icon,
  disabled = false,
  searchEnabled = true,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const ref = useRef<any>(null);
  const listRef = useRef<any>(null);
  const [searchFilter, setSearchFilter] = useState("");

  const optionList = useMemo(() => {
    if (withAllSelection) {
      return [allSelectionOption, ...options];
    }
    return options;
  }, [withAllSelection, options]);

  const toggleShowOptions = () => {
    setShowOptions(!showOptions);
    setSearchFilter("");
  };

  const handleInputChange = (event: any) => {
    setSearchFilter(event.target.value);
  };

  const renderPlacement = useMemo(() => {
    return placement === "top" ? "top-[-70px]" : "top-[50px]";
  }, [placement]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        ref.current &&
        listRef.current &&
        !ref.current.contains(event.target) &&
        !listRef.current.contains(event.target)
      ) {
        setShowOptions(false);
        setSearchFilter("");
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const selectedValue = useMemo(() => {
    if (value && Array.isArray(value) && value.length) {
      return value.map((item) => item.label).join(", ");
    }
    if (value && !Array.isArray(value)) return value.label;
    return placeholder;
  }, [value, placeholder]);

  const filteredOptionsList = useMemo(() => {
    const trimmedSearchFilter = searchFilter.trim();

    if (trimmedSearchFilter) {
      return optionList.filter((item) =>
        item.label.toLowerCase().includes(trimmedSearchFilter.toLowerCase())
      );
    }
    return optionList;
  }, [searchFilter, optionList]);

  const onSelect = useCallback(
    (event: Option) => {
      let res: Option | Option[] = event;
      if (multiple) {
        if (!Array.isArray(value)) {
          res = [];
        }
        if (Array.isArray(value)) {
          const isValueAlreadySelected = !!value.find(
            (item: Option) => item.value === event.value
          );
          res = isValueAlreadySelected
            ? value.filter((item: Option) => item.value !== event.value)
            : [...value, event];
        }
      }
      if (!multiple) {
        setShowOptions(false);
      }
      onChange(res);
      setSearchFilter("");
    },
    [multiple, value]
  );

  const renderSelectedIcon = useCallback(
    (item: Option) => {
      if (value) {
        const isSelected = Array.isArray(value)
          ? !!value.find((el) => el.value === item.value)
          : value.value === item.value;
        if (isSelected) {
          return <CheckIcon color="orange" />;
        }
      }
      return;
    },
    [value]
  );

  return (
    <div className="relative w-full">
      {label && (
        <p
          className={`${
            disabled ? "text-gray-300" : "text-gray-600"
          } font-medium text-[15px] text-left mb-[10px]`}
        >
          {label}
        </p>
      )}
      <button
        ref={ref}
        disabled={disabled}
        type="button"
        className="border-[1px] border-[lightgray] focus:border-2 rounded-[12px] px-3 py-2 w-full flex justify-between"
        onClick={toggleShowOptions}
      >
        <div className="flex flex-row">
          {icon}
          <p
            className={`text-left ${icon ? "ml-2" : ""} ${
              disabled ? "text-gray-300" : "text-gray-600"
            }`}
          >
            {selectedValue ? selectedValue : placeholder}
          </p>
        </div>
        {!disabled && <ChevronIcon />}
      </button>

      {showOptions && (
        <div
          ref={listRef}
          className={`border-[1px] border-[lightgray] absolute top-15 bg-white w-full mt-1 rounded-[12px] p-2 z-50 cursor-pointer ${renderPlacement}`}
        >
          {searchEnabled && (
            <input
              type="text"
              className="border-b border-gray-500 focus:outline-none w-full p-[4px] mb-3"
              placeholder="Search by name"
              value={searchFilter}
              onChange={handleInputChange}
            />
          )}
          <div className="max-h-[160px] overflow-y-auto">
            {filteredOptionsList?.length > 0 ? (
              filteredOptionsList.map((option) => (
                <p
                  className="flex justify-between"
                  onClick={() => {
                    onSelect(option);
                  }}
                >
                  {option.label}
                  {renderSelectedIcon(option)}
                </p>
              ))
            ) : (
              <p className="cursor-default">No results found</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
