import React, { FC } from "react";

interface DocumentsListTableProps {
  HeaderComponent: React.ElementType;
  BodyComponent: React.ElementType;
  documentsList?: any[];
}

export const DocumentsListTable: FC<DocumentsListTableProps> = ({
  HeaderComponent,
  BodyComponent,
  documentsList,
}) => {
  return (
    <table className="flex w-full flex-wrap">
      <HeaderComponent />
      <BodyComponent documentsList={documentsList} />
    </table>
  );
};
