export const API_URL =
  process.env.REACT_APP_API_URL || "https://api-dev.eqfund.org";

export const lenderUserStatus = [
  { value: "", label: "All Statuses" },
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
];

export const userStatusOptions = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
];

export const numberPageUser = [
  { value: "1", label: "Show 1" },
  { value: "6", label: "Show 6" },
  { value: "10", label: "Show 10" },
];

export const numberPageAssigned = [
  { value: "1", label: "Show 1" },
  { value: "5", label: "Show 5" },
  { value: "10", label: "Show 10" },
];

export const datePickListOptions = [
  { value: "2023-06-30", label: "June 30" },
  { value: "2023-08-31", label: "August 31" },
];

export const FAQ_DATA = [
  {
    question: "How do I access the annual report questions?",
    answer: "Click here for full information",
  },
  {
    question:
      "Running schools is important work and already challenging enough. Do I really have to submit the Annual Report??",
    answer:
      "EFF combines philanthropic dollars with tax-exempt bond proceeds to provide dramatically lower, subsidized rates to its School Partners, like you! In order to continue our low-cost lending to existing and future School Partners, EFF is required to provide disclosure to our bondholders. In addition, EFF has to carefully monitor its portfolio of borrowers to anticipate any issues that might affect a school’s ability to repay its loan. The disclosure you provide is crucial to us achieving both of these critical objectives. We literally wouldn't be able to provide low-cost financing to new school partners (or an additional loan to your organization should the need arise) without complete, accurate, and timely reporting from our School Partners.",
  },
  {
    question:
      "Where do I find information about my organization's Academic, Enrollment, and Financial Covenants?",
    answer:
      "Specifics on your organization’s covenants can be found in the Borrower Summary, sometimes referred to as the Cheat Sheet. This document includes the unique covenant language for your organization as well as any definitions for specific terms, like Net Revenue or Operating Expenses. This document was emailed to your team after closing AND is available in the EFF Borrower Portal in the Documents section.",
  },
  {
    question: "When are my organization’s annual and quarterly reports due?",
    answer:
      "With few exceptions, Annual Reports are due 180 days after the fiscal year end and Quarterly Reports are due 45 days after quarter end. That said, this is unique to each organization and some organizations do have different reporting deadlines based on unique circumstances for their organization or state. You can find the due dates for all EFF related reporting in the Borrower Summary, aka Cheat Sheet, located in the EFF Borrower Portal.",
  },
  {
    question: "How do I submit the Annual Report?",
    answer:
      "The Annual Report, and Quarterly Reports for that matter, are submitted using a form in Jotform. The links for the Annual Report and Quarterly Report Jotform are available in the Borrower Portal.",
  },
  {
    question:
      "Can I just email you the Annual Report instead of using Jotform?",
    answer:
      "Unfortunately no. Using Jotform helps ensure that we collect standardized information and data while streamlining the process. We know it seems like a lot, but we believe that this is easier for EFF and for you. If you don’t believe us, ask one of our School Partners that had to submit an Annual Report when there was no guidance or structure.",
  },
];

export const USEJETFORM_DATA = [
  {
    question:
      " Does the Annual Report form (via Jotform) need to be completed in one sitting or can I save and complete it at my leisure?",
    answer:
      "Unfortunately, the Annual Report must be completed in one sitting. In the Borrower Portal, you can find a list of all of the questions so that you can collect information in advance of completing the form.",
  },
  {
    question:
      "How do I navigate in Jotform without answering a question, as all the questions are required?",
    answer:
      "You can use the navigation bar at the bottom to move from question to question without providing answers (see screenshot below). The circles in the navigation bar will also indicate the title of the question as well as the question number, for easy reference.",
    image: "",
  },
  {
    question: "Are all of the questions required?",
    answer:
      "Questions with an asterisk require responses where as questions without can be skipped. This ONLY occurs in situations when they do not apply. For example, if you answer “No” to the question “Has the Borrower been audited by the [IRS]...” then you won’t need to answer the next question asking for additional details.",
  },
  {
    question: "What kind of documents can I upload to Jotform?",
    answer:
      "This configuration of Jotform only allows documents to be uploaded as PDF’s.",
    image: "",
  },
];

export const videos = [
  {
    title: "Enrollment Covenant",
    url: "https://vimeo.com/880150715",
  },
  {
    title: "Borrower Summary Overview",
    url: "https://vimeo.com/880146344",
  },
  {
    title: "Academic Covenant",
    url: "https://vimeo.com/880137102",
  },
  {
    title: "Additional Data Requests",
    url: "https://vimeo.com/880142633",
  },
  {
    title: "Financial Covenants Section",
    url: "https://vimeo.com/880126726",
  },
  {
    title: "Post Issuance Tax Compliance Questionnaire",
    url: "https://vimeo.com/880130740",
  },
  {
    title: "Financial Ratio Workbooks",
    url: "https://vimeo.com/880124664",
  },
];
