import React, { FC } from "react";
import CroupPlusIcon from "../../assets/icons/CroupPlusIcon";
import { UserPlaceholder } from "../UserPlaceholder";
interface AvatarsListProps {
  avatarsUrl: string[];
  onPlusClick: () => void;
  showPlus?: boolean;
}
export const AvatarsList: FC<AvatarsListProps> = ({
  avatarsUrl,
  onPlusClick,
  showPlus = true,
}) => {
  const displayedAvatarsNumber = avatarsUrl.length > 2 ? 2 : avatarsUrl.length;
  const newAvatarsArray = avatarsUrl.slice(0, displayedAvatarsNumber);
  const avatarsNumber = avatarsUrl.length;

  return (
    <div className="relative">
      {newAvatarsArray.map((avatar, index) => {
        return (
          <div
            key={index}
            className="absolute z-30"
            style={{ left: `${index * 32}px` }}
          >
            <UserPlaceholder name={avatar} />
          </div>
        );
      })}
      {avatarsNumber > 2 ? (
        <span
          className={`absolute bg-[#CA8A8A] left-[60px] top-[30px] text-white rounded-full px-[4px] z-50 text-[8px]`}
        >
          +{avatarsNumber - 2}
        </span>
      ) : (
        ""
      )}
      {showPlus && (
        <div
          className="absolute z-10"
          style={{ left: `${displayedAvatarsNumber * 32}px` }}
        >
          <button onClick={onPlusClick}>
            <CroupPlusIcon />
          </button>
        </div>
      )}
    </div>
  );
};
