import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { useGetLenderUsersQuery } from "../services/rtkQuery/employee";
import { useGetBorrowerUserQuery } from "../services/rtkQuery/borrower";
import { getOptions } from "../utill/helpers";
import { useAppSelector } from "../store/hooks";
import { selectLenderUsers } from "../store/modules/employee/selectors";
import { selectBorrowerUser } from "../store/modules/borrower/selectors";
import { useEffect, useMemo, useState } from "react";
import { Option } from "../view/ui/common/SelectBox";
import {
  useEditOrganizationMutation,
  useGetOrganizationsByIdQuery,
  useLazyGetOrganizationsByIdQuery,
} from "../services/rtkQuery/organization";
import { datePickListOptions } from "../utill/contants";
import moment from "moment";

const OrganizationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Please enter a valid password")
    .min(6, "Please enter at least 6 characters"),

  password: Yup.string()
    .required("Please enter a valid password")
    .min(6, "Please enter at least 6 characters"),
});

const defaultValues = {
  name: "",
  description: "",
  fiscalYearEnd: { value: "", label: "" },
  assigned_user_id: { value: "", label: "" },
};

export const useEditOrganization = (
  id: string | undefined,
  onSuccess: () => void
) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues,
  });
  useGetLenderUsersQuery({ getAll: true });
  useGetBorrowerUserQuery({ getAll: true });

  const [editOrganization] = useEditOrganizationMutation();
  const { data } = useGetOrganizationsByIdQuery({ id: id });

  const { rows }: any = useAppSelector(selectLenderUsers);
  const borrowerContacts = useAppSelector(selectBorrowerUser);

  const borrowerContactsOptions: Option[] = useMemo(() => {
    if (borrowerContacts?.rows) {
      return getOptions(borrowerContacts.rows, "fullName", "id");
    }
    return [];
  }, [borrowerContacts]);

  const options: Option[] = useMemo(() => {
    if (rows) {
      return getOptions(rows, "fullName", "id");
    }
    return [];
  }, [rows]);

  useEffect(() => {
    const organization = data?.data?.organization;
    if (organization) {
      reset({
        name: organization?.name,
        fiscalYearEnd: datePickListOptions.find(
          (el: Option) =>
            el.value === moment(organization.fiscalYearEnd).format("YYYY-MM-DD")
        ),
        assigned_user_id: options.find(
          (el: Option) => el.value === String(organization.AssignedUser?.id)
        ),
        description: organization.description,
      });
    }
  }, [data]);

  const onSubmit = (data: typeof defaultValues) => {
    const payload: any = {
      name: data.name,
      description: data.description,
      fiscalYearEnd: data.fiscalYearEnd.value,
    };

    payload.assigned_user_id = data.assigned_user_id.value;

    editOrganization({ id: id, data: payload }).then(() => {
      onSuccess();
    });
  };

  return {
    control,
    errors,
    handleSubmit: handleSubmit(onSubmit),
    options,
    borrowerContactsOptions,
    setValue,
    watch,
    reset,
  };
};
