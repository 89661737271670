import React, { FC, useMemo } from "react";
import usePagination from "../../../hooks/usePagination";
import ReactPaginate from "react-paginate";

interface PaginationProps {
  itemsPerPage: number;
  userCount?: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({
  itemsPerPage,
  userCount = 10,
  currentPage,
  onPageChange,
}) => {
  const pageCount = useMemo(() => {
    return Math.ceil(userCount / itemsPerPage);
  }, [itemsPerPage, userCount]);

  return (
    <div>
      <div className="my-4">
        {pageCount > 1 ? (
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            initialPage={currentPage - 1}
            onPageChange={(page) => {
              onPageChange(page.selected + 1);
            }}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            disableInitialCallback={true}
            previousLabel="<"
            className="flex gap-[24px] font-bold"
            activeClassName="text-[#4D5187]"
            nextClassName="text-[#000000]"
            previousClassName="text-[#000000]"
            disabledClassName="text-[grey]"
          />
        ) : null}
      </div>
    </div>
  );
};

export default Pagination;
