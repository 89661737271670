import { useForm } from "react-hook-form";
import { useEffect, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { useAppSelector } from "../store/hooks";

import { getOptions } from "../utill/helpers";
import {
  useEditBorrowerDocumentMutation,
  useGetCategoriesQuery,
  useGetDocumentByIdQuery,
} from "../services/rtkQuery/documents";
import { selectCategories } from "../store/modules/documents/selectors";

import { getGeneralSelectBoxSchema } from "../utill/validation/validationSchemas";

const documentSchema = Yup.object().shape({
  title: Yup.string().required("Document name is required"),
  categoryId: getGeneralSelectBoxSchema("Category is required"),
});

const defaultValues = {
  categoryId: { value: "", label: "" },
  title: "",
};

export const useEditBorrowerDocument = (id: number, onSuccess: () => void) => {
  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues, resolver: yupResolver(documentSchema) });

  useGetCategoriesQuery();
  const [updateDocument, { isSuccess: isFileUpdatedSuccess }] = useEditBorrowerDocumentMutation();
  const { data } = useGetDocumentByIdQuery({ id });

  const categories = useAppSelector(selectCategories);

  const categoriesOptions = useMemo(() => {
    if (categories && categories.length) {
      return getOptions(categories, "name", "id");
    }
    return [];
  }, [categories]);

  useEffect(() => {
    if (data?.data) {
      const document = data?.data?.document;

      reset({
        title: document.title,
        categoryId: categoriesOptions.find((el) => el.value === document?.categoryId?.toString()),
      });
    }
  }, [data]);

  useEffect(() => {
    if (isFileUpdatedSuccess) {
      toast.success("Document was updated");
    }
  }, [isFileUpdatedSuccess]);

  const onSubmit = (data: any) => {
    updateDocument({
      documentId: id,
      data: { title: data.title, categoryId: data.categoryId.value },
    });

    onSuccess();
  };

  return {
    handleSubmit: handleSubmit(onSubmit),
    control,
    errors,
    categoriesOptions,
  };
};
