import React, { FC } from "react";
import { useAppSelector } from "../../../../store/hooks";
import { selectLenderUsers } from "../../../../store/modules/employee/selectors";
import { EmployeeTableRow } from "./EmployeeTableRow";
import { ILenderUser } from "../../../../models/employee";

interface EmployeeTableBodyProps {
  options: ILenderUser[];
  deleteUser: (userId: number) => void;
  goToDetailsScreen: (id: number) => void;
}
export const EmployeeTableBody: FC<EmployeeTableBodyProps> = ({
  options,
  deleteUser,
  goToDetailsScreen,
}) => {
  const { rows }: any = useAppSelector(selectLenderUsers);

  return (
    <tbody className="w-full gap-[10px] items-center text-center">
      {(rows || []).map((user: ILenderUser) => (
        <EmployeeTableRow
          row={user}
          deleteUser={deleteUser}
          key={user.id}
          goToDetailsScreen={goToDetailsScreen}
        />
      ))}
    </tbody>
  );
};
