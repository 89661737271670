import { configureStore } from "@reduxjs/toolkit";
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from "redux-persist";

import { rtkQuery } from "../services/rtkQuery";
import { rtkQueryErrorLogger } from "../services/rtkQuery/errorsLogger";

import { rootReducer } from "./rootReducer";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 256 },
      serializableCheck: false,
    })
      .concat(rtkQuery.middleware)
      .concat(rtkQueryErrorLogger),
});

export const persistor = persistStore(store);
