import React, { FC } from "react";
import { Modal } from "../Modal";
import { Button } from "../../ui/common/Button";
import CropIcon from "../../assets/icons/CropIcon";

interface DeleteConfirmationModalProps {
  isOpenModal: boolean;
  closeModal: () => void;
  onDelete: () => void;
  title: string;
  text: string;
}
export const DeleteConfirmationModal: FC<DeleteConfirmationModalProps> = ({
  isOpenModal,
  closeModal,
  onDelete,
  title,
  text,
}) => {
  return (
    <Modal isOpened={isOpenModal} onClose={closeModal}>
      <div className="relative bg-[#FFF]  rounded-[32px] w-[520px]">
        <div className=" flex justify-end p-[10px]">
          <div className="cursor-pointer" onClick={closeModal}>
            <CropIcon />
          </div>
        </div>
        <div className="p-[60px] pt-4">
          <p className="text-[32px] text-center font-medium">{title}</p>
          <p className="text-[16px] text-[#717984] text-center mb-[16px]">
            {text}
          </p>
          <div className="flex mt-8 justify-between items-center gap-4">
            <div className="w-[50%] flex justify-center text-[#4D5187] border-[#4D5187] p-[12px] border-[2px] rounded-lg font-medium">
              <button className="w-full" onClick={closeModal}>
                Cancel
              </button>
            </div>
            <div className="w-[50%] flex justify-center p-[12px] rounded-lg bg-[#434784] text-[#FFFFFF] font-medium">
              <button className="w-full" onClick={onDelete}>
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
