import React, { FC, useState } from "react";
import CropIcon from "../../../../assets/icons/CropIcon";
import UserIcon from "../../../../assets/icons/UserIcon";
import { Modal } from "../../../../components/Modal";
import { TextField } from "../../../../ui/common/TextField";
import { Controller, Control } from "react-hook-form";
import EmailIcon from "../../../../assets/icons/EmailIcon";
import { Button } from "../../../../ui/common/Button";
import { SelectBox } from "../../../../ui/common/SelectBox";

interface CreateEmployeeModalProps {
  isOpenModal: boolean;
  closeModal: () => void;
  action: string;
  handleSubmit: () => void;
  control: Control | any;
  errors?: any;
}

export const roles = [
  { value: "", label: "All Roles" },
  { value: "user", label: "User" },
  { value: "admin", label: "Admin" },
];

export const userRoles = [
  { value: "user", label: "General User" },
  { value: "admin", label: "Admin" },
];

export const CreateEmployeeModal: FC<CreateEmployeeModalProps> = ({
  isOpenModal,
  closeModal,
  action,
  handleSubmit,
  control,
  errors,
}) => {
  const isCreate = action === "create";

  return (
    <Modal isOpened={isOpenModal} onClose={closeModal}>
      <div className="relative bg-[#FFF] p-[48px] rounded-[32px] w-[520px]">
        <div className=" flex justify-end">
          <div className="cursor-pointer" onClick={closeModal}>
            <CropIcon />
          </div>
        </div>
        <div>
          <p className="text-[#474747] text-[32px]">
            {isCreate ? "New Carewolf" : "Edit Carewolf"}
          </p>
          <p className="text-[#717984] text-[16px]">
            {isCreate ? "Enter" : "Change"} Carewolf work account information
          </p>
        </div>
        <form onSubmit={handleSubmit} className="gap-2">
          <Controller
            control={control}
            name="fullName"
            rules={{ required: "Full Name is required" }}
            render={({ field }) => (
              <>
                <TextField
                  icon={<UserIcon />}
                  value={field.value}
                  label="Full Name"
                  placeholder="Enter Full Name"
                  type="text"
                  onChange={field.onChange}
                />
                {errors?.fullName && (
                  <p className="text-[red] text-[13px]">
                    {errors?.fullName?.message}
                  </p>
                )}
              </>
            )}
          />

          <Controller
            control={control}
            name="email"
            rules={{ required: "Email is required" }}
            render={({ field }) => (
              <>
                <TextField
                  icon={<EmailIcon />}
                  value={field.value}
                  label="Email Address"
                  placeholder="Enter email"
                  type="text"
                  onChange={field.onChange}
                />
                {errors?.email && (
                  <p className="text-[red] text-[13px]">
                    {errors?.email?.message}
                  </p>
                )}
              </>
            )}
          />

          <Controller
            control={control}
            name="password"
            rules={{ required: "Password is required" }}
            render={({ field }) => (
              <>
                <TextField
                  value={field.value}
                  label="Password"
                  placeholder={`${
                    isCreate ? "Enter password" : "Reset password"
                  }`}
                  type="text"
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                />
                {errors?.password && (
                  <p className="text-[red] text-[13px]">
                    {errors?.password?.message}
                  </p>
                )}
              </>
            )}
          />

          <div className="gap-2">
            <Controller
              name="role"
              control={control}
              render={({ field }) => (
                <>
                  <SelectBox
                    label="Carewolf Role"
                    placeholder="Select the role of user"
                    value={field.value}
                    onChange={field.onChange}
                    options={userRoles}
                    multiple={false}
                    searchEnabled={false}
                  />
                  {errors?.role?.value && (
                    <p className="text-[red] text-[13px]">
                      {errors?.role.value?.message}
                    </p>
                  )}
                </>
              )}
            />
            <div className="w-full text-[15.5px] mt-[24px]">
              <Button
                type="submit"
                text={`${isCreate ? "Create and Send Access" : "Update"}`}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};
