import SortIcon from "../../../assets/icons/SortIcon";
import { Checkbox } from "../../../ui/common/Checkbox";

const BorrowerTableHeader = () => {
  return (
    <thead className="flex w-full justify-between gap-[10px]">
      <th className="flex items-center gap-1 w-[16%] py-3">
        <p className="text-center font-bold">School Partner Contact</p>
        <SortIcon />
      </th>
      <th className="flex  items-center gap-1 w-[14%] py-3">
        <p className="text-center font-bold">Role</p>
        <SortIcon />
      </th>
      <th className="flex items-center gap-1 w-[14%] py-3">
        <p className="text-center font-bold">Date of Registration</p>
        <SortIcon />
      </th>
      <th className="flex items-center gap-1 w-[14%] py-3">
        <p className="text-center font-bold">Date of Last Login</p>
        <SortIcon />
      </th>
      <th className="flex items-center gap-1 w-[14%] py-3">
        <p className="text-center font-bold">Status</p>
        <SortIcon />
      </th>
      <th className="flex justify-end w-[14%] py-3">
        <p className="text-right font-bold">Action</p>
      </th>
    </thead>
  );
};

export default BorrowerTableHeader;
