import React from "react";
import ReactPlayer from "react-player";
import { videos } from "../../../../utill/contants";

// Render a YouTube video player

const VideoList = () => {
  return (
    <div className="flex gap-4 flex-wrap w-full">
      {videos.map(({ url, title }) => (
        <div className="w-[23%]">
          <ReactPlayer width={"100%"} height={200} controls url={url} pip={false} />
          <p className="text-center font-bold py-4">{title}</p>
        </div>
      ))}
    </div>
  );
};

export default VideoList;
