import React, { FC } from "react";
import CalendarIcon from "../../../assets/icons/CalendarIcon";
import { ILenderUser } from "../../../../models/employee";
import moment from "moment";
import { userRoles } from "../../Employee/components/CreateEmployeeModal";

interface UserInfoProps {
  lenderUser: ILenderUser;
}

export const UserInfo: FC<UserInfoProps> = ({ lenderUser }) => {
  const lenderUserRole = userRoles.find((item) => item.value === lenderUser.role);

  return (
    <div className="flex gap-5 justify-between items-center">
      <div className="flex w-[40%]">
        <div>
          <p className="text-[24px]">{lenderUser.fullName}</p>
          <p className="text-[14px]">{lenderUser.email}</p>
        </div>
        <div className="bg-[#E8F0EB] flex items-center px-[16px] py-[4px] h-[30px] ml-[20px]">
          <p className="text-[#95B7A2]  text-[12px]">Online</p>
        </div>
      </div>
      <div className="flex justify-between w-[60%] gap-[120px]">
        <div>
          <p className="text-[12px] text-[#717984]">Date of Registration</p>
          <div className="flex items-center gap-2">
            <CalendarIcon />
            <p>{moment(lenderUser.createdAt).format("LL")}</p>
          </div>
        </div>
        <div>
          <p className="text-[12px] text-[#717984]">Date of Last Login</p>
          <div className="flex items-center gap-2">
            <CalendarIcon />
            <p>{moment(lenderUser.updatedAt).format("LL")}</p>
          </div>
        </div>
        <div>
          <p className="text-[12px] text-[#717984]">Role</p>
          <p className="text-[16px] text-[#474747]">{lenderUserRole?.label}</p>
        </div>
      </div>
    </div>
  );
};
