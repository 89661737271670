import { FC, useMemo } from "react";

import { useGetCategoriesQuery } from "../../../../services/rtkQuery/documents";
import { useAppSelector } from "../../../../store/hooks";
import { selectCategories } from "../../../../store/modules/documents/selectors";

import { getOptions } from "../../../../utill/helpers";

import { SearchInput } from "../../../components/SearchInput";
import { Option, SelectBox } from "../../../ui/common/SelectBox";

interface BorrowerDocumentsFiltersProps {
  name: string;
  setName: (name: string) => void;
  selectedCategory: Option | Option[];
  onChangeSelectedValue: (value: Option | Option[]) => void;
}

export const BorrowerDocumentsFilters: FC<BorrowerDocumentsFiltersProps> = ({
  name,
  setName,
  selectedCategory,
  onChangeSelectedValue,
}) => {
  useGetCategoriesQuery();
  const categories = useAppSelector(selectCategories);

  const handleChange = (value: string | number) => {
    setName(value.toString());
  };

  const categoriesOptions = useMemo(() => {
    if (categories && categories.length) {
      return getOptions(categories, "name", "id");
    }
    return [];
  }, [categories]);

  return (
    <div>
      <div className="flex justify-between items-center gap-[16px]">
        <div className="w-[55%]">
          <SearchInput
            value={name}
            placeholder="Search Documents"
            type="text"
            onChange={handleChange}
            icon={false}
          />
        </div>
        <div className="w-[45%] flex justify-between gap-[16px]">
          <SelectBox
            value={selectedCategory}
            options={[{ value: "", label: "All" }, ...categoriesOptions]}
            onChange={onChangeSelectedValue}
            placeholder="Category"
            multiple={false}
          />
        </div>
      </div>
    </div>
  );
};
