import React, { FC, ReactComponentElement } from "react";
import { Organization } from "../../../store/modules/employee/types";

interface TableProps {
  HeaderComponent: React.ElementType;
  BodyComponent: React.ElementType;
  deleteUser?: (userId: number) => void;
  goToDetailsScreen?: (id: number) => void;
  oranizationList?: Organization[];
}
const Table: FC<TableProps> = ({
  HeaderComponent,
  BodyComponent,
  deleteUser,
  goToDetailsScreen,
  oranizationList,
}) => {
  return (
    <table className="flex w-full flex-wrap">
      <HeaderComponent />
      <BodyComponent
        deleteUser={deleteUser}
        goToDetailsScreen={goToDetailsScreen}
        oranizationList={oranizationList}
      />
    </table>
  );
};

export default Table;
