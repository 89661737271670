import React, { FC, useState } from "react";
import CropAngleIcon from "../../../assets/icons/CropAngleIcon";
import CroupPlusIcon from "../../../assets/icons/CroupPlusIcon";
import { Organization } from "../../../../store/modules/employee/types";
import moment from "moment";
import { AvatarsList } from "../../../components/AvatarsList/AvatarsList";
import { AssignBorrowerModal } from "./AssignBorrowerModal";
import { AssignLenderModal } from "./AssignLenderModal";
interface OrganizationItemProps {
  row: Organization;
  goToDetailsScreen: (id: number) => void;
}
export const OrganizationItem: FC<OrganizationItemProps> = ({
  row,
  goToDetailsScreen,
}) => {
  const [assignBorrower, setAssignBorrower] = useState(false);
  const [assignLender, setAssignLender] = useState(false);

  const openDetailsPage = () => {
    goToDetailsScreen(row.id);
  };
  const borrowerAvatars = row.Users.map((user: any) => user.fullName);
  const lenderAvatars = row.AssignedUser ? [row.AssignedUser?.fullName] : [];

  const toggleShowAssignBorrower = () => {
    setAssignBorrower(!assignBorrower);
  };

  const toggleShowAssignLender = () => {
    setAssignLender(!assignLender);
  };

  return (
    <div className="flex w-[100%] gap-[10px] justify-between mb-[24px]">
      <div className="flex flex-col items-start w-[30%]">
        <div className="flex items-start" onClick={openDetailsPage}>
          <p className="text-[17px] font-bold text-[#474747] mr-2">
            {row?.name}
          </p>
          <CropAngleIcon />
        </div>
        <p className="text-[13px] text-[#717984]">{row?.AssignedUser?.email}</p>
      </div>
      <div className="flex justify-between w-[80%]">
        <div>
          <p className="text-[12px] mb-2">School Partner Contact</p>
          <div className="flex w-full relative group">
            <div className="absolute py-1 px-2 rounded-lg shadow-lg bg-[lightgray] z-[100] bottom-0 hidden group-hover:block">
              {borrowerAvatars.map((borrower) => (
                <div className="text-[12px]">{borrower}</div>
              ))}
            </div>
            <AvatarsList
              avatarsUrl={borrowerAvatars}
              onPlusClick={toggleShowAssignBorrower}
            />
          </div>
        </div>
        <div>
          <p className="text-[12px] mb-2">Carewolves</p>
          <div className="relative group">
            <div className="absolute py-1 px-2 rounded-lg shadow-lg bg-[lightgray] z-[100] bottom-0 hidden group-hover:block">
              {lenderAvatars.map((lender) => (
                <div className="text-[12px]">{lender}</div>
              ))}
            </div>
            <AvatarsList
              avatarsUrl={lenderAvatars}
              onPlusClick={toggleShowAssignLender}
            />
          </div>
        </div>
        <div>
          <p className="text-[12px] mb-2">Fiscal Year End</p>
          <p className="text-[16px]">
            {moment(row.fiscalYearEnd).format("MMMM  DD")}
          </p>
        </div>
      </div>
      {assignBorrower && (
        <AssignBorrowerModal
          isOpenModal={assignBorrower}
          closeModal={toggleShowAssignBorrower}
          organizationId={row.id.toString()}
        />
      )}
      {assignLender && (
        <AssignLenderModal
          isOpenModal={assignLender}
          closeModal={toggleShowAssignLender}
          organizationId={row.id.toString()}
        />
      )}
    </div>
  );
};
