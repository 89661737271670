import React, { ChangeEvent, FC, ReactNode } from "react";
import VectorIcon from "../../assets/icons/VectorIcon";
import ArcIcon from "../../assets/icons/ArcIcon";

interface Props {
  label?: string;
  value?: string;
  onChange: (value: string | number) => void;
  placeholder?: string;
  icon?: ReactNode;
  type: string;
}
export const SearchInput: FC<Props> = ({
  label,
  value,
  onChange,
  placeholder,
  icon = true,
  type,
}) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };
  return (
    <div className="w-full flex justify-start flex-col mb-[10px]">
      <label className="text-gray-600 font-medium text-[15px] text-left mb-[8px]">
        {label}
      </label>
      <div className="relative">
        {icon && (
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600">
            <VectorIcon />
          </div>
        )}
        <input
          className={`w-full border-2 rounded-[12px] px-2 py-2 focus:outline-none ${
            icon && "pl-10"
          }`}
          type={type}
          value={value}
          onChange={handleInputChange}
          placeholder={placeholder}
        />
        {icon ? (
          <div className="absolute inset-y-0 right-3 flex items-center pl-3 text-gray-600">
            <ArcIcon />
            <p className="ml-2">F</p>
          </div>
        ) : (
          <div className="absolute inset-y-0 right-4 flex items-center pl-3 text-gray-600">
            <VectorIcon />
          </div>
        )}
      </div>
    </div>
  );
};
