import React from "react";

const IllustrationIcon: React.FC = () => {
  return (
    <svg
      width="266"
      height="166"
      viewBox="0 0 266 166"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M92.0686 9.64922C92.0686 13.8267 98.7644 17.2132 94.105 17.2132C89.4456 17.2132 85.6685 13.8267 85.6685 9.64922C85.6685 5.47173 89.4456 2.08521 94.105 2.08521C98.7644 2.08521 92.0686 5.47173 92.0686 9.64922Z"
        fill="#4D5187"
      />
      <path
        d="M158.23 6L159.362 9.4844H163.026L160.062 11.6379L161.194 15.1223L158.23 12.9688L155.266 15.1223L156.398 11.6379L153.434 9.4844H157.098L158.23 6Z"
        fill="#EF996C"
      />
      <path
        d="M34.3782 79.6553C34.3782 79.6553 45.1263 74.302 47.5645 77.1078C48.904 78.6493 49.9156 80.5164 48.7159 82.0333C47.7912 83.2026 46.456 83.3516 44.8916 83.0769C41.5788 82.4951 41.6291 77.8947 42.9589 75.0455C44.795 71.1117 54.5415 73.4336 54.5415 73.4336"
        stroke="#95B7A2"
        stroke-width="2.01707"
      />
      <circle cx="229.217" cy="74.2923" r="5.88312" fill="#EF996C" />
      <path
        d="M56.0474 135.991L56.8844 128.771L62.7199 132.47L63.3851 126.414L68.7079 130.08L70.3656 123.533"
        stroke="#EF996C"
        stroke-width="1.68089"
      />
      <path
        d="M193.301 21.9249C193.301 21.9249 192.482 17.0642 194.831 16.0954C196.803 15.2815 198.514 18.8808 200.135 17.5759C201.777 16.2541 197.678 14.1601 199.013 12.5791C200.418 10.9152 204.929 12.5791 204.929 12.5791"
        stroke="#95B7A2"
        stroke-width="2.01707"
      />
      <path
        d="M32.955 14.6396L54.5613 25.3959L49.1611 32.7601L32.955 14.6396Z"
        fill="#95B7A2"
      />
      <circle cx="224.346" cy="94.3126" r="2.52134" fill="#EF996C" />
      <path
        d="M200.116 127.502C202.887 127.781 205.58 123.565 205.269 126.656C204.958 129.747 202.46 132.026 199.689 131.747C196.918 131.468 194.924 128.737 195.235 125.646C195.546 122.555 197.345 127.223 200.116 127.502Z"
        fill="#4D5187"
      />
      <path
        d="M209.11 38.9929L210.997 44.8003H217.103L212.163 48.3894L214.05 54.1967L209.11 50.6076L204.17 54.1967L206.057 48.3894L201.117 44.8003H207.223L209.11 38.9929Z"
        fill="#4D5187"
      />
      <rect
        x="82.7998"
        y="25.823"
        width="100"
        height="100"
        rx="50"
        fill="#4D5187"
      />
      <g filter="url(#filter0_d_451_94908)">
        <rect
          x="102.8"
          y="45.823"
          width="60"
          height="60"
          rx="30"
          fill="url(#paint0_linear_451_94908)"
          shape-rendering="crispEdges"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M143.684 68.6891C144.172 69.1773 144.172 69.9687 143.684 70.4569L131.184 82.9569C130.696 83.445 129.904 83.445 129.416 82.9569L123.166 76.7069C122.678 76.2187 122.678 75.4273 123.166 74.9391C123.654 74.451 124.446 74.451 124.934 74.9391L130.3 80.3052L141.916 68.6891C142.404 68.201 143.196 68.201 143.684 68.6891Z"
          fill="#F5F5F5"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_451_94908"
          x="62.7998"
          y="25.823"
          width="140"
          height="140"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="20" />
          <feGaussianBlur stdDeviation="20" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_451_94908"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_451_94908"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_451_94908"
          x1="132.8"
          y1="45.823"
          x2="132.8"
          y2="105.823"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#ABDCBE" />
          <stop offset="1" stop-color="#8BC4A1" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IllustrationIcon;
