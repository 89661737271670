import SortIcon from "../../../assets/icons/SortIcon";

export const DocumentTableHead = () => {
  return (
    <thead className="flex w-full justify-between gap-[10px]">
      <th className="flex items-center gap-1 w-[22%] py-3">
        <p className="text-center">Document Title</p>
        <SortIcon />
      </th>
      <th className="flex  items-center gap-1 w-[13%] py-3">
        <p className="text-center">Category</p>
      </th>
      <th className="flex items-center gap-1 w-[13%] py-3">
        <p className="text-center">Date Upload</p>
        <SortIcon />
      </th>
      <th className="flex items-center gap-1 w-[13%] py-3">
        <p className="text-center">Last View</p>
        <SortIcon />
      </th>
      <th className="flex items-center gap-1 w-[13%] py-3">
        <p className="text-center">Date Deleted</p>
        <SortIcon />
      </th>
      <th className="flex items-center gap-1 w-[13%] py-3">
        <p className="text-center">Deleted by</p>
        <SortIcon />
      </th>
      <th className="flex justify-center w-[13%] py-3">
        <p className="text-right">Status</p>
      </th>
      <th className="flex justify-end w-[13%] py-3">
        <p className="text-right">Action</p>
      </th>
    </thead>
  );
};
