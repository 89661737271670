import React, { FC, useCallback } from "react";
import moment from "moment";
import EyeIcon from "../../../assets/icons/EyeIcon";
import DownloadIcon from "../../../assets/icons/DownloadIcon";
import fileDownload from "js-file-download";

interface DocumentTableRowProps {
  document?: any;
}
export const DocumentTableRow: FC<DocumentTableRowProps> = ({ document }) => {
  const downloadFile = useCallback(() => {
    if (document.url) {
      const fileExtension = document.url.split(".").pop();
      fileDownload(document.url, document.title + `.${fileExtension}`);
    }
  }, [document]);

  const openInNewTab = () => {
    if (document.url) {
      window.open(document.url, "_blank", "noreferrer");
    }
  };

  return (
    <tr className="flex w-full justify-between gap-[10px] my-3">
      <td className="flex items-center gap-1 w-[22%]">
        <div className="flex flex-col items-start ml-2">
          <p className="text-[15px] font-medium text-[#474747] text-left">
            {document.title}
          </p>
        </div>
      </td>
      <td className="flex items-center justify-left gap-1 w-[13%]">
        <p className="text-left  text-[15px] text-[#474747]">
          {document.Category?.name}
        </p>
      </td>
      <td className="flex items-center justify-left gap-1 w-[13%]">
        <p className="text-left  text-[15px] text-[#474747]">
          {moment(document.createdAt).calendar()}
        </p>
      </td>
      <td className="flex items-center justify-left gap-1 w-[13%]">
        <p className="text-center  text-[15px] text-[#474747]">
          {document.lastViewedAt
            ? moment(document.lastViewedAt).calendar()
            : "-"}
        </p>
      </td>
      <td className="flex items-center justify-left gap-1 w-[13%]">
        <p className="text-center  text-[15px] text-[#474747]">
          {document.deletedAt ? moment(document.deletedAt).calendar() : "-"}
        </p>
      </td>
      <td className="flex items-center justify-left gap-1 w-[13%]">
        <p className="text-center text-[15px] w-full text-[#474747]">---</p>
      </td>
      <td className="flex items-center justify-left gap-1 w-[13%]">
        <p className="text-center text-[15px] w-full text-[#474747]">
          {document.status}
        </p>
      </td>
      <td className="flex items-center justify-end gap-2 w-[13%]">
        <button
          onClick={openInNewTab}
          className="flex w-[30px] h-[30px] p-1 justify-center items-center gap-8 bg-[#EE8C58] rounded-lg"
        >
          <EyeIcon />
        </button>
        <button
          onClick={downloadFile}
          className="flex  w-[30px] h-[30px] p-1 justify-center items-center gap-8 bg-[#434784] rounded-lg"
        >
          <DownloadIcon />
        </button>
      </td>
    </tr>
  );
};
