import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { logout } from "../../../store/modules/user";
import { selectUser } from "../../../store/modules/user/selectors";
import { Button } from "../../ui/common/Button";

export const UserSetting = () => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const logoutHandle = () => {
    dispatch(logout());
  };
  return (
    <div className="bg-white p-5 w-[300px] rounded-lg shadow-lg shadow-[grey]">
      <div>
        <p className="text-[12px] text-[#717984] font-medium">Contact Administrator</p>
        <p className="text-[15px] font-bold">{user?.fullName}</p>
        <p className="text-[13px] text-[#717984] font-medium">{user?.email}</p>
      </div>
      <div className="flex items-center gap-3 mt-5">
        <div className="w-full">
          <Button text="Logout" onClick={logoutHandle} />
        </div>
      </div>
    </div>
  );
};
