import React from "react";

const EditIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1803 2.58024C10.9582 2.48078 11.7451 2.79886 12.5447 3.55122L12.5457 3.55209C13.3479 4.31082 13.7132 5.08048 13.6584 5.8644C13.6054 6.6219 13.1663 7.26304 12.6655 7.79208M12.6655 7.79208L7.19493 13.5825C7.0407 13.7505 6.83304 13.8927 6.63618 13.998C6.43678 14.1046 6.20614 14.1958 5.98961 14.2343L5.98628 14.2349L3.84038 14.6014C3.32001 14.6912 2.82101 14.5611 2.46554 14.224C2.11057 13.8874 1.95371 13.3965 2.01182 12.8735L2.01199 12.872L2.25973 10.7026C2.2885 10.4868 2.36865 10.2533 2.46312 10.0507C2.55725 9.84886 2.68568 9.63483 2.83767 9.47303L2.83865 9.472L8.31198 3.67866C8.81301 3.14941 9.4287 2.67636 10.1803 2.58024M9.03854 4.36577L3.56652 10.1577C3.51187 10.216 3.43681 10.3288 3.36941 10.4734C3.30324 10.6153 3.26349 10.7465 3.25158 10.8309L3.00571 12.9839L3.00563 12.9846C2.97729 13.2412 3.05704 13.4068 3.15366 13.4984C3.24985 13.5896 3.41752 13.6596 3.67048 13.616L3.67125 13.6158L5.81599 13.2495C5.8995 13.2344 6.02818 13.1891 6.16468 13.1161C6.30307 13.0421 6.40718 12.9623 6.45929 12.9052L6.46526 12.8986L11.9387 7.10533C12.3844 6.63444 12.6321 6.20533 12.6608 5.79467C12.6877 5.41036 12.5297 4.91353 11.8591 4.27917C11.1922 3.65184 10.6893 3.52331 10.3072 3.57217C9.89891 3.62437 9.48408 3.89519 9.03854 4.36577Z"
        fill="#EAEAEA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.68513 4.49466C7.95798 4.45215 8.21363 4.63888 8.25614 4.91173C8.50756 6.52548 9.81724 7.76055 11.4455 7.92455C11.7203 7.95223 11.9206 8.19739 11.8929 8.47214C11.8652 8.74689 11.6201 8.94719 11.3453 8.91952C9.26696 8.71019 7.58997 7.13193 7.26806 5.06567C7.22555 4.79282 7.41228 4.53717 7.68513 4.49466Z"
        fill="#EAEAEA"
      />
    </svg>
  );
};

export default EditIcon;
