import { useForm } from "react-hook-form";
import { useAppSelector } from "../store/hooks";
import { useEffect, useMemo, useRef } from "react";
import { getOptions } from "../utill/helpers";
import {
  useEditDocumentMutation,
  useGetCategoriesQuery,
  useGetDocumentByIdQuery,
} from "../services/rtkQuery/documents";
import { selectCategories } from "../store/modules/documents/selectors";
import { useGetOrganizationsQuery } from "../services/rtkQuery/organization";
import { selectOrganizationUsers } from "../store/modules/organization/selectors";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Option } from "../view/ui/common/SelectBox";
import { getGeneralSelectBoxSchema } from "../utill/validation/validationSchemas";
import { toast } from "react-toastify";

const documentSchema = Yup.object().shape({
  title: Yup.string().required("Document name is required"),
  categoryId: getGeneralSelectBoxSchema("Category is required"),
  organizationId: getGeneralSelectBoxSchema("School partner is required"),
});

interface documentData {
  title: string;
  categoryId: Option;
  organizationId?: Option;
}

const defaultValues = {
  categoryId: { value: "", label: "" },
  title: "",
  status: "active",
  organizationId: { value: "", label: "" },
};

export const useEditDocument = (id: any, onSuccess: () => void) => {
  let defaultDocumentValues = useRef<documentData>();

  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm({ defaultValues, resolver: yupResolver(documentSchema) });
  useGetOrganizationsQuery({ getAll: true });
  useGetCategoriesQuery();
  const [updateDocument, { isSuccess: isEditingDocumentSuccess }] =
    useEditDocumentMutation();
  const { data } = useGetDocumentByIdQuery({ id });

  const organizationList = useAppSelector(selectOrganizationUsers);
  const categories = useAppSelector(selectCategories);

  const organizationOptions = useMemo(() => {
    if (organizationList && organizationList.rows) {
      return getOptions(organizationList?.rows, "name", "id");
    }
    return [];
  }, [organizationList?.rows]);

  const categoriesOptions = useMemo(() => {
    if (categories && categories.length) {
      return getOptions(categories, "name", "id");
    }
    return [];
  }, [categories]);

  useEffect(() => {
    if (data?.data) {
      const document = data?.data?.document;
      reset({
        title: document.title,
        categoryId: categoriesOptions.find(
          (el) => el.value === document.categoryId?.toString()
        ),
        organizationId: organizationOptions.find(
          (el) => el.value === document.organizationId?.toString()
        ),
      });
      defaultDocumentValues.current = getValues();
    }
  }, [data]);

  useEffect(() => {
    if (isEditingDocumentSuccess) {
      toast.success("Document was updated");
    }
  }, [isEditingDocumentSuccess]);

  const onSubmit = (data: documentData) => {
    const isChanged =
      JSON.stringify(defaultDocumentValues.current) !==
      JSON.stringify(getValues());
    if (isChanged) {
      updateDocument({
        documentId: id,
        data: { title: data.title, categoryId: data.categoryId.value },
      });
      onSuccess();
    } else {
      toast.info("You didn't change any fields");
    }
  };

  return {
    handleSubmit: handleSubmit(onSubmit),
    control,
    errors,
    organizationOptions,
    categoriesOptions,
  };
};
