import React, { FC, useState } from "react";
import { Modal } from "../../../components/Modal";
import CropIcon from "../../../assets/icons/CropIcon";
import { Button } from "../../../ui/common/Button";
import { TextField } from "../../../ui/common/TextField";

import { Option, SelectBox } from "../../../ui/common/SelectBox";
import { FileInput } from "../../../components/FileInput";
import { useCreateBorrowerDocument } from "../../../../hooks/useCreateBorroweDocument";
import { Control, Controller } from "react-hook-form";

interface CreateBorrowerDocumentModalProps {
  isOpenModal: boolean;
  closeModal: () => void;
  action: string;
  categories: Option[];
  file: File | any;
  setFile: (file: any) => void;
  handleSubmit: () => void;
  control: Control | any;
  isFileLoading?: boolean;
  errors?: any;
}

export const CreateBorrowerDocumentModal: FC<CreateBorrowerDocumentModalProps> = ({
  isOpenModal,
  closeModal,
  action,
  handleSubmit,
  file,
  setFile,
  control,
  categories,
  isFileLoading,
  errors,
}) => {
  const isCreation = action === "create";
  return (
    <Modal isOpened={isOpenModal} onClose={closeModal}>
      <div className="relative bg-[#FFF] p-[48px] rounded-[32px] w-[40%]">
        <div className=" flex justify-end">
          <div className="cursor-pointer" onClick={closeModal}>
            <CropIcon />
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <p className="text-[32px] font-medium text-[#474747]">
              {isCreation ? "Upload New" : "Edit"} Document
            </p>
            <p className="text-[16px] text-[#717984] mb-[24px]">Enter document information</p>
            <div>
              <p className="text-[20px] font-medium text-[#474747] mb-[16px]">
                Document Information
              </p>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <>
                    <TextField
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      label="Document Name*"
                      placeholder="Document Name"
                      type="input"
                    />
                    {errors?.title && (
                      <p className="text-[red] text-[13px]">{errors?.title.message}</p>
                    )}
                  </>
                )}
              />
            </div>
            <div className="mt-[16px]">
              <Controller
                name="categoryId"
                control={control}
                render={({ field }) => (
                  <>
                    <SelectBox
                      value={field.value}
                      onChange={field.onChange}
                      options={categories}
                      label="Category*"
                      placeholder="Insurance Certificates"
                      multiple={false}
                      disabled={!isCreation}
                    />
                    {errors?.categoryId?.value && (
                      <p className="text-[red] text-[13px]">{errors?.categoryId.value.message}</p>
                    )}
                  </>
                )}
              />
            </div>
            <div className="flex flex-col h-[90%] mt-[24px]">
              <p className="text-[20px] font-medium text-[#474747] mb-[10px]">
                Upload New Document*
              </p>
              <FileInput
                file={file}
                isFileLoading={isFileLoading}
                changeFile={setFile}
                disabled={!isCreation}
              />
            </div>
            <div className="mt-5">
              <Button text={`${isCreation ? "Save" : "Update"}`} type="submit" />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};
