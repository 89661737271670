import React, { useEffect, useMemo, useState } from "react";
import { Container } from "../../components/Container";
import { Button } from "../../ui/common/Button";
import { BorrowerFilters } from "./components/BorrowerFilters";
import { BorrowerTable } from "./components/BorrowerTable";
import { BorrowerTableBody } from "./components/BorrowerTableBody";
import BorrowerTableHeader from "./components/BorrowerTableHeader";
import Pagination from "../../ui/common/Pagination";
import { Option, SelectBox } from "../../ui/common/SelectBox";
import { numberPageUser } from "../../../utill/contants";
import { CreateBorrowerModal } from "./components/CreateBorrowerModal";
import { useLazyGetBorrowerUserQuery } from "../../../services/rtkQuery/borrower";
import { useAppSelector } from "../../../store/hooks";
import { selectBorrowerUser } from "../../../store/modules/borrower/selectors";
import { getOptions } from "../../../utill/helpers";
import { useCreateBorrowerUser } from "../../../hooks/useCreateBorrowerUser";

const Borrower = () => {
  const [fiscalYearEnd, setFiscalYearEnd] = useState<Date | "">("");
  const [itemsPerPage, setItemsPerPage] = useState(numberPageUser[1]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpenModal, setOpenModal] = useState(false);
  const borrowersUser = useAppSelector(selectBorrowerUser);
  const [searchQuery, setSearchQuery] = useState("");
  const [role, setRole] = useState<Option | undefined>(undefined);
  const [organizationId, setOrganizationId] = useState<Option | undefined>(
    undefined
  );
  const [status, setStatus] = useState<Option | undefined>(undefined);

  const [getBorrowerUsers] = useLazyGetBorrowerUserQuery();

  const openModal = () => {
    setOpenModal(true);
  };

  const onChangeSearchQuery = (value: string | number) => {
    setSearchQuery(value.toString());
  };

  const onChangeRole = (event: Option | Option[]) => {
    setRole(event as Option);
  };

  const onChangeOrganization = (event: Option | Option[]) => {
    setOrganizationId(event as Option);
  };

  const onChangeStatus = (event: Option | Option[]) => {
    setStatus(event as Option);
  };

  useEffect(() => {
    getBorrowerUsers({
      search: searchQuery,
      limit: +itemsPerPage.value,
      page: currentPage,
      role: role && role.value && role.value.length ? role.value : undefined,
      organizationId:
        organizationId && organizationId.value && organizationId.value.length
          ? organizationId.value
          : undefined,
      status:
        status && status.value && status.value.length
          ? status.value
          : undefined,
    });
  }, [searchQuery, currentPage, role, status, itemsPerPage, organizationId]);
  const closeModal = () => {
    setOpenModal(false);
  };

  const { control, handleSubmit, errors, organizationOptions } =
    useCreateBorrowerUser(closeModal);

  return (
    <div>
      <Container>
        <div>
          <div className="flex justify-between items-center w-full mb-[24px]">
            <div className="w-[90%]">
              <p className="text-[24px] text-[#474747] font-bold">
                School Partner Contacts List
              </p>
              <p className="text-[14px] text-[#717984] folt-medium">
                Manage School Partner Contacts
              </p>
            </div>
            <div className="w-[13%] text-[15.5px]">
              <Button text="+ New Contact" onClick={openModal} />
            </div>
          </div>
          <div>
            <BorrowerFilters
              setFiscalYearEnd={setFiscalYearEnd}
              fiscalYearEnd={fiscalYearEnd}
              value={searchQuery}
              setValue={onChangeSearchQuery}
              status={status}
              onChangeStatus={onChangeStatus}
              onChangeRole={onChangeRole}
              role={role}
              organizationId={organizationId}
              onChangeOrganization={onChangeOrganization}
              organizationOptions={organizationOptions}
            />
          </div>
          <div>
            <div>
              <BorrowerTable
                BodyComponent={BorrowerTableBody}
                HeaderComponent={BorrowerTableHeader}
                borrowerList={borrowersUser?.rows}
              />
            </div>
          </div>
          <div className="flex justify-between">
            <div className="w-[70%] justify-start">
              <Pagination
                itemsPerPage={+itemsPerPage.value}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
                userCount={borrowersUser?.count ?? 1}
              />
            </div>
            <div className="w-[30%] flex items-center justify-end gap-[16px]">
              <p className="text-[13px] text-[#717984]">
                Showing 1 to 6 of 50 entries
              </p>
              <div className="flex h-[32px] p-[10px] justify-center items-center gap-[10px]">
                <SelectBox
                  value={itemsPerPage}
                  onChange={(value: Option | Option[]) =>
                    setItemsPerPage(value as Option)
                  }
                  options={numberPageUser}
                  placeholder="Show 6"
                  multiple={false}
                  searchEnabled={false}
                  placement={"top"}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
      {isOpenModal && (
        <CreateBorrowerModal
          isOpenModal={isOpenModal}
          closeModal={closeModal}
          control={control}
          handleSubmit={handleSubmit}
          action="create"
          errors={errors}
          organizationOptions={organizationOptions}
        />
      )}
    </div>
  );
};

export default Borrower;
