import { FC, useState } from "react";
import { Modal } from "../../../components/Modal";
import CropIcon from "../../../assets/icons/CropIcon";

import { Controller } from "react-hook-form";

import { SelectBox } from "../../../ui/common/SelectBox";

import { Button } from "../../../ui/common/Button";

import { useAssignLenderToOrganization } from "../../../../hooks/useAssignLender";

interface AssignLenderModalProps {
  isOpenModal: boolean;
  closeModal: () => void;
  organizationId: string;
}

export const AssignLenderModal: FC<AssignLenderModalProps> = ({
  isOpenModal,
  closeModal,
  organizationId,
}) => {
  const { control, handleSubmit, lenderOptions, errors } = useAssignLenderToOrganization(
    organizationId,
    closeModal
  );

  return (
    <Modal isOpened={isOpenModal} onClose={closeModal}>
      <div className="relative bg-[#FFF] p-[48px] rounded-[32px] w-[520px]">
        <div className=" flex justify-end">
          <div className="cursor-pointer" onClick={closeModal}>
            <CropIcon />
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <p className="text-[28px] font-medium text-[#474747]">Assign carewolves</p>
          </div>
          <div className="flex flex-col gap-4 mb-[16px] mt-[24px]">
            <p className="text-[20px] text-[#474747] font-medium ">Carewolves</p>
            <div>
              <Controller
                control={control}
                name="lenderId"
                render={({ field }) => (
                  <>
                    <SelectBox
                      value={field.value}
                      onChange={field.onChange}
                      options={lenderOptions}
                      label=""
                      placeholder="Select carewolves"
                      placement="bottom"
                      multiple={false}
                    />
                    {errors?.lenderId?.value && (
                      <p className="text-[red] text-[13px]">{errors?.lenderId.value.message}</p>
                    )}
                  </>
                )}
              />
            </div>
          </div>
          <div className="flex justify-end mt-[24px]">
            <div className="w-[100%]">
              <Button text="Save" type="submit" />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};
