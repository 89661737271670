import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { EmployeeState } from "./types";
import { EmployeeModel, ILenderUser } from "../../../models/employee";
import { employeeApi } from "../../../services/rtkQuery/employee";
import OrganizationList from "../../../view/pages/Organization";

export const initialState: EmployeeState = {
  lenderUsers: [],
  lenderUser: null,
  organizationList: null,
};

const employeeSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLenderUsers(state, action: PayloadAction<EmployeeModel>) {
      state.lenderUsers = action.payload && action.payload.data.lendUsers;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      employeeApi.endpoints.getLenderUsers.matchFulfilled,
      (state, { payload }: any) => {
        state.lenderUsers = payload.data.lendUsers;
      }
    );
    builder.addMatcher(
      employeeApi.endpoints.createLenderUser.matchFulfilled,
      (state, { payload }: any) => {}
    );
    builder.addMatcher(
      employeeApi.endpoints.getLenderUserById.matchFulfilled,
      (state, { payload }: any) => {
        state.lenderUser = payload.data;
      }
    );
    builder.addMatcher(
      employeeApi.endpoints.getOrganizationsUserById.matchFulfilled,
      (state, { payload }: any) => {
        state.organizationList = payload.data;
      }
    );
  },
});

export const employeeReducer = employeeSlice.reducer;
export const { setLenderUsers } = employeeSlice.actions;
