import { rtkQuery } from "..";
import { tags } from "../cache";
import {
  CreateBorroweData,
  CreateBorrowerWithOrgData,
  GetBorrowersRequestParams,
} from "./types";

export const borrowerApi = rtkQuery.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getBorrowerUser: builder.query<any, GetBorrowersRequestParams>({
      query: ({
        limit,
        page,
        search,
        role,
        status,
        getAll,
        organizationId,
      }) => {
        const params: any = {};

        if (search) {
          params.search = search;
        }
        if (role) {
          params.role = role;
        }
        if (page) {
          params.page = page;
        }
        if (status) {
          params.status = status;
        }
        if (getAll) {
          params.getAll = getAll;
        }
        if (organizationId) {
          params.organizationId = organizationId;
        }
        params.limit = limit;

        return {
          url: "/users/borrower-users",
          params,
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: [tags.BORROWER],
    }),
    createBorrower: builder.mutation<any, any>({
      query: (borrowerData) => {
        return {
          url: `/users/borrower-user`,
          method: "POST",
          body: JSON.stringify(borrowerData),
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: [tags.BORROWER],
    }),
    createBorrowerAsBorrower: builder.mutation<any, CreateBorroweData>({
      query: (borrowerData) => {
        return {
          url: `/users/borrower`,
          method: "POST",
          body: JSON.stringify(borrowerData),
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: [tags.BORROWER],
    }),
    createBorrowerWithOrgId: builder.mutation<any, CreateBorrowerWithOrgData>({
      query: (borrowerData) => {
        return {
          url: `/users/borrower-orgnization`,
          method: "POST",
          body: JSON.stringify(borrowerData),
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: [tags.ORGANIZATION, tags.ORGANIZATION_ID],
    }),
    deleteBorrower: builder.mutation<any, any>({
      query: (id: string) => {
        return {
          url: `/users/borrower-user/${id}`,
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: [tags.BORROWER],
    }),
    editBorrower: builder.mutation<any, any>({
      query: ({ id, data }) => {
        return {
          url: `/users/borrower-user/${id}`,
          method: "PUT",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: [tags.BORROWER],
    }),
  }),
});

export const {
  useGetBorrowerUserQuery,
  useLazyGetBorrowerUserQuery,
  useCreateBorrowerMutation,
  useDeleteBorrowerMutation,
  useCreateBorrowerWithOrgIdMutation,
  useEditBorrowerMutation,
  useCreateBorrowerAsBorrowerMutation,
} = borrowerApi;
