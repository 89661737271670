import React from "react";

const LogoIcon = ({
  width,
  height,
}: {
  width?: number;
  height?: number | string;
}) => {
  return (
    <img
      src={require("./Logo.png")}
      alt="Logo"
      width={width}
      style={{ height: height }}
    />
  );
};

export default LogoIcon;
