import { FC, ReactNode } from "react";
import { Option } from "../../../../ui/common/SelectBox";

interface BorrowerContactsProps {
  borrower: Option;
  icon?: ReactNode;
  deleteBorrower: (id: string) => void;
}

export const BorrowerContacts: FC<BorrowerContactsProps> = ({ borrower, icon, deleteBorrower }) => {
  return (
    <div className="relative">
      <p className="w-full border-[1px] border-[lightgray] focus:border-2 rounded-[12px] px-3 py-2 focus:outline-none focus:border-[#4D5187]">
        {borrower?.label}
      </p>
      <button
        className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-600"
        onClick={() => {
          deleteBorrower(borrower.value);
        }}
      >
        {icon}
      </button>
    </div>
  );
};
