import React from "react";

const SortIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M6.46055 2.3202C6.75848 2.02657 7.24152 2.02657 7.53945 2.3202L10.275 5.01639C10.7556 5.49006 10.4152 6.29998 9.73558 6.29998H4.26442C3.58476 6.29998 3.24438 5.49006 3.72498 5.01639L6.46055 2.3202Z"
        fill="#717984"
      />
      <path
        d="M6.46055 11.6797C6.75848 11.9734 7.24152 11.9734 7.53945 11.6797L10.275 8.98356C10.7556 8.50989 10.4152 7.69998 9.73558 7.69998H4.26442C3.58476 7.69998 3.24438 8.50989 3.72498 8.98356L6.46055 11.6797Z"
        fill="#717984"
      />
    </svg>
  );
};

export default SortIcon;
