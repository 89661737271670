import { rtkQuery } from "..";
import { GetCategoriesResponse } from "../../../store/modules/documents/types";
import { tags } from "../cache";
import { GetDocumentByIdRequestParams, GetDocumentRequestParams } from "./types";

export const documentsApi = rtkQuery.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getOrganizationsDocuments: builder.query<any, GetDocumentRequestParams>({
      query: ({ id, limit, page, title, categoryId }) => {
        return {
          url: `/documents/document/${id}`,
          params: {
            limit,
            page,
            title,
            categoryId,
          },
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
    }),
    getCategories: builder.query<GetCategoriesResponse, void>({
      query: () => {
        return {
          url: `/categories`,
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
    }),
    getDocuments: builder.query<any, GetDocumentRequestParams>({
      query: ({ limit = 6, page, title, categoryId, organizationId, status }) => {
        return {
          url: `/documents/admin/document/`,
          params: {
            limit,
            page,
            title,
            categoryId,
            organizationId,
            status,
          },
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: [tags.DOCUMENTS],
    }),
    uploadFile: builder.mutation<any, any>({
      query: (fileData) => {
        return {
          url: `/documents/upload`,
          method: "POST",
          body: fileData,
        };
      },
      invalidatesTags: [tags.DOCUMENTS],
    }),
    createDocument: builder.mutation<any, any>({
      query: (data) => {
        return {
          url: `/documents/admin`,
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: [tags.DOCUMENTS, tags.ORGANIZATION_ID],
    }),
    deleteDocument: builder.mutation<any, any>({
      query: (documentId: number) => {
        return {
          url: `/documents/admin/document/${documentId}`,
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: [tags.DOCUMENTS],
    }),
    getDocumentById: builder.query<any, GetDocumentByIdRequestParams>({
      query: ({ id }) => {
        return {
          url: `/documents/admin/document/${id}`,
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
    }),
    editDocument: builder.mutation<any, any>({
      query: ({ documentId, data }) => {
        return {
          url: `/documents/admin/document/${documentId}`,
          method: "PUT",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: [tags.DOCUMENTS],
    }),
    getBorrowerDocuments: builder.query<any, GetDocumentRequestParams>({
      query: ({ limit = 6, page, title, categoryId }) => {
        return {
          url: `/documents/borrower/document`,
          params: {
            limit,
            page,
            title,
            categoryId,
          },
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: [tags.BORROWER_DOCUMENTS],
    }),
    createBorrowerDocument: builder.mutation<any, any>({
      query: (data) => {
        return {
          url: `/documents/borrower`,
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: [tags.BORROWER_DOCUMENTS],
    }),
    editBorrowerDocument: builder.mutation<any, any>({
      query: ({ documentId, data }) => {
        return {
          url: `/documents/borrower/document/${documentId}`,
          method: "PUT",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: [tags.BORROWER_DOCUMENTS],
    }),
    deleteBorrowerDocument: builder.mutation<any, any>({
      query: (documentId: number) => {
        return {
          url: `/documents/borrower/document/${documentId}`,
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: [tags.BORROWER_DOCUMENTS],
    }),
  }),
});

export const {
  useLazyGetOrganizationsDocumentsQuery,
  useLazyGetBorrowerDocumentsQuery,
  useLazyGetDocumentsQuery,
  useGetCategoriesQuery,
  useGetDocumentsQuery,
  useUploadFileMutation,
  useCreateDocumentMutation,
  useDeleteDocumentMutation,
  useDeleteBorrowerDocumentMutation,
  useGetDocumentByIdQuery,
  useEditDocumentMutation,
  useEditBorrowerDocumentMutation,
  useCreateBorrowerDocumentMutation,
} = documentsApi;
