import React, { FC } from "react";

interface ContainerProps {
  children: React.ReactNode;
  fullHeight?: boolean;
}
export const Container: FC<ContainerProps> = ({ children, fullHeight = true }) => {
  return (
    <div className={`p-6 w-full min-h-[calc(100vh-114px)] bg-[#4F5188] flex flex-col`}>
      <div className="bg-white flex-1 rounded-md p-6">{children}</div>
    </div>
  );
};
