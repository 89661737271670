import React from "react";

const UserIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M5.90002 20.5C7.50002 19 9.60002 18 12 18C14.3 18 16.5 18.9 18.1 20.5"
        stroke="#4D5187"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.1 9.90002C15.3 11.1 15.3 13 14.1 14.1C12.9 15.2 11 15.3 9.90002 14.1C8.80002 12.9 8.70002 11 9.90002 9.90002C11.1 8.80002 12.9 8.70002 14.1 9.90002"
        stroke="#4D5187"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4 17.5C3.4 16.3 3 14.9 3 13.5C3 8.5 7 4.5 12 4.5C17 4.5 21 8.5 21 13.5C21 14.9 20.6 16.3 20 17.5"
        stroke="#4D5187"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default UserIcon;
