import { createSelector, Selector } from "@reduxjs/toolkit";

import { RootState } from "../../types";

import { OrganizationState } from "./types";

const selectSelf: Selector<RootState, OrganizationState> = (state) =>
  state.organization;

export const selectOrganizationUsers = createSelector(selectSelf, (state) => {
  return state.organizationUsers;
});
export const selectOrganizationById = createSelector(selectSelf, (state) => {
  return state.organizationById;
});
