import React from "react";

const ChevronIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.0876 4.83748C3.31541 4.60967 3.68475 4.60967 3.91256 4.83748L7.00008 7.925L10.0876 4.83748C10.3154 4.60967 10.6848 4.60967 10.9126 4.83748C11.1404 5.06529 11.1404 5.43463 10.9126 5.66244L7.41256 9.16244C7.18475 9.39024 6.81541 9.39024 6.5876 9.16244L3.0876 5.66244C2.8598 5.43463 2.8598 5.06529 3.0876 4.83748Z"
        fill="#474747"
      />
    </svg>
  );
};

export default ChevronIcon;
