import React, { useEffect, useMemo, useState } from "react";
import Header from "../../components/Header";
import { Container } from "../../components/Container";
import CroupLeftIcon from "../../assets/icons/GroupLeftIcon";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UserInfo } from "./components/UserInfo";
import { Button } from "../../ui/common/Button";
import EditIcon from "../../assets/icons/EditIcon";
import TrashIcon from "../../assets/icons/TrashIcon";
import { CharterSchoolsFilters } from "./components/CharterSchoolsFilters";
import { EmployeeDetailsTableHead } from "./components/EmployeeDetailsTableHead";
import { EmployeeDetailsTableBody } from "./components/EmployeeDetailsTableBody";
import {
  useDeleteLenderUserMutation,
  useGetOrganizationsUserByIdQuery,
  useLazyGetLenderUserByIdQuery,
  useLazyGetOrganizationsUserByIdQuery,
} from "../../../services/rtkQuery/employee";
import { useAppSelector } from "../../../store/hooks";
import {
  selectLenderUsers,
  selectOrganizationList,
  selecteUserById,
} from "../../../store/modules/employee/selectors";
import Pagination from "../../ui/common/Pagination";
import OrganizationTable from "../../components/OrganizationTable";
import { Option, SelectBox } from "../../ui/common/SelectBox";
import { numberPageAssigned } from "../../../utill/contants";
import { DeleteConfirmationModal } from "../../components/DeleteConfirmationModal";
import { CreateEmployeeModal } from "../Employee/components/CreateEmployeeModal";
import { AssingToCharterModal } from "../Employee/components/AssingToCharterModal";
import { getOptions } from "../../../utill/helpers";
import { useEditLender } from "../../../hooks/useEditLender";

const EmployeeDetails = () => {
  const params = useParams();
  const navigation = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [isOpenModal, setOpenModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [seacrhQuery, setSearchQuery] = useState("");
  const [selectedValue, setSelectedValue] = useState({
    label: "All Assigned School Partners",
    value: "",
  });
  const [itemsPerPage, setItemsPerPage] = useState(numberPageAssigned[1]);
  const [assignedDate, setAssignedDate] = useState(null);
  const [showDelete, setShowDelete] = useState(false);

  const { id } = params;

  const [deleteUser] = useDeleteLenderUserMutation();
  const [getOrganization] = useLazyGetOrganizationsUserByIdQuery();
  const [getLenderUserById] = useLazyGetLenderUserByIdQuery();
  // useGetLenderUserByIdQuery({ id });
  useGetOrganizationsUserByIdQuery({ id });

  const lenderUser = useAppSelector(selecteUserById);
  const organizationList = useAppSelector(selectOrganizationList);

  const onChangeSelectedValue = (e: Option | Option[]) => {
    if (Array.isArray(e)) {
      return;
    }
    setSelectedValue(e);
  };

  const onChangeSearchQuery = (value: string | number) => {
    setSearchQuery(value.toString());
  };

  const onDeleteUser = () => {
    deleteUser(id);
    navigation("/employee");
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const closeEditModal = () => {
    setIsOpenEditModal(false);
  };

  const openEditModal = () => {
    setIsOpenEditModal(true);
  };

  const options: Option[] = useMemo(() => {
    if (organizationList) {
      return getOptions(organizationList.rows, "name", "id");
    }
    return [];
  }, [organizationList]);

  useEffect(() => {
    getOrganization({
      name: seacrhQuery,
      id,
      page: currentPage,
      limit: itemsPerPage.value,
      organizationId: selectedValue.value.length > 0 ? +selectedValue.value : undefined,
      createdAt: assignedDate,
    });
  }, [seacrhQuery, getOrganization, selectedValue, currentPage, assignedDate]);

  useEffect(() => {
    getLenderUserById({
      id,
    });
  }, [id, getLenderUserById]);

  const { control, handleSubmit, errors } = useEditLender(Number(id), closeEditModal);

  return (
    <div>
      <Container>
        <div className="h-full">
          <div className="h-[90%]">
            <Link to="/employee" className="items-center flex text-[14px] text-[#000] font-bold">
              <div className="flex items-center">
                <CroupLeftIcon />
              </div>
              Back
            </Link>
            <div className="flex justify-between mt-[24px]">
              {lenderUser && (
                <div className="w-[65%] ">
                  <UserInfo lenderUser={lenderUser} />
                </div>
              )}
              <div className="flex justify-between items-center w-[25%] gap-[16px]">
                <Button
                  type="button"
                  text="+ Assign to School Partner"
                  onClick={() => setOpenModal(true)}
                  className="text-[15.5px] w-[60%] px-[12px] py-[12px] items-center"
                />

                {/* <div className="flex gap-[12px]">
                  <button
                    onClick={openEditModal}
                    className="w-[40px] h-[40px] p-[12px] justify-center items-center gap-8 bg-[#434784] rounded-lg"
                  >
                    <EditIcon />
                  </button>
                  <button
                    onClick={() => setShowDelete(true)}
                    disabled={lenderUser?.status === "inactive"}
                    className=" w-[40px] h-[40px] p-[12px] justify-center items-center gap-8 bg-[#E04D4D] rounded-lg"
                  >
                    <TrashIcon />
                  </button>
                </div> */}
              </div>
            </div>
            <div className=" mb-[24px] mt-[24px]">
              <CharterSchoolsFilters
                withAllSelection
                value={seacrhQuery}
                setValue={onChangeSearchQuery}
                options={options}
                onSelect={onChangeSelectedValue}
                selectedValue={selectedValue}
                assignedDate={assignedDate}
                setAssignedDate={setAssignedDate}
              />
            </div>
            <div>
              {organizationList && organizationList.rows.length > 0 ? (
                <OrganizationTable
                  HeaderComponent={EmployeeDetailsTableHead}
                  BodyComponent={EmployeeDetailsTableBody}
                  organizationList={organizationList.rows}
                />
              ) : (
                <p>Not found</p>
              )}
            </div>
          </div>
          <div className="flex justify-between items-center h-[10%]">
            <div className="w-[70%] justify-start">
              <Pagination
                itemsPerPage={+itemsPerPage.value}
                userCount={organizationList?.count ?? 1}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
              />
            </div>
            <div className="w-[30%] flex items-center justify-end gap-[16px]">
              <p className="text-[13px] text-[#717984]">Showing 1 to 5 of 50 entries</p>
              <div className="flex h-[32px] p-[10px] justify-center items-center gap-[10px]">
                <SelectBox
                  value={itemsPerPage}
                  onChange={(value: Option | Option[]) => setItemsPerPage(value as Option)}
                  options={numberPageAssigned}
                  placeholder="Show 5"
                  multiple={false}
                  searchEnabled={false}
                />
              </div>
            </div>
          </div>
        </div>
        {showDelete && (
          <DeleteConfirmationModal
            isOpenModal={showDelete}
            title="Confirmation of User Deletion"
            text="Are you sure you want to delete this User?"
            closeModal={() => setShowDelete(false)}
            onDelete={onDeleteUser}
          />
        )}
      </Container>
      {isOpenModal && (
        <AssingToCharterModal isOpenModal={isOpenModal} closeModal={closeModal} userId={id} />
      )}
      {isOpenEditModal && (
        <CreateEmployeeModal
          isOpenModal={isOpenEditModal}
          closeModal={closeEditModal}
          control={control}
          handleSubmit={handleSubmit}
          action={"edit"}
          errors={errors}
        />
      )}
    </div>
  );
};

export default EmployeeDetails;
