import { FC } from "react";

import { BorrowerDocumentsListTableRow } from "./BorrowerDocumentsListTableRow";
import { Document } from "../../../../store/modules/documents/types";

interface BorrowerDocumentsListTableBodyProps {
  documentsList: Document[];
}

export const BorrowerDocumentsListTableBody: FC<
  BorrowerDocumentsListTableBodyProps
> = ({ documentsList }) => {
  return (
    <tbody className="w-full gap-[10px] items-center text-center">
      {(documentsList || []).map((document: Document) => (
        <BorrowerDocumentsListTableRow
          documentItem={document}
          key={document.id}
        />
      ))}
    </tbody>
  );
};
