import React, { FC } from "react";
import { AuthWrapper } from "../components/AuthWrapper";
import { Button } from "../../../ui/common/Button";
import { Link } from "react-router-dom";
import IllustrationIcon from "../../../assets/icons/IllustrationIcon";

const SuccessPassword: FC = () => {
  return (
    <AuthWrapper>
      <div className="flex flex-col items-center gap-2">
        <div className="w-full mt-7 flex flex-col items-center ">
          <IllustrationIcon />
        </div>

        <h1 className="text-[32px] text-center w-full">
          You successfully changed your password
        </h1>
        <p className="text-[16px] text-[#717984] text-center w-full mb-[20px]">
          Remember that you can always change your password in your account
          settings if needed.
        </p>
        <div className="w-full mb-8">
          <Link to="/login">
            <Button text="Back to Login" />
          </Link>
        </div>
      </div>
    </AuthWrapper>
  );
};
export default SuccessPassword;
