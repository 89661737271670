import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { UserProfileModel } from "../../../models/user";
import { userApi } from "../../../services/rtkQuery/user";
import { resetStore } from "../app";

import { UserState, UserAuth } from "./types";

export const initialState: UserState = {
  user: null,
  access_token: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAuth(state, action: PayloadAction<string>) {
      state.access_token = action.payload;
    },
    setUser(state, action: PayloadAction<UserProfileModel | null>) {
      state.user = action.payload;
    },
    logout(state) {
      state.access_token = "";
      state.user = null;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      userApi.endpoints.login.matchFulfilled,
      (state, { payload }: any) => {
        state.user = payload.data.user;
        state.access_token = payload.data.token;
      }
    );
  },
});

export const userReducer = userSlice.reducer;
export const { setAuth, setUser, logout } = userSlice.actions;
