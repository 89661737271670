import React, { FC, useEffect, useState } from "react";
import { Modal } from "../../../components/Modal";
import CropIcon from "../../../assets/icons/CropIcon";
import { Controller } from "react-hook-form";
import { TextField } from "../../../ui/common/TextField";
import EmailIcon from "../../../assets/icons/EmailIcon";
import { useCreateOrganization } from "../../../../hooks/useCreateOrganization";
import { TextArea } from "../../../components/TextArea";
import DataPiker from "../../../components/DatePicker";
import SchoolIcon from "../../../assets/icons/SchoolIcon";
import UserIcon from "../../../assets/icons/UserIcon";
import { Switcher } from "../../../ui/common/Switch";
import { Button } from "../../../ui/common/Button";
import { Option, SelectBox } from "../../../ui/common/SelectBox";
import { BorrowerContacts } from "./BorrowerContacts/BorrowerContacts";
import { datePickListOptions } from "../../../../utill/contants";
interface CreateOrganizationModalProps {
  isOpenModal: boolean;
  closeModal: () => void;
  control: any;
  setValue: any;
  handleSubmit: () => void;
  borrowerContactsOptions: Option[];
  options: Option[];
  action?: "edit" | "create";
  selectBorrowerValue?: Option[] | undefined;
  watch?: any;
  errors?: any;
  reset: any;
}
export const CreateOrganizationModal: FC<CreateOrganizationModalProps> = ({
  isOpenModal,
  closeModal,
  control,
  setValue,
  handleSubmit,
  borrowerContactsOptions,
  options,
  action = "create",
  watch,
  errors,
  reset,
}) => {
  const isEdit = action === "edit";
  const [showAssignMember, setShowTeamMember] = useState(
    isEdit && watch().assigned_user_id?.value ? true : false
  );
  const [isVisibleContactsSelectBox, setIsVisibleContactsSelectBox] = useState(
    isEdit ? true : false
  );

  const closeModalHandler = () => {
    reset();
    closeModal();
  };
  const [selectedBorrowerContacts, setSelectedBorrowerContacts] = useState<
    Option[]
  >([]);

  const selectBorrowers = (data: any) => {
    setSelectedBorrowerContacts(data);
  };

  const deleteBorrower = (id: string) => {
    const filteredData = selectedBorrowerContacts.filter(
      (el) => el.value !== id
    );
    setSelectedBorrowerContacts(filteredData);
  };

  useEffect(() => {
    setValue("userIds", selectedBorrowerContacts);
  }, [selectedBorrowerContacts]);

  return (
    <Modal isOpened={isOpenModal} onClose={closeModalHandler}>
      <div className="relative bg-[#FFF] p-[48px] rounded-[32px] w-[70%]">
        <div className=" flex justify-end">
          <div className="cursor-pointer" onClick={closeModalHandler}>
            <CropIcon />
          </div>
        </div>
        <form className="flex justify-between" onSubmit={handleSubmit}>
          <div className="w-[48%]">
            <p className="text-[32px] font-medium text-[#474747]">
              {isEdit ? "Edit School Partner" : "New School Partner"}
            </p>
            <p className="text-[16px] text-[#717984] mb-[24px]">
              Enter School Partner information
            </p>
            <div>
              <p className="text-[20px] font-medium text-[#474747] mb-[16px]">
                School Partner Information
              </p>
              <Controller
                name="name"
                control={control}
                rules={{ required: "School partner is required" }}
                render={({ field }) => (
                  <>
                    <TextField
                      icon={<SchoolIcon />}
                      value={field.value}
                      label="School Partner*"
                      placeholder="Enter your school partner"
                      type="text"
                      onChange={field.onChange}
                    />
                    {errors?.name && (
                      <p className="text-[red] text-[13px]">
                        {errors?.name?.message}
                      </p>
                    )}
                  </>
                )}
              />
              {!isEdit && (
                <div className="flex justify-between mb-[16px] mt-[24px]">
                  <p className="text-[20px] text-[#474747] font-medium">
                    School Partner Contact
                  </p>

                  <button
                    type="button"
                    onClick={() => {
                      setIsVisibleContactsSelectBox(
                        !isVisibleContactsSelectBox
                      );
                    }}
                  >
                    <p className="text-[#4D5187]">
                      {isVisibleContactsSelectBox
                        ? "Add new"
                        : "Add an existing"}
                    </p>
                  </button>
                </div>
              )}

              {isVisibleContactsSelectBox && !isEdit ? (
                <div>
                  <SelectBox
                    value={selectedBorrowerContacts}
                    onChange={selectBorrowers}
                    options={borrowerContactsOptions}
                    label=""
                    placeholder="Select School Partner Contact"
                    placement="bottom"
                    multiple={true}
                  />
                </div>
              ) : (
                !isEdit && (
                  <div>
                    <Controller
                      name="fullName"
                      control={control}
                      rules={{ required: "Full name is require" }}
                      render={({ field }) => (
                        <TextField
                          icon={<UserIcon />}
                          value={field.value}
                          label="Full Name*"
                          placeholder="Enter Full Name"
                          type="text"
                          className="mb-[24px]"
                          onChange={field.onChange}
                        />
                      )}
                    />
                    <Controller
                      name="email"
                      control={control}
                      rules={{ required: "Email is require" }}
                      render={({ field }) => (
                        <TextField
                          icon={<EmailIcon />}
                          value={field.value}
                          label="Email Address*"
                          placeholder="Enter Email Address"
                          type="text"
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </div>
                )
              )}
              <div className="flex justify-between items-center mb-[16px]">
                <p className="text-[20px] font-medium">Assign a Carewolf</p>
                <div className="w-[30%]">
                  <Switcher
                    checked={showAssignMember}
                    onChange={setShowTeamMember}
                    label=""
                  />
                </div>
              </div>
              {showAssignMember && (
                <Controller
                  control={control}
                  name="assigned_user_id"
                  render={({ field }) => (
                    <SelectBox
                      value={field.value}
                      onChange={field.onChange}
                      options={options}
                      label=""
                      placeholder="Select Carewolf"
                      placement="top"
                      multiple={false}
                    />
                  )}
                />
              )}
            </div>
          </div>
          <div className="w-[48%]">
            <div>
              <p className="text-[20px] font-medium text-[#474747] mb-[16px]">
                Additional Notes on Loans Outstanding
              </p>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextArea
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    label="Description"
                    placeholder="Description"
                  />
                )}
              />
              <div className="w-full">
                <Controller
                  control={control}
                  name="fiscalYearEnd"
                  rules={{ required: "Fiscal Year End  is required" }}
                  render={({ field }) => (
                    <>
                      <SelectBox
                        value={field.value}
                        onChange={field.onChange}
                        options={datePickListOptions}
                        label="Fiscal Year End*"
                        placeholder="Year"
                        placement="bottom"
                        multiple={false}
                        searchEnabled={false}
                      />
                      {errors?.fiscalYearEnd && (
                        <p className="text-[red] text-[13px]">
                          {errors?.fiscalYearEnd?.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
            </div>
            <div className="flex justify-end h-[10%] mt-[45px]">
              <div className="w-[50%]">
                <Button
                  text={isEdit ? "Update" : "Create and Send Access"}
                  type="submit"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};
