import React, { FC } from "react";
import { DocumentsListTableRow } from "./DocumentsListTableRow";
import { Document } from "../../../../store/modules/documents/types";

interface DocumentsListTableBodyProps {
  documentsList: Document[];
}

export const DocumentsListTableBody: FC<DocumentsListTableBodyProps> = ({
  documentsList,
}) => {
  return (
    <tbody className="w-full gap-[10px] items-center text-center">
      {(documentsList || []).map((document: Document) => (
        <DocumentsListTableRow documentItem={document} />
      ))}
    </tbody>
  );
};
