import { Document } from "../store/modules/documents/types";

export const getOptions = (collection: any[], label: string, value: string) => {
  return collection?.map((item) => {
    return {
      value: item[value].toString(),
      label: item[label],
    };
  });
};

export const prepareDownloadAttributes = (file: Document) => {
  if (!file.url) {
    return { href: '#', download: null };
  }

  const urlSegments = file.url.split('/');
  const lastSegment = urlSegments[urlSegments.length - 1];
  const nameSegments = lastSegment.split('.');
  const extension = nameSegments.length > 1 ? nameSegments.pop() : '';
  const baseName = file.title || nameSegments.join('.');
  const safeName = baseName.replace(/[^a-zA-Z0-9.]/g, '_');

  return {
    href: file.url,
    download: `${safeName}.${extension}`,
  };
};