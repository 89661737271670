import React, { FC } from "react";
import Switch from "react-switch";

interface SwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label: string;
}

export const Switcher: FC<SwitchProps> = ({ checked, onChange, label }) => {
  return (
    <div className="px-3 py-2 w-full flex justify-between mt-2">
      <label className="ml-[4px] text-[14px] font-medium">{label}</label>
      <Switch
        onChange={onChange}
        checked={checked}
        checkedIcon={<></>}
        uncheckedIcon={<></>}
        onColor="#95B7A2"
      />
    </div>
  );
};
