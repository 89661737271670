import React from "react";

const VectorIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.80541 2.94006C6.01372 2.94006 2.93994 6.01384 2.93994 9.80553C2.93994 13.5972 6.01372 16.671 9.80541 16.671C13.5971 16.671 16.6709 13.5972 16.6709 9.80553C16.6709 6.01384 13.5971 2.94006 9.80541 2.94006ZM1.68994 9.80553C1.68994 5.32348 5.32336 1.69006 9.80541 1.69006C14.2875 1.69006 17.9209 5.32348 17.9209 9.80553C17.9209 14.2876 14.2875 17.921 9.80541 17.921C5.32336 17.921 1.68994 14.2876 1.68994 9.80553Z"
        fill="#474747"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5727 14.9629C14.8164 14.7185 15.2121 14.718 15.4565 14.9618L18.3932 17.8909C18.6376 18.1346 18.6381 18.5303 18.3944 18.7747C18.1506 19.0191 17.7549 19.0196 17.5105 18.7759L14.5738 15.8468C14.3294 15.6031 14.3289 15.2073 14.5727 14.9629Z"
        fill="#474747"
      />
    </svg>
  );
};

export default VectorIcon;
