import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";

import { toast } from "react-toastify";

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (action.payload?.data) {
      toast.error(action.payload?.data?.error);
      return next(action);
    }
    if (action.payload?.status === "FETCH_ERROR") {
      toast.error("Service unavailable. Please try again later");
      return next(action);
    }

    toast.error("Something went wrong...");
  }

  return next(action);
};
