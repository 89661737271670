import React, { FC, useEffect } from "react";
import { AuthWrapper } from "../components/AuthWrapper";
import { Controller } from "react-hook-form";
import { TextField } from "../../../ui/common/TextField";
import EmailIcon from "../../../assets/icons/EmailIcon";
import { useRecoveryPassword } from "../../../../hooks/useRecoveryPassword";
import { Button } from "../../../ui/common/Button";
import ArrowIcon from "../../../assets/icons/ArrowIcon";
import { Link, useNavigate } from "react-router-dom";

const RecoveryPassword: FC = () => {
  const navigate = useNavigate();

  const onSuccess = () => {
    navigate("/login");
  };
  const { handleSubmit, control, errors, customErrorMessage } = useRecoveryPassword(onSuccess);

  return (
    <AuthWrapper>
      <div className="flex flex-col items-center gap-2">
        <div className=" flex justify-start w-full">
          <Link to="/login">
            <ArrowIcon />
          </Link>
        </div>
        <h1 className="text-[32px] text-center w-full">Password recovery</h1>
        <p className="text-[16px] text-[#717984] text-center w-full mb-[20px]">
          Enter the email address to recover the password, enter a secondary email if access to the
          primary one is lost
        </p>
        <form onSubmit={handleSubmit} className="w-full">
          <Controller
            name="email"
            control={control}
            rules={{ required: "Email is required" }}
            render={({ field }) => (
              <>
                <TextField
                  icon={<EmailIcon />}
                  value={field.value}
                  label="Email Address"
                  placeholder="Email Address"
                  type="text"
                  onChange={field.onChange}
                />
                {errors.email && <p className="text-[red] text-[13px]">{errors?.email?.message}</p>}
                {customErrorMessage && (
                  <p className="text-[red] text-[13px]">{customErrorMessage}</p>
                )}
              </>
            )}
          />

          <div className="w-full mb-[60px] mt-[40px]">
            <Button type="submit" text="Reset password" onClick={() => {}} />
          </div>
        </form>
      </div>
    </AuthWrapper>
  );
};

export default RecoveryPassword;
