import { useForm } from "react-hook-form";
import { useCreateLenderUserMutation } from "../services/rtkQuery/employee";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { yupResolver } from "@hookform/resolvers/yup";

import { getUserSchema } from "../utill/validation/validationSchemas";

const lenderUserSchema = getUserSchema();

export interface LendRequest {
  fullName: string;
  email: string;
  password: string;
  role: { value: string; label: string };
}
export const useLendRegister = (onSuccess: () => void) => {
  const [createUser, { isSuccess }] = useCreateLenderUserMutation();

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      fullName: "",
      email: "",
      password: "",
      role: { value: "", label: "" },
    },
    resolver: yupResolver(lenderUserSchema),
  });

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
      toast.success("New carewolf was created");
      reset();
    }
  }, [isSuccess]);

  const onSubmit = (data: LendRequest) => {
    const newData = { ...data, role: data.role.value };
    createUser(newData);
  };

  return {
    handleSubmit: handleSubmit(onSubmit),
    errors,
    control,
    reset,
  };
};
