import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import {
  useCreateBorrowerWithOrgIdMutation,
  useGetBorrowerUserQuery,
} from "../services/rtkQuery/borrower";
import { useAppSelector } from "../store/hooks";
import { selectBorrowerUser } from "../store/modules/borrower/selectors";

import { getOptions } from "../utill/helpers";
import { Option } from "../view/ui/common/SelectBox";

const assignedBorrowerUserSchema = Yup.object().shape(
  {
    fullName: Yup.string()
      .required("Full Name is required")
      .when("borrowerId", {
        is: (borrowerId: Option) => {
          return borrowerId?.value;
        },
        then: (schema) => Yup.string(),
      }),
    email: Yup.string()
      .required("Email is required")
      .when("borrowerId", {
        is: (borrowerId: Option) => {
          return borrowerId?.value;
        },
        then: (schema) => Yup.string(),
      }),

    borrowerId: Yup.object()
      .shape({
        value: Yup.string().required("School partner is required"),
        label: Yup.string().required("School partner is required"),
      })
      .when(["fullName", "email"], {
        is: (fullName: string, email: string) => {
          return fullName || email;
        },
        then: (schema) =>
          Yup.object().shape({
            value: Yup.string(),
            label: Yup.string(),
          }),
      }),
  },
  [
    ["fullName", "borrowerId"],
    ["borrowerId", "email"],
  ]
);

export const useAssignBorrowerToOrganization = (organizationId: string, onSuccess: () => void) => {
  useGetBorrowerUserQuery({ getAll: true });

  const borrowerUsers = useAppSelector(selectBorrowerUser);

  const [createBorrowerWithOrgId, { isSuccess }] = useCreateBorrowerWithOrgIdMutation();

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      borrowerId: { value: "", label: "" },
      fullName: "",
      email: "",
    },
    resolver: yupResolver(assignedBorrowerUserSchema),
  });

  const deleteAssigned = () => {
    reset({ borrowerId: { value: "", label: "" } });
  };
  const borrowersOptions = useMemo(() => {
    return getOptions(borrowerUsers?.rows, "fullName", "id");
  }, [borrowerUsers]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Partner contact was assigned successfully");
      onSuccess();
    }
  }, [isSuccess]);

  const onSubmit = (data: any) => {
    const payload: {
      fullName?: "";
      email?: "";
      organizationId: string;
      role: string;
      userId?: string;
    } = { organizationId, role: "borrower" };

    if (data.fullName && data.email) {
      payload.fullName = data.fullName;
      payload.email = data.email;
    } else {
      payload.userId = data.borrowerId.value;
    }
    createBorrowerWithOrgId(payload);
  };

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    borrowersOptions,
    watch,
    deleteAssigned,
    errors,
  };
};
