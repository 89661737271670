import SortIcon from "../../../assets/icons/SortIcon";

export const EmployeeDetailsTableHead = () => {
  return (
    <thead className="flex w-full justify-between gap-[10px]">
      <th className="flex items-center gap-1 w-[25%] py-3">
        <p>Assigned School Partners</p>
        <SortIcon />
      </th>
      <th className="flex items-center gap-1 w-[25%] py-3">
        <p>Assigned Date</p>
        <SortIcon />
      </th>
      <th className="flex items-center gap-1 w-[25%] py-3">
        <p>Date of Last Login</p>
        <SortIcon />
      </th>
      <th className="flex justify-end gap-1 w-[25%] py-3">
        <p>Action</p>
      </th>
    </thead>
  );
};
