import React, { FC } from "react";
import { IBorrowerUser } from "../../../../store/modules/borrower/types";

interface BorrowerTableProps {
  HeaderComponent: React.ElementType;
  BodyComponent: React.ElementType;
  borrowerList?: IBorrowerUser[];
}

export const BorrowerTable: FC<BorrowerTableProps> = ({
  HeaderComponent,
  BodyComponent,
  borrowerList,
}) => {
  return (
    <table className="flex w-full flex-wrap">
      <HeaderComponent />
      <BodyComponent borrowerList={borrowerList} />
    </table>
  );
};
