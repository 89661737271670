import { CreatePassword } from "../../../hooks/useCreatePassword";
import { rtkQuery } from "../../rtkQuery";
import { tags } from "../cache";
import {
  GetOrganizationResponse,
  GetOrganizationRequestParams,
  CreateOrganizationRequestBody,
  GetOrganizationByIdRequestParams,
  GetOrganizationByIdResponse,
} from "./types";

export const organizationApi = rtkQuery.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getOrganizations: builder.query<GetOrganizationResponse, GetOrganizationRequestParams>({
      query: ({ limit, page, assigned_user_id, fiscalYearEnd, name, borrower_userId, getAll }) => {
        return {
          url: "/organizations/users",
          params: {
            limit,
            page,
            assigned_user_id,
            fiscalYearEnd,
            name,
            borrower_userId,
            getAll,
          },
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: [tags.ORGANIZATION],
    }),
    unassignUser: builder.mutation<any, any>({
      query: (organizationId: number) => {
        return {
          url: `/users/user/unassign/${organizationId}`,
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: [tags.ORGANIZATION],
    }),
    assignUser: builder.mutation<any, any>({
      query: ({ userId, organizationId }: { userId: string; organizationId: string }) => {
        return {
          url: `/users/user/assign/${userId}/${organizationId}`,
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: [tags.ORGANIZATION],
    }),
    createOrganizations: builder.mutation<any, CreateOrganizationRequestBody>({
      query: (data) => {
        return {
          url: "/organizations",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };
      },
      invalidatesTags: [tags.ORGANIZATION],
    }),
    getOrganizationsById: builder.query<
      GetOrganizationByIdResponse,
      GetOrganizationByIdRequestParams
    >({
      query: ({ id }) => {
        return {
          url: `/organizations/organization/${id}`,
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: [tags.ORGANIZATION_ID],
    }),
    deleteOrganization: builder.mutation<any, any>({
      query: (id) => {
        return {
          url: `/organizations/organization/${id}`,
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
    }),
    editOrganization: builder.mutation<any, any>({
      query: ({ id, data }) => {
        return {
          url: `/organizations/organization/${id}`,
          body: JSON.stringify(data),
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: [tags.ORGANIZATION_ID, tags.ORGANIZATION],
    }),
  }),
});

export const {
  useGetOrganizationsQuery,
  useLazyGetOrganizationsQuery,
  useUnassignUserMutation,
  useAssignUserMutation,
  useCreateOrganizationsMutation,
  useGetOrganizationsByIdQuery,
  useLazyGetOrganizationsByIdQuery,
  useDeleteOrganizationMutation,
  useEditOrganizationMutation,
} = organizationApi;
