import React, { FC, useMemo, useState } from "react";
import { SearchInput } from "../../../components/SearchInput";
import { Option, SelectBox } from "../../../ui/common/SelectBox";
import {
  useGetCategoriesQuery,
  useGetDocumentsQuery,
} from "../../../../services/rtkQuery/documents";
import { useAppSelector } from "../../../../store/hooks";
import { selectCategories, selectDocuments } from "../../../../store/modules/documents/selectors";
import { getOptions } from "../../../../utill/helpers";
import { selectOrganizationUsers } from "../../../../store/modules/organization/selectors";
import { lenderUserStatus } from "../../../../utill/contants";
import { useGetOrganizationsQuery } from "../../../../services/rtkQuery/organization";

interface DocumentsFiltersProps {
  name: string;
  setName: (name: string) => void;
  selectedCategory: Option | Option[];
  selectedOrganization: Option | Option[];
  onChangeSelectedValue: (value: Option | Option[]) => void;
  onChangeSelectedOrganization: (value: Option | Option[]) => void;
  onChangeStatus: (value: Option | Option[]) => void;
  status: Option | any;
}

export const DocumentsFilters: FC<DocumentsFiltersProps> = ({
  name,
  setName,
  selectedCategory,
  selectedOrganization,
  onChangeSelectedValue,
  onChangeSelectedOrganization,
  onChangeStatus,
  status,
}) => {
  useGetCategoriesQuery();
  useGetOrganizationsQuery({ getAll: true });
  const categories = useAppSelector(selectCategories);
  const organizationList = useAppSelector(selectOrganizationUsers);

  const organizationOptions = useMemo(() => {
    if (organizationList && organizationList.rows) {
      return getOptions(organizationList?.rows, "name", "id");
    }
    return [];
  }, [organizationList?.rows]);

  const handleChange = (value: string | number) => {
    setName(value.toString());
  };

  const categoriesOptions = useMemo(() => {
    if (categories && categories.length) {
      return getOptions(categories, "name", "id");
    }
    return [];
  }, [categories]);

  return (
    <div>
      <div className="flex justify-between items-center gap-[16px]">
        <div className="w-[40%]">
          <SearchInput
            value={name}
            placeholder="Search Documents"
            type="text"
            onChange={handleChange}
            icon={false}
          />
        </div>
        <div className="w-[60%] flex justify-between gap-[16px]">
          <SelectBox
            value={selectedCategory}
            options={[{ value: "", label: "All Categories" }, ...categoriesOptions]}
            onChange={onChangeSelectedValue}
            placeholder="All Categories"
            multiple={false}
          />
          <SelectBox
            value={selectedOrganization}
            options={[{ value: "", label: "All School Partners" }, ...organizationOptions]}
            onChange={onChangeSelectedOrganization}
            placeholder="All School Partners"
            multiple={false}
          />
          <SelectBox
            value={status}
            options={lenderUserStatus}
            onChange={onChangeStatus}
            placeholder="All statuses"
            searchEnabled={false}
            multiple={false}
          />
        </div>
      </div>
    </div>
  );
};
