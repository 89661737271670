import React, { ChangeEvent, FC, ReactNode } from "react";

interface Props {
  label?: string;
  value?: string;
  onChange: (value: string | number) => void;
  placeholder?: string;
  icon?: ReactNode;
  type: string;
  onBlur?: () => void;
  className?: string;
  readOnly?: boolean;
}
export const TextField: FC<Props> = ({
  label,
  value,
  onChange,
  placeholder,
  icon,
  type,
  onBlur,
  className,
  readOnly,
}) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };
  return (
    <div className={"w-full flex justify-start flex-col mb-[10px] " + className}>
      <label className="text-gray-600 font-medium text-[15px] text-left mb-[8px]">{label}</label>
      <div className="relative">
        <input
          className={`w-full border-[1px] border-[lightgray]  rounded-[12px] px-3 py-2 focus:outline-none ${
            readOnly ? "" : "focus:border-[#4D5187] focus:border-2"
          } `}
          type={type}
          value={value}
          onChange={handleInputChange}
          placeholder={placeholder}
          readOnly={readOnly}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-600">
          {icon}
        </div>
      </div>
    </div>
  );
};
