import { Navigate, Route, Routes } from "react-router";
import Login from "./view/pages/Auth/Login";
import { useAppSelector } from "./store/hooks";
import { selectIsAuth, selectUser } from "./store/modules/user/selectors";
import React from "react";
import RecoveryPassword from "./view/pages/Auth/RecoveryPassword";
import CreatePassword from "./view/pages/Auth/CreatePassword";
import SuccessPassword from "./view/pages/Auth/SuccessPassword";
import Employee from "./view/pages/Employee";
import EmployeeDetails from "./view/pages/EmployeeDetails";
import Settings from "./view/pages/Settings";
import Organizations from "./view/pages/Organization";
import OrganizationDetails from "./view/pages/OrganizationDetails";
import Documents from "./view/pages/Documents";
import Borrower from "./view/pages/Borrower";
import BorrowerDocuments from "./view/pages/BorrowerDocuments";
import Help from "./view/pages/Help";

const RootRouter = () => {
  const isAuth = useAppSelector(selectIsAuth);
  const user = useAppSelector(selectUser);
  const isBorrowerAdmin = user?.type === "borrower_admin";

  return isAuth && user && !user.isNew ? (
    <Routes>
      {user.type === "lenderUser" ? (
        <Route path="*" element={<Navigate to="/organization" />} />
      ) : user.type === "borrower_user" ? (
        <Route path="*" element={<Navigate to="/documents" />} />
      ) : isBorrowerAdmin ? (
        <Route path="*" element={<Navigate to="/borrower" />} />
      ) : (
        <Route path="*" element={<Navigate to="/employee" />} />
      )}
      <Route path="/employee" element={<Employee />} />
      <Route path="/employee/:id" element={<EmployeeDetails />} />
      <Route path="/organization" element={<Organizations />} />
      <Route path="/organization/:id" element={<OrganizationDetails />} />
      <Route path="/settings" element={<Settings />} />
      <Route
        path="/documents"
        element={
          user.type === "borrower_user" || isBorrowerAdmin ? <BorrowerDocuments /> : <Documents />
        }
      />
      <Route path="/borrower" element={<Borrower />} />
      <Route path="/help" element={<Help />} />
    </Routes>
  ) : (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/recovery-password" element={<RecoveryPassword />} />
      <Route path="/create-password" element={<CreatePassword />} />
      <Route path="/success-password" element={<SuccessPassword />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default RootRouter;
