import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { borrowerApi } from "../../../services/rtkQuery/borrower";
import { BorrowerState, GetBorrowerResponse } from "./types";

export const initialState: BorrowerState = {
  borrowerUsers: null,
};

const borrowerSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addMatcher(
      borrowerApi.endpoints.getBorrowerUser.matchFulfilled,
      (state, { payload }: PayloadAction<GetBorrowerResponse>) => {
        state.borrowerUsers = payload && payload.data.borrowerUsers;
      }
    );
  },
});

export const borrowerReducer = borrowerSlice.reducer;
export const {} = borrowerSlice.actions;
