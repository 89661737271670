import SortIcon from "../../../assets/icons/SortIcon";

export const BorrowerDocumentsListTableHeader = () => {
  return (
    <thead className="w-full">
      <tr className="flex w-full justify-between gap-[10px]">
        <th className="flex items-center gap-1 w-[25%] py-3">
          <p className="text-center text-[15px]">Document Title</p>
          <SortIcon />
        </th>
        <th className="flex justify-start items-center gap-1 w-[25%] py-3">
          <p className="text-center text-[15px]">Category</p>
        </th>
        <th className="flex justify-start items-center gap-1 w-[25%] py-3">
          <p className="text-center text-[15px]">Date Upload</p>
          <SortIcon />
        </th>
        <th className="flex justify-end items-center w-[25%] py-3">
          <p className="text-right text-[15px]">Action</p>
        </th>
      </tr>
    </thead>
  );
};
