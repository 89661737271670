import { createSelector, Selector } from "@reduxjs/toolkit";

import { RootState } from "../../types";
import { BorrowerState } from "./types";

const selectSelf: Selector<RootState, BorrowerState> = (state) =>
  state.borrower;

export const selectBorrowerUser = createSelector(selectSelf, (state) => {
  return state.borrowerUsers;
});
