import { FC, useState } from "react";
import { Modal } from "../../../components/Modal";
import CropIcon from "../../../assets/icons/CropIcon";
import { useAssignBorrowerToOrganization } from "../../../../hooks/useAssignBorrower";
import { Controller } from "react-hook-form";
import { TextField } from "../../../ui/common/TextField";
import UserIcon from "../../../assets/icons/UserIcon";
import { SelectBox } from "../../../ui/common/SelectBox";
import EmailIcon from "../../../assets/icons/EmailIcon";
import { Button } from "../../../ui/common/Button";
import { BorrowerContacts } from "./BorrowerContacts/BorrowerContacts";

interface AssignBorrowerModalProps {
  isOpenModal: boolean;
  closeModal: () => void;
  organizationId: string;
}

export const AssignBorrowerModal: FC<AssignBorrowerModalProps> = ({
  isOpenModal,
  closeModal,
  organizationId,
}) => {
  const [isVisibleContactsSelectBox, setIsVisibleContactsSelectBox] = useState(false);

  const { control, handleSubmit, borrowersOptions, watch, deleteAssigned, errors } =
    useAssignBorrowerToOrganization(organizationId, closeModal);
  return (
    <Modal isOpened={isOpenModal} onClose={closeModal}>
      <div className="relative bg-[#FFF] p-[48px] rounded-[32px] w-[520px]">
        <div className=" flex justify-end">
          <div className="cursor-pointer" onClick={closeModal}>
            <CropIcon />
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <p className="text-[28px] font-medium text-[#474747]">Assign School Partner</p>
          </div>
          <div className="flex justify-between mb-[16px] mt-[24px]">
            <p className="text-[20px] text-[#474747] font-medium">School Partner</p>
            <button
              type="button"
              onClick={() => {
                setIsVisibleContactsSelectBox(!isVisibleContactsSelectBox);
              }}
            >
              <p className="text-[#4D5187]">
                {isVisibleContactsSelectBox ? "Add new" : "Add an existing"}
              </p>
            </button>
          </div>
          {isVisibleContactsSelectBox ? (
            <>
              <div className="mb-3">
                {watch().borrowerId.value && (
                  <BorrowerContacts
                    borrower={watch().borrowerId}
                    deleteBorrower={deleteAssigned}
                    icon={<CropIcon />}
                  />
                )}
              </div>
              <div>
                <Controller
                  control={control}
                  name="borrowerId"
                  render={({ field }) => (
                    <>
                      <SelectBox
                        value={field.value}
                        onChange={field.onChange}
                        options={borrowersOptions}
                        label=""
                        placeholder="Select School Partner"
                        placement="bottom"
                        multiple={false}
                      />
                      {errors?.borrowerId?.value && (
                        <p className="text-[red] text-[13px]">{errors?.borrowerId.value.message}</p>
                      )}
                    </>
                  )}
                />
              </div>
            </>
          ) : (
            <div>
              <Controller
                name="fullName"
                control={control}
                rules={{}}
                render={({ field }) => (
                  <>
                    <TextField
                      icon={<UserIcon />}
                      value={field.value}
                      label="Full Name*"
                      placeholder="Pristia Candra"
                      type="text"
                      className="mb-[24px]"
                      onChange={field.onChange}
                    />
                    {errors?.fullName && (
                      <p className="text-[red] text-[13px]">{errors?.fullName.message}</p>
                    )}
                  </>
                )}
              />
              <Controller
                name="email"
                control={control}
                rules={{}}
                render={({ field }) => (
                  <>
                    <TextField
                      icon={<EmailIcon />}
                      value={field.value}
                      label="Email Address*"
                      placeholder="Email Address"
                      type="text"
                      onChange={field.onChange}
                    />
                    {errors?.email && (
                      <p className="text-[red] text-[13px]">{errors?.email.message}</p>
                    )}
                  </>
                )}
              />
            </div>
          )}
          <div className="flex justify-end mt-[24px]">
            <div className="w-[100%]">
              <Button
                text={`Save ${isVisibleContactsSelectBox ? "" : "and Send Access"} `}
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};
