import { rtkQuery } from "../../rtkQuery";
import { providesList, tags } from "../cache";
import { CreateLenderWithOrgData } from "./types";

export const employeeApi: any = rtkQuery.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getLenderUsers: builder.query<any, any>({
      query: ({ search, role, page, limit = 8, status, getAll }) => {
        const params: any = {};
        if (search) {
          params.search = search;
        }
        if (role) {
          params.role = role;
        }
        if (page) {
          params.page = page;
        }
        if (status) {
          params.status = status;
        }
        if (getAll) {
          params.getAll = getAll;
        }
        params.limit = limit;
        return {
          url: "/users/lender-users",
          method: "GET",
          params,
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: [tags.EMPLOYEE],
    }),
    createLenderUser: builder.mutation<any, any>({
      query: (data) => {
        return {
          url: "/users/lendregister",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };
      },
      invalidatesTags: [tags.EMPLOYEE],
    }),
    createLenderUserWithOrgId: builder.mutation<any, CreateLenderWithOrgData>({
      query: (data) => {
        return {
          url: "/users/lender-orgnization",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };
      },
      invalidatesTags: [tags.ORGANIZATION, tags.ORGANIZATION_ID],
    }),
    deleteLenderUser: builder.mutation<any, any>({
      query: (userId: number) => {
        return {
          url: `/users/user/${userId}`,
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: [tags.EMPLOYEE],
    }),
    activeInactiveLenderUser: builder.mutation<any, any>({
      query: ({ userId, status }: { userId: number; status: string }) => {
        return {
          url: `/users/user/status/${userId}`,
          method: "PUT",
          body: JSON.stringify({ status }),
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: [tags.EMPLOYEE, tags.BORROWER],
    }),
    updateLenderUser: builder.mutation<any, any>({
      query: ({
        userId,
        password,
        email,
        fullName,
        role,
      }: {
        userId: number;
        fullName: string;
        email: string;
        password?: string;
        role?: string;
      }) => {
        const updatedLenderInfo: {
          email: string;
          fullName: string;
          password?: string;
          role?: string;
        } = {
          email,
          fullName,
          role,
        };
        if (password) {
          updatedLenderInfo.password = password;
        }
        return {
          url: `/users/lender-user/${userId}`,
          method: "PUT",
          body: JSON.stringify(updatedLenderInfo),
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: [tags.EMPLOYEE],
    }),
    getLenderUserById: builder.query<any, any>({
      query: ({ id }) => {
        return {
          url: `/users/user/${id}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
    }),
    getOrganizationsUserById: builder.query<any, any>({
      query: ({ id, name, page, limit, organizationId, createdAt }) => {
        const params: any = {};
        if (name) {
          params.name = name;
        }
        if (page) {
          params.page = page;
        }
        if (limit) {
          params.limit = limit;
        }
        if (organizationId) {
          params.organizationId = organizationId;
        }
        if (createdAt) {
          params.createdAt = createdAt;
        }

        return {
          url: `/organizations/users/${id}`,
          method: "GET",
          params,
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: [tags.ORGANIZATION],
    }),
  }),
});

export const {
  useLazyGetLenderUsersQuery,
  useGetLenderUsersQuery,
  useCreateLenderUserMutation,
  useCreateLenderUserWithOrgIdMutation,
  useUpdateLenderUserMutation,
  useDeleteLenderUserMutation,
  useActiveInactiveLenderUserMutation,
  useGetLenderUserByIdQuery,
  useLazyGetLenderUserByIdQuery,
  useGetOrganizationsUserByIdQuery,
  useLazyGetOrganizationsUserByIdQuery,
} = employeeApi;
