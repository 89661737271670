import { useForm } from "react-hook-form";
import { useLoginMutation } from "../services/rtkQuery/user";
import { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getEmailSchema } from "../utill/validation/validationSchemas";

const loginSchema = Yup.object().shape({
  email: getEmailSchema(),

  password: Yup.string()
    .required("Please enter a valid password")
    .min(6, "Please enter at least 6 characters"),
});

export interface LoginRequest {
  email: string;
  password: string;
}
export const useLogin = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [login, { isLoading, error }] = useLoginMutation();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(loginSchema),
  });

  useEffect(() => {
    if (error) {
      setErrorMessage("Please enter a valid Email and Password combination");
    } else {
      setErrorMessage("");
    }
  }, [error]);

  useEffect(() => {
    if (error) {
      setErrorMessage("");
    }
  }, [errors.email, errors.password]);

  const onSubmit = (data: LoginRequest) => {
    login(data);
  };

  return {
    handleSubmit: handleSubmit(onSubmit),
    errors,
    control,
    error: errorMessage,
  };
};
