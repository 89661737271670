import React from "react";
import { Container } from "../../components/Container";
import VideoList from "./components/VideoList";
import QuestionsAccordion from "./components/QuestionsAccordion";
import { FAQ_DATA, USEJETFORM_DATA } from "../../../utill/contants";

const Help = () => {
  return (
    <div className="h-full">
      <Container fullHeight={false}>
        <div className="h-full">
          <h1 className="font-bold text-2xl">Help</h1>
          <h2 className="mb-4 text-sm font-medium">Training Materials</h2>
          <div className="mb-4">
            <p className="mb-4 font-bold text text-xl">Video Materials</p>
            <VideoList />
          </div>
          <div>
            <p className="mb-4 font-bold text text-xl">FAQ</p>
            <QuestionsAccordion data={FAQ_DATA} />
          </div>
          <div className="mt-4">
            <p className="mb-4 font-bold text text-xl">USING JOTFORM</p>
            <QuestionsAccordion data={USEJETFORM_DATA} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Help;
