import React, { FC, ReactComponentElement } from "react";
import { Organization } from "../../../store/modules/employee/types";

interface OrganizationTableProps {
  HeaderComponent: React.ElementType;
  BodyComponent: React.ElementType;

  organizationList?: Organization[];
}
const OrganizationTable: FC<OrganizationTableProps> = ({
  HeaderComponent,
  BodyComponent,
  organizationList,
}) => {
  return (
    <table className="flex w-full flex-wrap">
      <HeaderComponent />
      <BodyComponent organizationList={organizationList} />
    </table>
  );
};

export default OrganizationTable;
