import React from "react";

const EyeIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.9999 6.67581C6.95604 6.67581 6.11323 7.51862 6.11323 8.56248C6.11323 9.60634 6.95604 10.4491 7.9999 10.4491C9.04375 10.4491 9.88656 9.60634 9.88656 8.56248C9.88656 7.51862 9.04375 6.67581 7.9999 6.67581ZM5.11323 8.56248C5.11323 6.96634 6.40375 5.67581 7.9999 5.67581C9.59604 5.67581 10.8866 6.96634 10.8866 8.56248C10.8866 10.1586 9.59604 11.4491 7.9999 11.4491C6.40375 11.4491 5.11323 10.1586 5.11323 8.56248Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.50489 6.56046C3.09884 4.05488 5.43458 2.54248 7.9999 2.54248C10.5653 2.54248 12.9012 4.05501 14.4951 6.56078C14.8584 7.13034 15.0232 7.85992 15.0232 8.55915C15.0232 9.25838 14.8584 9.98795 14.4951 10.5575C12.9012 13.0633 10.5653 14.5758 7.9999 14.5758C5.43446 14.5758 3.09863 13.0633 1.50468 10.5575C1.14137 9.98795 0.976562 9.25838 0.976562 8.55915C0.976562 7.85978 1.14143 7.13006 1.50489 6.56046ZM7.9999 3.54248C5.85866 3.54248 3.80783 4.80328 2.34844 7.09751L2.34802 7.09816C2.11163 7.46851 1.97656 7.99864 1.97656 8.55915C1.97656 9.11965 2.11163 9.64979 2.34802 10.0201L2.34844 10.0208C3.80783 12.315 5.85866 13.5758 7.9999 13.5758C10.1411 13.5758 12.192 12.315 13.6514 10.0208L13.6518 10.0201C13.8882 9.64979 14.0232 9.11965 14.0232 8.55915C14.0232 7.99864 13.8882 7.46851 13.6518 7.09816L13.6514 7.09751C12.192 4.80328 10.1411 3.54248 7.9999 3.54248Z"
        fill="white"
      />
    </svg>
  );
};

export default EyeIcon;
