import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { useAppSelector } from "../store/hooks";
import { getOptions } from "../utill/helpers";
import {
  useCreateLenderUserWithOrgIdMutation,
  useGetLenderUsersQuery,
} from "../services/rtkQuery/employee";
import { selectLenderUsers } from "../store/modules/employee/selectors";
import { getGeneralSelectBoxSchema } from "../utill/validation/validationSchemas";

const assignedLenderUserSchema = Yup.object().shape({
  lenderId: getGeneralSelectBoxSchema("Carewolf is required"),
});

export const useAssignLenderToOrganization = (organizationId: string, onSuccess: () => void) => {
  useGetLenderUsersQuery({ getAll: true });

  const { rows }: any = useAppSelector(selectLenderUsers);

  const [createLenderUserWithOrgId, { isSuccess }] = useCreateLenderUserWithOrgIdMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      lenderId: { value: "", label: "" },
    },
    resolver: yupResolver(assignedLenderUserSchema),
  });

  const lenderOptions = useMemo(() => {
    return getOptions(rows, "fullName", "id");
  }, [rows]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Carewolf was assigned successfully");
      onSuccess();
    }
  }, [isSuccess]);

  const onSubmit = (data: any) => {
    const payload = { organizationId, role: "user", userId: data.lenderId.value };

    createLenderUserWithOrgId(payload);
  };

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    lenderOptions,
    errors,
  };
};
