import React, { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";

import EyeIcon from "../../../assets/icons/EyeIcon";
import EditIcon from "../../../assets/icons/EditIcon";
import TrashIcon from "../../../assets/icons/TrashIcon";
import { Option, SelectBox } from "../../../ui/common/SelectBox";
import { userStatusOptions } from "../../../../utill/contants";
import { ILenderUser } from "../../../../models/employee";
import { useActiveInactiveLenderUserMutation } from "../../../../services/rtkQuery/employee";
import { CreateEmployeeModal, userRoles } from "./CreateEmployeeModal";
import { useEditLender } from "../../../../hooks/useEditLender";

interface EmployeeTableRowProps {
  row: ILenderUser;
  deleteUser: (userId: number) => void;
  goToDetailsScreen: (id: number) => void;
}
export const EmployeeTableRow: FC<EmployeeTableRowProps> = ({
  row,
  deleteUser,
  goToDetailsScreen,
}) => {
  const [userStatus, setUserStatus] = useState<Option | undefined>(undefined);

  useEffect(() => {
    setUserStatus(userStatusOptions.find((status) => status.value === row.status));
  }, [row.status]);

  const [isOpenEditLenderModal, setIsOpenEditLenderModal] = useState(false);

  const openEditModal = () => {
    setIsOpenEditLenderModal(true);
  };
  const closeEditModal = () => {
    setIsOpenEditLenderModal(false);
  };

  const { control, handleSubmit, errors } = useEditLender(row.id, closeEditModal);

  const [activateInactivate, { isSuccess: isChangingStatusSuccess }] =
    useActiveInactiveLenderUserMutation();

  const onDeleteUser = () => {
    deleteUser(row.id);
  };

  const openDetailsScreen = () => {
    goToDetailsScreen(row.id);
  };

  useEffect(() => {
    if (isChangingStatusSuccess) {
      toast.success("Status was changed successfully!");
    }
  }, [isChangingStatusSuccess]);

  const setUserStatusHandler = (status: Option | Option[]) => {
    if (!Array.isArray(status)) {
      activateInactivate({ userId: row.id, status: status.value });
      setUserStatus(status);
    }
  };

  const userRole = userRoles?.find((item) => item.value === row?.role);

  return (
    <tr className="flex w-full justify-between gap-[10px] my-3">
      <td className="flex items-center gap-1 w-[23%]">
        <div className="flex flex-col items-start ml-2">
          <p className="text-[15px] font-bold text-[#474747]">{row.fullName}</p>
          <p className="text-[13px] text-[#717984] text-left">{row.email}</p>
        </div>
      </td>
      <td className="flex items-center justify-left gap-1 w-[11%]">
        <p className="text-left  text-[15px] text-[#474747]">{userRole?.label}</p>
      </td>

      <td className="flex items-center justify-left gap-1 w-[16%]">
        <p className="text-center  text-[15px] text-[#474747]">
          {moment(row.createdAt).format("LL")}
        </p>
      </td>
      <td className="flex items-center justify-left gap-1 w-[16%]">
        <p className="text-center  text-[15px] text-[#474747]">
          {moment(row.updatedAt).format("LL")}
        </p>
      </td>
      <td className="flex items-center justify-left gap-1 w-[19%]">
        <p className="text-center text-[15px] w-full text-[#474747]">
          <SelectBox
            value={userStatus}
            onChange={setUserStatusHandler}
            options={userStatusOptions}
            placeholder="Status"
            multiple={false}
            searchEnabled={false}
          />
        </p>
      </td>
      <td className="flex items-center justify-end gap-1 w-[13%]">
        <button
          onClick={openDetailsScreen}
          className="flex w-[30px] h-[30px] p-1 justify-center items-center gap-8 bg-[#EE8C58] rounded-lg"
        >
          <EyeIcon />
        </button>
        <button
          onClick={openEditModal}
          className="flex  w-[30px] h-[30px] p-1 justify-center items-center gap-8 bg-[#434784] rounded-lg"
        >
          <EditIcon />
        </button>
        <div className="group relative w-max">
          <button
            onClick={onDeleteUser}
            disabled={userStatus?.value === "inactive"}
            className={`flex w-[30px] h-[30px] p-1 justify-center items-center gap-8 rounded-lg ${
              userStatus?.value === "inactive" ? "bg-[#9c9595]" : "bg-[#E04D4D]"
            }`}
          >
            <TrashIcon />
          </button>
          {userStatus?.value === "inactive" ? (
            <div className="text-[10px] pointer-events-none rounded-full  p-2  bg-[#f7e0e0] absolute -top-7 -left-[180px] w-max opacity-0 transition-opacity group-hover:opacity-100">
              Deletion unavailable for an Inactive account.
            </div>
          ) : (
            ""
          )}
        </div>
      </td>
      {isOpenEditLenderModal && (
        <CreateEmployeeModal
          isOpenModal={isOpenEditLenderModal}
          closeModal={closeEditModal}
          control={control}
          handleSubmit={handleSubmit}
          action={"edit"}
          errors={errors}
        />
      )}
    </tr>
  );
};
