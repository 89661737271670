import { createSelector, Selector } from "@reduxjs/toolkit";

import { RootState } from "../../types";

import { EmployeeState } from "./types";

const selectSelf: Selector<RootState, EmployeeState> = (state) =>
  state.employee;

export const selectLenderUsers = createSelector(selectSelf, (state) => {
  return state.lenderUsers;
});

export const selecteUserById = createSelector(selectSelf, (state) => {
  return state.lenderUser;
});

export const selectOrganizationList = createSelector(selectSelf, (state) => {
  return state.organizationList;
});
