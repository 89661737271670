import React from "react";

const CalendarIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M13.3334 1.66663V4.99996"
        stroke="#4D5187"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66667 1.66663V4.99996"
        stroke="#4D5187"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 7.50004H17.5"
        stroke="#4D5187"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8333 3.33337H4.16667C3.24583 3.33337 2.5 4.07921 2.5 5.00004V15.8334C2.5 16.7542 3.24583 17.5 4.16667 17.5H15.8333C16.7542 17.5 17.5 16.7542 17.5 15.8334V5.00004C17.5 4.07921 16.7542 3.33337 15.8333 3.33337Z"
        stroke="#4D5187"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.84406 10.6075C5.72906 10.6075 5.63573 10.7008 5.63656 10.8158C5.63656 10.9308 5.72989 11.0242 5.84489 11.0242C5.95989 11.0242 6.05323 10.9308 6.05323 10.8158C6.05323 10.7008 5.95989 10.6075 5.84406 10.6075"
        stroke="#4D5187"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0108 10.6075C9.89581 10.6075 9.80247 10.7008 9.80331 10.8158C9.80331 10.9308 9.89664 11.0242 10.0116 11.0242C10.1266 11.0242 10.22 10.9308 10.22 10.8158C10.22 10.7008 10.1266 10.6075 10.0108 10.6075"
        stroke="#4D5187"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1776 10.6075C14.0626 10.6075 13.9692 10.7008 13.9701 10.8158C13.9701 10.9308 14.0634 11.0242 14.1784 11.0242C14.2934 11.0242 14.3867 10.9308 14.3867 10.8158C14.3867 10.7008 14.2934 10.6075 14.1776 10.6075"
        stroke="#4D5187"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.84406 13.9409C5.72906 13.9409 5.63573 14.0342 5.63656 14.1492C5.63656 14.2642 5.72989 14.3575 5.84489 14.3575C5.95989 14.3575 6.05323 14.2642 6.05323 14.1492C6.05323 14.0342 5.95989 13.9409 5.84406 13.9409"
        stroke="#4D5187"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0108 13.9409C9.89581 13.9409 9.80247 14.0342 9.80331 14.1492C9.80331 14.2642 9.89664 14.3575 10.0116 14.3575C10.1266 14.3575 10.22 14.2642 10.22 14.1492C10.22 14.0342 10.1266 13.9409 10.0108 13.9409"
        stroke="#4D5187"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalendarIcon;
