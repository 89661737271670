import React, { FC } from "react";
import { Document } from "../../../../store/modules/documents/types";
import { BorrowerTableRow } from "./BorrowerTableRow";
import { IBorrowerUser } from "../../../../store/modules/borrower/types";

interface BorrowerTableBodyProps {
  borrowerList: IBorrowerUser[];
}

export const BorrowerTableBody: FC<BorrowerTableBodyProps> = ({ borrowerList }) => {
  return (
    <tbody className="w-full gap-[10px] items-center text-center">
      {(borrowerList || []).map((borrowerUser: IBorrowerUser) => (
        <BorrowerTableRow borrowerUser={borrowerUser} />
      ))}
    </tbody>
  );
};
