import React, { ChangeEvent, FC, ReactNode } from "react";

interface Props {
  label?: string;
  value?: string;
  onChange: (value: string | number) => void;
  placeholder?: string;
  icon?: ReactNode;
  onBlur?: () => void;
}
export const TextArea: FC<Props> = ({
  label,
  value,
  onChange,
  placeholder,
  icon,
  onBlur,
}) => {
  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };
  return (
    <div className="w-full flex justify-start flex-col mb-[10px]">
      <label className="text-gray-600 font-medium text-[15px] text-left mb-[8px]">
        {label}
      </label>
      <div className="relative">
        <textarea
          className="w-full border-[1px] h-[250px] border-[lightgray] focus:border-2 px-3 py-2 focus:outline-none focus:border-[#4D5187]"
          value={value}
          onChange={handleInputChange}
          placeholder={placeholder}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-600">
          {icon}
        </div>
      </div>
    </div>
  );
};
