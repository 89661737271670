import React, { FC } from "react";

interface ButtonProps {
  className?: string;
  text: string;
  onClick?: () => void;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
}
export const Button: FC<ButtonProps> = ({
  text,
  onClick,
  type,
  className,
  disabled = false,
}) => {
  return (
    <button
      type={type}
      className={
        `w-full bg-orange-500 text-white py-2 rounded-md hover:bg-orange-600 text font-bold` +
        className
      }
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
};
