import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { Container } from "../../components/Container";
import { Button } from "../../ui/common/Button";
import { CreateEmployeeModal, roles } from "./components/CreateEmployeeModal";
import { SearchInput } from "../../components/SearchInput";
import Table from "../../components/Table";
import EmployeeTableHead from "./components/EmployeeTableHead";
import { EmployeeTableBody } from "./components/EmployeeTableBody";
import {
  useDeleteLenderUserMutation,
  useGetLenderUsersQuery,
  useLazyGetLenderUsersQuery,
} from "../../../services/rtkQuery/employee";
import { Option, SelectBox } from "../../ui/common/SelectBox";
import { lenderUserStatus, numberPageUser } from "../../../utill/contants";
import Pagination from "../../ui/common/Pagination";
import { useAppSelector } from "../../../store/hooks";
import { selectLenderUsers } from "../../../store/modules/employee/selectors";
import { useNavigate } from "react-router";
import { selectUser } from "../../../store/modules/user/selectors";
import { useLendRegister } from "../../../hooks/useLendRegister";
import { toast } from "react-toastify";

const Employee = () => {
  const [isOpenModal, setOpenModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [role, setRole] = useState<Option | undefined>({ value: "", label: "" });
  const [status, setStatus] = useState<Option | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(numberPageUser[1]);
  const { count }: any = useAppSelector(selectLenderUsers);
  const navigate = useNavigate();
  const [deleteUser, { isSuccess: isDeleteSuccessfully }] = useDeleteLenderUserMutation();
  const [getUsers] = useLazyGetLenderUsersQuery();

  useGetLenderUsersQuery();

  const onChange = (event: Option | Option[]) => {
    setRole(event as Option);
  };

  const onChangeStatus = (event: Option | Option[]) => {
    setStatus(event as Option);
  };

  const handleChange = (value: string | number) => {
    setSearchQuery(value.toString());
  };

  const openModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
    reset();
  };

  const { control, handleSubmit, errors, reset } = useLendRegister(closeModal);

  useEffect(() => {
    getUsers({
      search: searchQuery,
      role: role && role.value && role.value.length ? role.value : undefined,
      page: currentPage,
      limit: itemsPerPage.value,
      status: status && status.value && status.value.length ? status.value : undefined,
    });
  }, [searchQuery, role, currentPage, itemsPerPage, status]);

  useEffect(() => {
    if (isDeleteSuccessfully) {
      toast.success("Carewolf was deleted");
    }
  }, [isDeleteSuccessfully]);

  const goToDetailsScreen = (id: number) => {
    navigate(`/employee/${id}`);
  };
  return (
    <div>
      <Container>
        <div className="h-full">
          <div className="h-[90%]">
            <div className="flex justify-between items-center">
              <div>
                <p className="text-[#474747] text-[24px] font-bold ...">Carewolves List</p>
                <p className="text-[#717984] text-[14px]">Manage Carewolves</p>
              </div>

              <div className="w-[150px] text-[15.5px]">
                <Button type="button" text="+ New Carewolf" onClick={openModal} />
              </div>
            </div>
            <div className="flex flex-row items-center gap-[10px] justify-between">
              <div className="w-[60%]">
                <SearchInput
                  value={searchQuery}
                  placeholder="Search Carewolf"
                  type="text"
                  onChange={handleChange}
                  icon={false}
                />
              </div>
              <div className="w-[40%] flex justify-end space-x-4 items-center">
                <SelectBox
                  value={role}
                  onChange={onChange}
                  options={roles}
                  placeholder="All Roles"
                  multiple={false}
                  searchEnabled={false}
                />
                <SelectBox
                  value={status}
                  onChange={onChangeStatus}
                  options={lenderUserStatus}
                  placeholder="All Carewolf Statuses"
                  multiple={false}
                  searchEnabled={false}
                />
              </div>
            </div>
            <div className="h-[80%] overflow-y-auto">
              <Table
                HeaderComponent={EmployeeTableHead}
                BodyComponent={EmployeeTableBody}
                deleteUser={deleteUser}
                goToDetailsScreen={goToDetailsScreen}
              />
            </div>
          </div>
          <div className="flex justify-between items-center h-[10%]">
            <div className="w-[70%] justify-start">
              <Pagination
                itemsPerPage={+itemsPerPage.value}
                userCount={count}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
              />
            </div>
            <div className="w-[30%] flex items-center justify-end gap-[16px]">
              <p className="text-[13px] text-[#717984]">
                {`Showing 1 to ${itemsPerPage?.value} of ${count} entries`}
              </p>
              <div className="flex h-[32px] p-[10px] justify-center items-center gap-[10px]">
                <SelectBox
                  value={itemsPerPage}
                  onChange={(value: Option | Option[]) => setItemsPerPage(value as Option)}
                  options={numberPageUser}
                  placeholder="Show 8"
                  multiple={false}
                  searchEnabled={false}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
      {isOpenModal && (
        <CreateEmployeeModal
          isOpenModal={isOpenModal}
          closeModal={closeModal}
          action={"create"}
          control={control}
          handleSubmit={handleSubmit}
          errors={errors}
        />
      )}
    </div>
  );
};

export default Employee;
