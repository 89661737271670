import React from "react";

const PasswordIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0023 21.0039H6.99815C6.46745 21.004 5.95844 20.7932 5.58318 20.418C5.20792 20.0427 4.99717 19.5337 4.99731 19.003V12.0001C4.99717 11.4694 5.20792 10.9604 5.58318 10.5851C5.95844 10.2099 6.46745 9.99912 6.99815 9.99927H17.0023C17.533 9.99912 18.042 10.2099 18.4173 10.5851C18.7925 10.9604 19.0033 11.4694 19.0031 12.0001V19.003C19.0033 19.5337 18.7925 20.0427 18.4173 20.418C18.042 20.7932 17.533 21.004 17.0023 21.0039V21.0039Z"
        stroke="#4D5187"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0369 15.465C12.0517 15.4798 12.0561 15.5021 12.0481 15.5215C12.0401 15.5409 12.0212 15.5535 12.0002 15.5535C11.9792 15.5535 11.9603 15.5409 11.9523 15.5215C11.9443 15.5021 11.9487 15.4798 11.9635 15.465V15.465C11.9838 15.4448 12.0166 15.4448 12.0368 15.465"
        stroke="#4D5187"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4384 17.0022V14.001"
        stroke="#4D5187"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99854 9.99925V6.998V6.998C7.99863 4.78799 9.79018 2.99644 12.0002 2.99634V2.99634C14.2102 2.99644 16.0018 4.78799 16.0019 6.998V6.998V9.99925"
        stroke="#4D5187"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5984 15.465C8.61324 15.4798 8.61767 15.5021 8.60965 15.5215C8.60162 15.5409 8.58271 15.5535 8.56173 15.5535C8.54076 15.5535 8.52184 15.5409 8.51382 15.5215C8.50579 15.5021 8.51023 15.4798 8.52506 15.465V15.465C8.54531 15.4448 8.5781 15.4448 8.59835 15.465"
        stroke="#4D5187"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PasswordIcon;
