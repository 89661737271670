import React from "react";

const AvatarIcon = ({ width, height }: { width?: string; height?: string }) => {
  return (
    <img
      src={require("./avatar.png")}
      alt="Avatar Profile"
      width={width}
      height={height}
    />
  );
};

export default AvatarIcon;
