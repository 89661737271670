import React, { FC, useState } from "react";

interface Props {
  data: { question: string; answer: string; image?: string }[];
}

const QuestionsAccordion: FC<Props> = ({ data }) => {
  const [selectedQuestion, setSelectedQuestion] = useState<number | null>(null);

  const handleClick = (i: number) => {
    if (selectedQuestion === i) {
      setSelectedQuestion(null);
    } else {
      setSelectedQuestion(i);
    }
  };

  return (
    <ul className="flex flex-col gap-y-4">
      {data.map((item, index) => (
        <li
          key={index}
          className="flex flex-col gap-y-4 bg-[#faf8f8] rounded-xl p-2 text-lg"
        >
          <div
            className="flex items-center font-semibold h-[50px] cursor-pointer"
            onClick={() => handleClick(index)}
          >
            {item.question}
          </div>
          {selectedQuestion === index ? (
            <div className="pt-4 border-t-2 border-solid border-black text-base">
              <a
                target="_blank"
                href={
                  index === 0
                    ? "https://docs.google.com/document/d/13gLymhpJZMnTLRUYliS0hpxr7vEoOp44w_QYeVXBbuY/edit"
                    : "https://docs.google.com/document/d/1AwE0xALKqSFIg12gbuBt-5X1rnCOLt09r6mUB1T8I0s/edit"
                }
              >
                {item.answer}
              </a>
            </div>
          ) : (
            ""
          )}
        </li>
      ))}
    </ul>
  );
};

export default QuestionsAccordion;
