import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForgotPasswordMutation } from "../services/rtkQuery/user";
import { useEffect, useState } from "react";
import { getEmailSchema } from "../utill/validation/validationSchemas";

const recoveryPasswordSchema = Yup.object().shape({
  email: getEmailSchema(),
});
export interface RecoveryPassword {
  email: string;
}
export const useRecoveryPassword = (onSuccess: () => void) => {
  const [customErrorMessage, setCustomErrorMessage] = useState("");
  const [recovery, { isLoading, error, isSuccess }] = useForgotPasswordMutation();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(recoveryPasswordSchema),
  });

  useEffect(() => {
    if (error) {
      setCustomErrorMessage("Please enter a registered Email Address.");
    } else {
      setCustomErrorMessage("");
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess]);

  const onSubmit = (data: RecoveryPassword) => {
    recovery(data);
  };
  return {
    handleSubmit: handleSubmit(onSubmit),
    errors,
    control,
    customErrorMessage,
  };
};
