import React, { useEffect, useState } from "react";
import { TextField } from "../../../ui/common/TextField";
import EmailIcon from "../../../assets/icons/EmailIcon";
import PasswordIcon from "../../../assets/icons/PasswordIcon";
import { AuthWrapper } from "../components/AuthWrapper";
import { Button } from "../../../ui/common/Button";
import { Checkbox } from "../../../ui/common/Checkbox";
import { useLogin } from "../../../../hooks/useLogin";
import { Controller, appendErrors } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../store/hooks";
import { selectUser } from "../../../../store/modules/user/selectors";
import LogoIcon from "../../../assets/icons/Logo";

const Login: React.FC = () => {
  const { handleSubmit, control, error, errors } = useLogin();
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (user && user.isNew) {
      navigate("/create-password");
    }
  }, [user]);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <AuthWrapper>
      <div className="flex flex-col items-center gap-2">
        <div className="text-center w-full py-4 mb-5 flex items-center justify-center">
          <LogoIcon height={120} />
        </div>
        <h1 className="text-[32px] text-left w-full">Login to the Lender platform</h1>
        <p className="text-[16px] text-[#717984] text-left w-full mb-[20px]">
          Enter your work account information
        </p>
        <form onSubmit={handleSubmit} className="w-full">
          <Controller
            name="email"
            control={control}
            rules={{ required: "Please enter a valid email" }}
            render={({ field }) => (
              <>
                <TextField
                  icon={<EmailIcon />}
                  value={field.value}
                  label="Email Address"
                  placeholder="Email Address"
                  type="text"
                  onChange={field.onChange}
                />
                {errors.email && <p className="text-[red] text-[13px]">{errors?.email?.message}</p>}
              </>
            )}
          />
          <Controller
            name="password"
            control={control}
            rules={{ required: "Please enter a valid password" }}
            render={({ field }) => (
              <>
                <TextField
                  icon={
                    <div onClick={togglePassword}>
                      <PasswordIcon />
                    </div>
                  }
                  value={field.value}
                  label="Password"
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  onChange={field.onChange}
                />
                {errors.password && (
                  <p className="text-[red] text-[13px]">{errors?.password?.message}</p>
                )}
              </>
            )}
          />
          {/* {error && <p className="text-[red] text-[13px]">{error}</p>} */}
          <div className="flex justify-end items-center w-full my-[20px]">
            {/* <Checkbox checked={true} onChange={() => {}} label="Remember me" /> */}
            <Link to={"/recovery-password"} className="text-blue-500 font-medium text-[14px]">
              Forgot your password?
            </Link>
          </div>
          <div className="w-full">
            <Button type="submit" text="Login" onClick={() => {}} />
          </div>
        </form>
      </div>
    </AuthWrapper>
  );
};

export default Login;
