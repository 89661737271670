import { useForm } from "react-hook-form";
import {
  useLazyGetLenderUserByIdQuery,
  useUpdateLenderUserMutation,
} from "../services/rtkQuery/employee";
import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  getEmailSchema,
  getUserRolesSchema,
} from "../utill/validation/validationSchemas";
import { userRoles } from "../view/pages/Employee/components/CreateEmployeeModal";
import { Option } from "../view/ui/common/SelectBox";

const lenderUserSchema = Yup.object().shape({
  fullName: Yup.string().required("Please enter full name"),
  email: getEmailSchema(),
  password: Yup.string().min(6, "Please enter at least 6 characters"),
  role: getUserRolesSchema(),
});

export interface lenderUserRequest {
  fullName: string;
  password?: string;
  email: string;
  role?: Option;
}

export const useEditLender = (id: number, onSuccess: () => void) => {
  const [getLenderUserById] = useLazyGetLenderUserByIdQuery();
  const [updateLenderUser, { isSuccess }] = useUpdateLenderUserMutation();
  let defaultLenderValues: any = useRef();

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      fullName: "",
      email: "",
      password: "",
      role: { value: "", label: "" },
    },
    resolver: yupResolver(lenderUserSchema),
  });

  useEffect(() => {
    getLenderUserById({ id }).then((data: any) => {
      if (data?.data) {
        const lender = data?.data;
        let role;
        const newValues = {
          fullName: lender.data?.fullName,
          email: lender.data?.email,
        };
        if (lender.data?.role) {
          role = userRoles.find(
            (item) =>
              item.value.toLowerCase() === lender.data?.role?.toLowerCase()
          );
        }
        reset({
          ...newValues,
          role,
        });
      }
      defaultLenderValues.current = getValues();
    });
  }, [id]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Carewolf was updated");
      onSuccess();
    }
  }, [isSuccess]);

  const onSubmit = (data: lenderUserRequest) => {
    const isChanged =
      JSON.stringify(defaultLenderValues.current) !==
      JSON.stringify(getValues());

    if (isChanged) {
      const payload: {
        userId: number;
        fullName: string;
        email: string;
        password?: string;
        role?: string;
      } = {
        userId: id,
        fullName: data.fullName,
        email: data.email,
        role: data?.role?.value,
      };

      if (data.password) {
        payload.password = data.password;
      }

      updateLenderUser(payload);
    } else {
      toast.info("You didn't change any fields");
    }
  };

  return {
    handleSubmit: handleSubmit(onSubmit),
    errors,
    control,
  };
};
