import { FC } from "react";
import { Portal } from "../Portal";
import CropIcon from "../../assets/icons/CropIcon";

interface ModalProps {
  children: React.ReactNode;
  onClose: () => void;
  isOpened: boolean;
}

export const Modal: FC<ModalProps> = ({ children, onClose, isOpened }) => {
  if (!isOpened) {
    return null;
  }
  return (
    <Portal>
      <div className="fixed top-0 left-0 right-0 bottom-0 p-9 box-border z-50 flex justify-center items-center">
        <div
          className="absolute top-0 left-0 right-0 bottom-0 cursor-pointer bg-black bg-opacity-60"
          role="button"
          tabIndex={0}
          onClick={onClose}
        />
        {children}
      </div>
    </Portal>
  );
};
