import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { logout } from "../store/modules/user";
import { useResetPasswordMutation } from "../services/rtkQuery/user";
import { useEffect } from "react";
import { useNavigate } from "react-router";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Minimum 8 characters required")
    .matches(/[A-Z]/, "At least one uppercase letter is required")
    .matches(/\d/, "At least one digit is required")
    .matches(/[!@#%^&*]/, "At least one of the symbols !@#%%^&* is required"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password")], "Passwords must match"),
});

export interface CreatePassword {
  password: string;
  confirmPassword: string;
}

export const useCreatePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [resetPassword] = useResetPasswordMutation();

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(passwordSchema),
  });

  const onSubmit = (data: CreatePassword) => {
    resetPassword(data).then(() => {
      navigate("/success-password");
      dispatch(logout());
    });
  };

  return {
    handleSubmit: handleSubmit(onSubmit),
    errors,
    control,
    watch,
  };
};
