import React from "react";

const DocumentsIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M18.0027 10.4993V8.32635C18.0027 7.79613 17.7916 7.28692 17.4164 6.91176L14.5872 4.08258C14.2121 3.70743 13.7029 3.49634 13.1726 3.49634H5.99766C4.8922 3.49634 3.99683 4.39171 3.99683 5.49717V19.503C3.99683 20.6085 4.8922 21.5038 5.99766 21.5038H9.99933"
        stroke="#4D5187"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.0026 8.49842H14.0009C13.4487 8.49842 13.0005 8.05023 13.0005 7.498V3.49634"
        stroke="#4D5187"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.5021 22.5042C14.0161 22.5042 12.0002 20.4884 12.0002 18.0024C12.0002 15.5163 14.0161 13.5005 16.5021 13.5005C18.9892 13.5005 21.004 15.5163 21.004 18.0024C21.004 20.4884 18.9892 22.5042 16.5021 22.5042"
        stroke="#4D5187"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.5019 16.2065V19.797"
        stroke="#4D5187"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.2978 18.0024H14.7073"
        stroke="#4D5187"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default DocumentsIcon;
