import { useForm } from "react-hook-form";
import { useAppSelector } from "../store/hooks";
import { selectLenderUsers } from "../store/modules/employee/selectors";
import { useGetLenderUsersQuery } from "../services/rtkQuery/employee";
import { Option } from "../view/ui/common/SelectBox";
import { useMemo } from "react";
import { getOptions } from "../utill/helpers";
import { useCreateOrganizationsMutation } from "../services/rtkQuery/organization";
import moment from "moment";
import { selectBorrowerUser } from "../store/modules/borrower/selectors";
import { useGetBorrowerUserQuery } from "../services/rtkQuery/borrower";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  getEmailSchema,
  getGeneralSelectBoxSchema,
} from "../utill/validation/validationSchemas";

const organizationSchema = Yup.object().shape({
  name: Yup.string().required("Please enter school partner"),
  email: getEmailSchema(),
  fiscalYearEnd: getGeneralSelectBoxSchema("Fiscal Year End is required"),
});

const defaultValues = {
  name: "",
  fullName: "",
  email: "",
  description: "",
  fiscalYearEnd: { value: "", label: "" },
  assigned_user_id: { value: "", label: "" },
  userIds: [{ value: "", label: "" }],
};
export const useCreateOrganization = (closeModal: () => void) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues,
  });
  useGetLenderUsersQuery({ getAll: true });
  useGetBorrowerUserQuery({ getAll: true });

  const { rows }: any = useAppSelector(selectLenderUsers);
  const borrowerContacts = useAppSelector(selectBorrowerUser);

  const borrowerContactsOptions: Option[] = useMemo(() => {
    if (borrowerContacts?.rows) {
      return getOptions(borrowerContacts.rows, "fullName", "id");
    }
    return [];
  }, [borrowerContacts]);

  const [createOrganization] = useCreateOrganizationsMutation();

  const options: Option[] = useMemo(() => {
    if (rows) {
      return getOptions(rows, "fullName", "id");
    }
    return [];
  }, [rows]);
  const onSubmit = (data: typeof defaultValues) => {
    const payload: {
      name: string;
      description: string;
      fiscalYearEnd: string;
      email?: string;
      fullName?: string;
      userIds?: string[];
    } = {
      name: data.name,
      description: data.description,
      fiscalYearEnd: data.fiscalYearEnd.value,
      // fiscalYearStart: moment(date).format("YYYY-MM-DD"),
    };

    if (data.email && data.fullName) {
      payload.email = data.email;
      payload.fullName = data.fullName;
    } else {
      payload.userIds = data.userIds.map((user) => user.value);
    }

    createOrganization(payload).then(() => {
      closeModal();
    });
  };
  return {
    control,
    errors,
    handleSubmit: handleSubmit(onSubmit),
    options,
    borrowerContactsOptions,
    setValue,
    reset,
  };
};
