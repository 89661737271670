import React, { FC } from "react";

import { Control, Controller } from "react-hook-form";
import { Modal } from "../../../components/Modal";
import CropIcon from "../../../assets/icons/CropIcon";
import { TextField } from "../../../ui/common/TextField";
import UserIcon from "../../../assets/icons/UserIcon";
import EmailIcon from "../../../assets/icons/EmailIcon";
import { Option, SelectBox } from "../../../ui/common/SelectBox";
import { Button } from "../../../ui/common/Button";
import { Switcher } from "../../../ui/common/Switch";
import { useCreateBorrowerUser } from "../../../../hooks/useCreateBorrowerUser";
import { userRoles } from "../../Employee/components/CreateEmployeeModal";
import { useAppSelector } from "../../../../store/hooks";
import { selectUser } from "../../../../store/modules/user/selectors";

interface CreateBorrowerModalProps {
  isOpenModal: boolean;
  control: Control | any;
  closeModal: () => void;
  handleSubmit: () => void;
  action: string;
  errors?: any;
  organizationOptions: Option[];
}

export const CreateBorrowerModal: FC<CreateBorrowerModalProps> = ({
  isOpenModal,
  closeModal,
  control,
  handleSubmit,
  action,
  errors,
  organizationOptions,
}) => {
  const isCreation = action === "create";

  const user = useAppSelector(selectUser);
  const isBorrowerAdmin = user?.type === "borrower_admin";

  return (
    <Modal isOpened={isOpenModal} onClose={closeModal}>
      <div className="relative bg-[#FFF] p-[48px] rounded-[32px] w-[520px]">
        <div className=" flex justify-end">
          <div className="cursor-pointer" onClick={closeModal}>
            <CropIcon />
          </div>
        </div>
        <div className="mb-[16px]">
          <p className="text-[#474747] text-[32px] folt-medium">
            {isCreation ? "New" : "Edit"} School Partner Contact
          </p>
          <p className="text-[#717984] text-[16px] folt-medium">
            {isCreation ? "Enter" : "Change"} contact work account information
          </p>
        </div>
        <form onSubmit={handleSubmit} className="gap-2">
          <Controller
            control={control}
            name="fullName"
            rules={{ required: "Full Name is required" }}
            render={({ field }) => (
              <>
                <TextField
                  icon={<UserIcon />}
                  value={field.value}
                  label="Full Name"
                  placeholder="Full Name"
                  type="text"
                  onChange={field.onChange}
                />
                {errors?.fullName && (
                  <p className="text-[red] text-[13px]">
                    {errors?.fullName?.message}
                  </p>
                )}
              </>
            )}
          />

          <Controller
            control={control}
            name="email"
            rules={{ required: "Email is required" }}
            render={({ field }) => (
              <>
                <TextField
                  icon={<EmailIcon />}
                  value={field.value}
                  label="Email Address"
                  placeholder="Email"
                  type="text"
                  onChange={field.onChange}
                />
                {errors?.email && (
                  <p className="text-[red] text-[13px]">
                    {errors?.email?.message}
                  </p>
                )}
              </>
            )}
          />

          <Controller
            control={control}
            name="password"
            rules={{ required: "Password is required" }}
            render={({ field }) => (
              <>
                <TextField
                  value={field.value}
                  label="Password"
                  placeholder="Password"
                  type="text"
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                />
                {errors?.password && (
                  <p className="text-[red] text-[13px]">
                    {errors?.password?.message}
                  </p>
                )}
              </>
            )}
          />

          <div className="gap-[23px]">
            <Controller
              name="role"
              control={control}
              render={({ field }) => (
                <>
                  <SelectBox
                    label="Borrower Role"
                    placeholder="Contact Administrator"
                    value={field.value}
                    onChange={field.onChange}
                    options={userRoles}
                    multiple={false}
                    searchEnabled={false}
                  />
                  {errors?.role?.value && (
                    <p className="text-[red] text-[13px]">
                      {errors?.role.value.message}
                    </p>
                  )}
                </>
              )}
            />

            {isBorrowerAdmin ? (
              ""
            ) : (
              <div className="mt-2">
                <Controller
                  name="organization"
                  control={control}
                  render={({ field }) => (
                    <>
                      <SelectBox
                        label="School partner"
                        placeholder="Select School partner"
                        value={field.value}
                        onChange={field.onChange}
                        options={organizationOptions}
                        multiple={false}
                      />
                      {errors?.organization?.value && (
                        <p className="text-[red] text-[13px]">
                          {errors?.organization.value.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
            )}

            <div className="w-full text-[15.5px] mt-[24px] folt-bold">
              <Button
                type="submit"
                text={isCreation ? "Create and Send Access" : "Update"}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};
