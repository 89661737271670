import { CreatePassword } from "../../../hooks/useCreatePassword";
import { RecoveryPassword } from "../../../hooks/useRecoveryPassword";
import { rtkQuery } from "../../rtkQuery";
import { AuthResponse, SignInVariables } from "./types";

export const userApi = rtkQuery.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    login: builder.mutation<AuthResponse, SignInVariables>({
      query: (data) => {
        return {
          url: "/users/login",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };
      },
    }),
    resetPassword: builder.mutation<AuthResponse, CreatePassword>({
      query: (data) => {
        return {
          url: "/users/reset-password",
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };
      },
    }),
    forgotPassword: builder.mutation<AuthResponse, RecoveryPassword>({
      query: (data) => {
        return {
          url: "/users/forgot-password",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };
      },
    }),
  }),
});

export const { useLoginMutation, useResetPasswordMutation, useForgotPasswordMutation } = userApi;
