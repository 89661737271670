import React, { FC, useCallback, useEffect, useState } from "react";
import moment from "moment";
import EyeIcon from "../../../assets/icons/EyeIcon";
import EditIcon from "../../../assets/icons/EditIcon";
import TrashIcon from "../../../assets/icons/TrashIcon";
import DownloadIcon from "../../../assets/icons/DownloadIcon";
import { Document } from "../../../../store/modules/documents/types";
import { useDeleteDocumentMutation } from "../../../../services/rtkQuery/documents";
import { useEditDocument } from "../../../../hooks/useEditDocuments";
import { CreateDocumentModal } from "./CreateDocumentModal";
// import fileDownload from "js-file-download";
import { prepareDownloadAttributes } from "../../../../utill/helpers";

import { toast } from "react-toastify";
import { DeleteConfirmationModal } from "../../../components/DeleteConfirmationModal";

interface DocumentsListTableRowProps {
  documentItem: Document;
}

export const DocumentsListTableRow: FC<DocumentsListTableRowProps> = ({ documentItem }) => {
  const [isOpenEditOrganizationModal, setIsOpenOrganizationModal] = useState(false);
  const [file, setFile] = useState({
    url: documentItem.url,
    name: documentItem.title,
  });
  const [showDelete, setShowDelete] = useState(false);

  const { href, download } = prepareDownloadAttributes(documentItem);

  const openEditModal = () => {
    setIsOpenOrganizationModal(true);
  };
  const closeModal = () => {
    setIsOpenOrganizationModal(false);
  };

  const { control, handleSubmit, categoriesOptions, organizationOptions, errors } = useEditDocument(
    documentItem.id,
    closeModal
  );

  const [deleteDocument, { isSuccess: isDeleteDocSuccess }] = useDeleteDocumentMutation();

  const onDelete = () => {
    deleteDocument(documentItem.id);
  };

  const openInNewTab = () => {
    if (documentItem.url) {
      window.open(documentItem.url, "_blank", "noreferrer");
    }
  };

  useEffect(() => {
    if (isDeleteDocSuccess) {
      toast.success("Document was deleted");
    }
  }, [isDeleteDocSuccess]);

  // const downloadFile = useCallback(() => {
  //   if (documentItem.url) {
  //     const fileExtension = documentItem.url.split(".").pop();
  //     fileDownload(documentItem.url, documentItem.title + `.${fileExtension}`);
  //   }
  // }, [documentItem]);

  return (
    <tr className="flex w-full justify-between gap-[10px] my-3">
      <td className="flex items-center gap-1 w-[12%]">
        <div className="flex flex-col items-start ml-2">
          <p className="text-[15px] font-medium text-[#474747] text-left">{documentItem.title}</p>
        </div>
      </td>
      <td className="flex items-center justify-left gap-1 w-[12%]">
        <p className="text-left  text-[15px] text-[#474747]">{documentItem?.Category?.name}</p>
      </td>
      <td className="flex items-center justify-left gap-1 w-[18%]">
        <p className="text-left  text-[15px] text-[#474747]">{documentItem.Organization?.name}</p>
      </td>
      <td className="flex items-center justify-left gap-1 w-[12%]">
        <p className="text-start  text-[15px] text-[#474747] w-full">
          <p>{documentItem?.Organization?.Users?.[0]?.fullName}</p>
        </p>
      </td>
      <td className="flex items-center justify-left gap-1 w-[12%]">
        <p className="text-start  text-[15px] text-[#474747]">
          {documentItem.Organization?.AssignedUser?.fullName}
        </p>
      </td>
      <td className="flex items-center justify-left gap-1 w-[10%]">
        <p className="text-start text-[15px] w-full text-[#474747]">
          {moment(documentItem.createdAt).format("LL")}
        </p>
      </td>
      <td className="flex items-center justify-left gap-1 w-[8%]">
        <p className="text-start text-[15px] w-full text-[#474747]">{documentItem.status}</p>
      </td>
      <td className="flex items-center justify-end gap-2 w-[12%]">
        <button
          onClick={openInNewTab}
          className="flex w-[30px] h-[30px] p-1 justify-center items-center gap-8 bg-[#EE8C58] rounded-lg"
        >
          <EyeIcon />
        </button>
        <button
          onClick={openEditModal}
          className="flex  w-[30px] h-[30px] p-1 justify-center items-center gap-8 bg-[#434784] rounded-lg"
        >
          <EditIcon />
        </button>
        <a
          href={href}
          download={download || undefined}
          target="_blank"
          rel="noopener noreferrer"
          // onClick={downloadFile}
          className="flex  w-[30px] h-[30px] p-1 justify-center items-center gap-8 bg-[#434784] rounded-lg"
        >
          <DownloadIcon />
        </a>
        <button
          onClick={() => setShowDelete(true)}
          className="flex w-[30px] h-[30px] p-1 justify-center items-center gap-8 bg-[#E04D4D] rounded-lg"
        >
          <TrashIcon />
        </button>
      </td>
      {showDelete && (
        <DeleteConfirmationModal
          isOpenModal={showDelete}
          onDelete={onDelete}
          title="Confirmation of Document Deletion"
          text="Are you sure you want to delete this document? Deletion is an irreversible action and will result in the removal of all saved data for this document. Please confirm your decision."
          closeModal={() => setShowDelete(false)}
        />
      )}
      {isOpenEditOrganizationModal && (
        <CreateDocumentModal
          isOpenModal={isOpenEditOrganizationModal}
          control={control}
          handleSubmit={handleSubmit}
          categoriesOptions={categoriesOptions}
          organizationOptions={organizationOptions}
          closeModal={closeModal}
          action="edit"
          file={file}
          setFile={setFile}
          errors={errors}
        />
      )}
    </tr>
  );
};
