import React, { FC, useRef, useState } from "react";
import DocumentsIcon from "../../assets/icons/DocumentsIcon";
import FilesIcon from "../../assets/icons/FilesIcon";
import TrashIcon from "../../assets/icons/TrashIcon";

interface FileInputProps {
  changeFile: (file: any) => void;
  file: any;
  disabled: boolean;
  isFileLoading?: boolean;
}

export const FileInput: FC<FileInputProps> = ({ changeFile, file, disabled, isFileLoading }) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput?.current?.click();
    }
  };

  const handleChange = (event: any) => {
    const fileUploaded = event.target.files[0];

    changeFile(fileUploaded);
    setIsFileUploaded(!!fileUploaded);
  };

  const onFileDelete = () => {
    changeFile(null);
    setIsFileUploaded(!isFileUploaded);
  };

  return (
    <div
      className={`border-2 ${
        isFileUploaded || file?.name ? "p-[20px]" : "p-[60px]"
      } border-dashed ${disabled ? "border-[lightgray]" : "border-[#4D5187]"} `}
      onClick={() => (!isFileUploaded && !disabled ? handleClick() : () => {})}
    >
      <div
        className={`flex flex-col ${
          isFileUploaded || file?.name ? "items-start" : "items-center"
        } justify-center`}
      >
        {!isFileUploaded && !file ? (
          <>
            <DocumentsIcon />
            <p className="text-[15px] text-[#474747] flex flex-col items-center mt-[16px]">
              Browse your document here or
              <p className={`text-[#4D5187] font-medium`}>click to browse</p>
            </p>
          </>
        ) : (
          <div className="flex justify-between w-full">
            <div className="flex">
              <FilesIcon />
              <div className="ml-[5px]">
                {file && (
                  <>
                    <p className={`${disabled && "text-gray-300"}`}>{file.name}</p>
                    {file.size && <p>{file.size}Kb</p>}
                  </>
                )}

                <p className="text-[#95B7A2]">{isFileLoading ? "Loading process..." : ""}</p>
              </div>
            </div>
            {!disabled && (
              <div
                onClick={() => {
                  changeFile(null);
                  setIsFileUploaded(false);
                }}
              >
                <TrashIcon fill={"#717984"} />
              </div>
            )}
          </div>
        )}
        <input type="file" className="hidden" ref={hiddenFileInput} onChange={handleChange} />
      </div>
    </div>
  );
};
