import React from "react";

const CroupPlusIcon: React.FC = () => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20.75" cy="20" r="20" fill="#F1F3F8" />
      <circle
        cx="20.75"
        cy="20"
        r="19.5"
        stroke="#4D5187"
        stroke-opacity="0.4"
        stroke-dasharray="5 5"
      />
      <path
        d="M20.75 14.1667V25.8334"
        stroke="#AFB2CB"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.9167 20H26.5834"
        stroke="#AFB2CB"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CroupPlusIcon;
