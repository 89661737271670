import { useEffect, useRef, useState } from "react";
import AvatarIcon from "../../assets/icons/AvatarIcon";
import { UserSetting } from "./UserSetting";
import ChevronIcon from "../../assets/icons/ChevronIcon";

export const Avatar = () => {
  const [showSetting, setShowSetting] = useState(false);

  const toggleShowSetting = (e: any) => {
    setShowSetting(!showSetting);
  };

  const ref = useRef<HTMLDivElement>(null);

  const onPopUpClick = (e: Event) => {
    if (ref?.current && !ref.current.contains(e.target as Node)) {
      setShowSetting(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", onPopUpClick);

    return () => {
      window.removeEventListener("click", onPopUpClick);
    };
  }, []);

  return (
    <div className="relative" ref={ref}>
      <div
        className="flex items-center gap-2 cursor-pointer"
        onClick={toggleShowSetting}
      >
        <AvatarIcon width={"30px"} height={"30px"} />
        <ChevronIcon />
      </div>

      {showSetting && (
        <div className="absolute top-5 right-0">
          <UserSetting />
        </div>
      )}
    </div>
  );
};
