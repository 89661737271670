import { useEffect, useState } from "react";

import { useLazyGetBorrowerDocumentsQuery } from "../../../services/rtkQuery/documents";

import { useAppSelector } from "../../../store/hooks";
import { selectBorrowerDocuments } from "../../../store/modules/documents/selectors";

import { Container } from "../../components/Container";
import { Button } from "../../ui/common/Button";
import { Option, SelectBox } from "../../ui/common/SelectBox";

import { BorrowerDocumentsFilters } from "./components/BorrowerDocumentsFilters";
import { BorrowerDocumentsListTableHeader } from "./components/BorrowerDocumentsListTableHeader";

import { BorrowerDocumentsListTable } from "./components/BorrowerDocumentsListTable";
import { BorrowerDocumentsListTableBody } from "./components/BorrowerDocumentsListTableBody";
import Pagination from "../../ui/common/Pagination";
import { numberPageUser } from "../../../utill/contants";
import { CreateBorrowerDocumentModal } from "./components/СreateBorrowerDocumentModal";
import { useCreateBorrowerDocument } from "../../../hooks/useCreateBorroweDocument";

const BorrowerDocuments = () => {
  const [name, setName] = useState("");
  const [isOpenBorrowerModal, setIsOpenBorrowerModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    value: "",
    label: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(numberPageUser[1]);
  const [file, setFile] = useState();

  const [getBorrowerDocuments] = useLazyGetBorrowerDocumentsQuery();

  const borrowerDocuments = useAppSelector(selectBorrowerDocuments);

  useEffect(() => {
    getBorrowerDocuments({
      page: currentPage,
      limit: +itemsPerPage.value,
      title: name,
      categoryId: selectedCategory.value.length > 0 ? +selectedCategory.value : undefined,
    });
  }, [currentPage, itemsPerPage, name, selectedCategory]);

  const onChangeSelectedValue = (e: Option | Option[]) => {
    if (Array.isArray(e)) {
      return;
    }
    setSelectedCategory(e);
  };

  const openBorrowerModal = () => {
    setIsOpenBorrowerModal(true);
  };

  const closeBorrowerModal = () => {
    setIsOpenBorrowerModal(false);
  };

  const { control, handleSubmit, categoriesOptions, isFileLoading, errors } =
    useCreateBorrowerDocument(file, closeBorrowerModal);

  return (
    <div>
      <Container>
        <div>
          <div className="flex justify-between items-center w-full mb-[24px]">
            <div className="w-[90%]">
              <p className="text-[24px] text-[#474747] font-bold">Documents</p>
              <p className="text-[14px] text-[#717984] folt-medium">Manage documents</p>
            </div>
            <div className="w-[15%] text-[15.5px]">
              <Button text="+ Upload New Document" onClick={openBorrowerModal} />
            </div>
          </div>
          <div className="mb-[24px]">
            <BorrowerDocumentsFilters
              name={name}
              setName={setName}
              selectedCategory={selectedCategory}
              onChangeSelectedValue={onChangeSelectedValue}
            />
          </div>
          <div>
            <BorrowerDocumentsListTable
              HeaderComponent={BorrowerDocumentsListTableHeader}
              BodyComponent={BorrowerDocumentsListTableBody}
              documentsList={borrowerDocuments?.rows}
            />
          </div>
          <div className="flex justify-between">
            <div className="w-[70%] justify-start">
              <Pagination
                itemsPerPage={+itemsPerPage.value}
                userCount={borrowerDocuments?.count}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
              />
            </div>
            <div className="w-[30%] flex items-center justify-end gap-[16px]">
              <p className="text-[13px] text-[#717984]">Showing 1 to 5 of 50 entries</p>
              <div className="flex h-[32px] p-[10px] justify-center items-center gap-[10px]">
                <SelectBox
                  value={itemsPerPage}
                  onChange={(value: Option | Option[]) => setItemsPerPage(value as Option)}
                  options={numberPageUser}
                  placeholder="Show 8"
                  multiple={false}
                  searchEnabled={false}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
      {isOpenBorrowerModal && (
        <CreateBorrowerDocumentModal
          isOpenModal={isOpenBorrowerModal}
          closeModal={closeBorrowerModal}
          action="create"
          control={control}
          handleSubmit={handleSubmit}
          categories={categoriesOptions}
          file={file}
          setFile={setFile}
          isFileLoading={isFileLoading}
          errors={errors}
        />
      )}
    </div>
  );
};

export default BorrowerDocuments;
