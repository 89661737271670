import React from "react";

const SchoolIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.70304 4.59499L3.52904 8.02499C2.23104 8.74599 2.23104 10.612 3.52904 11.333L9.70304 14.763C11.131 15.556 12.868 15.556 14.297 14.763L20.471 11.333C21.769 10.612 21.769 8.74599 20.471 8.02499L14.297 4.59499C12.868 3.80199 11.132 3.80199 9.70304 4.59499Z"
        stroke="#4D5187"
        stroke-width="1.419"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.99109 12.7V16.624C5.99109 17.573 6.46609 18.458 7.25509 18.983L8.86209 20.052C10.7631 21.316 13.2371 21.316 15.1371 20.052L16.7441 18.983C17.5341 18.458 18.0081 17.572 18.0081 16.624V12.7"
        stroke="#4D5187"
        stroke-width="1.4167"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SchoolIcon;
