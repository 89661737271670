import { FC, useMemo, useState } from "react";
import { Modal } from "../../../../components/Modal";
import { useLendRegister } from "../../../../../hooks/useLendRegister";
import { Button } from "../../../../ui/common/Button";
import CropIcon from "../../../../assets/icons/CropIcon";
import { Option, SelectBox } from "../../../../ui/common/SelectBox";
import { BorrowerItem } from "../BorrowerItem";
import {
  useAssignUserMutation,
  useGetOrganizationsQuery,
  useLazyGetOrganizationsQuery,
} from "../../../../../services/rtkQuery/organization";
import { useAppSelector } from "../../../../../store/hooks";
import { selectOrganizationUsers } from "../../../../../store/modules/organization/selectors";
import { getOptions } from "../../../../../utill/helpers";

interface AssingToCharterModalProps {
  isOpenModal: boolean;
  closeModal: () => void;
  userId: string | undefined;
}
export const AssingToCharterModal: FC<AssingToCharterModalProps> = ({
  isOpenModal,
  closeModal,
  userId,
}) => {
  const [selectedValue, setSelectedValue] = useState<Option>({
    label: "",
    value: "",
  });
  const organizationList = useAppSelector(selectOrganizationUsers);
  useGetOrganizationsQuery({ getAll: true });
  const [assignOrganization] = useAssignUserMutation();

  const options = useMemo(() => {
    if (organizationList && organizationList.rows) {
      return getOptions(organizationList?.rows, "name", "id");
    }
    return [];
  }, [organizationList?.rows]);

  const onChangeSelectedValues = (event: Option | Option[]) => {
    setSelectedValue(event as Option);
  };

  const removeItem = () => {
    setSelectedValue({ label: "", value: "" });
  };

  const assignUserOrganization = () => {
    assignOrganization({
      userId: userId,
      organizationId: selectedValue.value,
    }).then(() => {
      closeModal();
    });
  };

  return (
    <Modal isOpened={isOpenModal} onClose={closeModal}>
      <div className="relative bg-[#FFF] rounded-[32px] w-[520px] mb-[23px]">
        <div className=" flex justify-end">
          <div className="cursor-pointer mt-5 mr-5" onClick={closeModal}>
            <CropIcon />
          </div>
        </div>
        <div className="p-[48px] pt-[24px]">
          <div className="w-full mb-[23px]">
            <p className="text-[32px] text-center">Assign to School Partner</p>
          </div>
          {selectedValue.value && <BorrowerItem value={selectedValue} removeItem={removeItem} />}

          <div className="mb-[24px] mt-[8px]">
            <SelectBox
              value={selectedValue}
              options={options}
              onChange={onChangeSelectedValues}
              placeholder="Select a School Partner"
              multiple={false}
            />
          </div>
          <div>
            <Button
              type="button"
              text="Assign"
              className="text-[15px]"
              onClick={assignUserOrganization}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
