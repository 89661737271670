import SortIcon from "../../../assets/icons/SortIcon";
import { Checkbox } from "../../../ui/common/Checkbox";

export const DocumentsListTableHeader = () => {
  return (
    <thead className="flex w-full justify-between gap-[10px]">
      <th className="flex items-center gap-1 w-[12%] py-3">
        <p className="text-center text-[15px]">Document Title</p>
        <SortIcon />
      </th>
      <th className="flex justify-start items-center gap-1 w-[12%] py-3">
        <p className="text-center text-[15px]">Category</p>
      </th>
      <th className="flex justify-start items-center gap-1 w-[18%] py-3">
        <p className="text-center text-[15px]">Charter School Partner</p>
        <SortIcon />
      </th>
      <th className="flex justify-start items-center gap-1 w-[14%] py-3">
        <p className="text-center text-[15px]">Primary School Contact</p>
        <SortIcon />
      </th>
      <th className="flex justify-start items-center gap-1 w-[12%] py-3">
        <p className="text-center text-[15px]">Carewolf</p>
        <SortIcon />
      </th>
      <th className="flex justify-start items-center gap-1 w-[12%] py-3">
        <p className="text-center text-[15px]">Date Upload</p>
        <SortIcon />
      </th>
      <th className="flex justify-start items-center gap-1 w-[8%] py-3">
        <p className="text-right text-[15px]">Status</p>
        <SortIcon />
      </th>
      <th className="flex justify-end items-center w-[12%] py-3">
        <p className="text-right text-[15px]">Action</p>
      </th>
    </thead>
  );
};
