import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { OrganizationState } from "./types";
import {
  GetOrganizationByIdResponse,
  GetOrganizationResponse,
} from "../../../services/rtkQuery/organization/types";
import { organizationApi } from "../../../services/rtkQuery/organization";

export const initialState: OrganizationState = {
  organizationUsers: null,
  organizationById: null,
};

const organizationsSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addMatcher(
      organizationApi.endpoints.getOrganizations.matchFulfilled,
      (state, { payload }: PayloadAction<GetOrganizationResponse>) => {
        state.organizationUsers = payload && payload.data.organizationUsers;
      }
    );
    builder.addMatcher(
      organizationApi.endpoints.getOrganizationsById.matchFulfilled,
      (state, { payload }: PayloadAction<GetOrganizationByIdResponse>) => {
        state.organizationById = payload.data.organization;
      }
    );
  },
});

export const organizationsReducer = organizationsSlice.reducer;
export const {} = organizationsSlice.actions;
