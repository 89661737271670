import CropIcon from "../../../../assets/icons/CropIcon";

export const BorrowerItem = ({
  value,
  removeItem,
}: {
  value: { value: string; label: string };
  removeItem: () => void;
}) => {
  return (
    <div className="flex justify-between pt-[5px] pb-[5px] px-[10px] border-x border-y border-[#EAEAEA] rounded ">
      <div>{value.label}</div>
      <div onClick={removeItem}>
        <CropIcon />
      </div>
    </div>
  );
};
