import React, { FC } from "react";

interface AuthWrapperProps {
  children: React.ReactNode;
}
export const AuthWrapper: FC<AuthWrapperProps> = ({ children }) => {
  return (
    <div className="bg-image w-full h-[100vh] backgroundImage bg-cover bg-center flex items-center justify-center">
      <div className="bg-white w-[520px] p-[48px] rounded-[32px]">
        {children}
      </div>
    </div>
  );
};
