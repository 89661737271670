import React, { FC } from "react";
import { EmployeeDetailsTableRow } from "./EmployeeDetailsTableRow";
import { Organization } from "../../../../store/modules/employee/types";

interface EmployeeDetailsTableBodyProps {
  organizationList: Organization[];
}

export const EmployeeDetailsTableBody: FC<EmployeeDetailsTableBodyProps> = ({
  organizationList,
}) => {
  return (
    <tbody className="w-full gap-[10px] items-center text-center">
      {(organizationList || []).map((oranization: Organization) => (
        <EmployeeDetailsTableRow organization={oranization} />
      ))}
    </tbody>
  );
};
