import React from "react";

import { useLocation } from "react-router";
import { ToastContainer } from "react-toastify";

import RootRouter from "./router";
import Header from "./view/components/Header";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";

const App: React.FC = () => {
  const forbiddenHeaderPaths = [
    "/login",
    "/recovery-password",
    "/create-password",
    "/success-password",
  ];

  const location = useLocation();

  return (
    <>
      {forbiddenHeaderPaths.includes(location.pathname) ? "" : <Header />}
      <RootRouter />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default App;
