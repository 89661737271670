import React, { FC, useEffect, useMemo } from "react";
import { SearchInput } from "../../../components/SearchInput";
import { Option, SelectBox } from "../../../ui/common/SelectBox";
import DataPiker from "../../../components/DatePicker";
import moment from "moment";
import { useAppSelector } from "../../../../store/hooks";
import { selectLenderUsers } from "../../../../store/modules/employee/selectors";
import { getOptions } from "../../../../utill/helpers";
import { useLazyGetLenderUsersQuery } from "../../../../services/rtkQuery/employee";
import { useLazyGetBorrowerUserQuery } from "../../../../services/rtkQuery/borrower";
import { selectBorrowerUser } from "../../../../store/modules/borrower/selectors";

interface CharterOrganizationFiltersProps {
  value: string;
  fiscalYearEnd: Date | "";
  setFiscalYearEnd: (date: any) => void;
  setValue: (value: string | number) => void;
  employeeId: Option;
  setEmployeeId: (value: Option) => void;
  onBorrowerChange: (value: Option | Option[]) => void;
  selectedBorrower: Option | undefined;
}

export const CharterOrganizationFilters: FC<CharterOrganizationFiltersProps> = ({
  value,
  fiscalYearEnd,
  setFiscalYearEnd,
  setValue,
  employeeId,
  setEmployeeId,
  onBorrowerChange,
  selectedBorrower,
}) => {
  const [getLenderUser] = useLazyGetLenderUsersQuery();
  const [getBorrowerUsers] = useLazyGetBorrowerUserQuery();

  const { rows }: any = useAppSelector(selectLenderUsers);
  const borrowersUser = useAppSelector(selectBorrowerUser);

  useEffect(() => {
    getLenderUser({ role: "user", getAll: true });
    getBorrowerUsers({ getAll: true });
  }, []);

  const options: Option[] = useMemo(() => {
    if (rows) {
      return getOptions(rows, "fullName", "id");
    }
    return [];
  }, [rows]);

  const borrowerOptions: Option[] = useMemo(() => {
    if (borrowersUser?.rows) {
      return getOptions(borrowersUser?.rows, "fullName", "id");
    }
    return [];
  }, [borrowersUser?.rows]);

  const onChangeSelectedValue = (e: Option | Option[]) => {
    if (Array.isArray(e)) {
      return;
    }
    setEmployeeId(e);
  };

  return (
    <div className="flex justify-between items-center gap-[16px]">
      <div className="w-[70%]">
        <SearchInput
          value={value}
          placeholder="Search School Partners"
          type="text"
          onChange={setValue}
          icon={false}
        />
      </div>
      <div className="w-[30%] flex justify-between ">
        <div className="w-[100%] flex justify-between gap-[16px]">
          <SelectBox
            value={employeeId}
            options={[{ value: "", label: "All Carewolves" }, ...options]}
            onChange={onChangeSelectedValue}
            placeholder="All Carewolves"
            multiple={false}
          />
        </div>
      </div>
    </div>
  );
};
