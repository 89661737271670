import React, { FC, useMemo } from "react";
import { SearchInput } from "../../../components/SearchInput";
import { Option, SelectBox } from "../../../ui/common/SelectBox";
import { useGetCategoriesQuery } from "../../../../services/rtkQuery/documents";
import { useAppSelector } from "../../../../store/hooks";
import { selectCategories } from "../../../../store/modules/documents/selectors";
import { getOptions } from "../../../../utill/helpers";

interface DocumentsOrganizationFiltersProps {
  name: string;
  setName: (name: string) => void;
  selectedCategory: Option | Option[];
  onChangeSelectedValue: (value: Option | Option[]) => void;
}

export const DocumentsOrganizationFilters: FC<DocumentsOrganizationFiltersProps> = ({
  name,
  setName,
  selectedCategory,
  onChangeSelectedValue,
}) => {
  useGetCategoriesQuery();

  const categories = useAppSelector(selectCategories);

  const handleChange = (value: string | number) => {
    setName(value.toString());
  };

  const categoriesOptions = useMemo(() => {
    if (categories && categories.length) {
      return getOptions(categories, "name", "id");
    }
    return [];
  }, [categories]);

  return (
    <div className="flex justify-between items-center gap-[16px]">
      <div className="w-[50%]">
        <SearchInput
          value={name}
          placeholder="Search Documents"
          type="text"
          onChange={handleChange}
          icon={false}
        />
      </div>
      <div className="w-[50%] flex justify-between gap-[16px]">
        <SelectBox
          value={selectedCategory}
          options={[{ value: "", label: "All categories" }, ...categoriesOptions]}
          onChange={onChangeSelectedValue}
          placeholder="All categories"
          multiple={false}
        />
        <SelectBox
          value={{ value: "", label: "" }}
          options={[]}
          onChange={() => {}}
          placeholder="Status"
          multiple={false}
          searchEnabled={false}
        />
      </div>
    </div>
  );
};
