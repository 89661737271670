import React, { FC } from "react";

interface UserPlaceholderProps {
  name: string;
  className?: string;
}
export const UserPlaceholder: FC<UserPlaceholderProps> = ({
  name,
  className,
}) => {
  return (
    <div
      className={
        "bg-[grey] rounded-full w-[40px] h-[40px] flex items-center justify-center border-1 border-white" +
        className
      }
    >
      <p className="uppercase text-white">{name?.split("")[0] || ""}</p>
    </div>
  );
};
