import React from "react";

const FilesIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.414 6.914L15.586 4.086C15.211 3.711 14.702 3.5 14.172 3.5H7C5.895 3.5 5 4.395 5 5.5V19.5C5 20.605 5.895 21.5 7 21.5H17C18.105 21.5 19 20.605 19 19.5V8.328C19 7.798 18.789 7.289 18.414 6.914V6.914Z"
        stroke="#4D5187"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19 8.5H15C14.448 8.5 14 8.052 14 7.5V3.5"
        stroke="#4D5187"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default FilesIcon;
