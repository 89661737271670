import React, { FC, useState } from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import { AuthWrapper } from "../components/AuthWrapper";
import { Controller } from "react-hook-form";
import { TextField } from "../../../ui/common/TextField";
import PasswordIcon from "../../../assets/icons/PasswordIcon";
import { useCreatePassword } from "../../../../hooks/useCreatePassword";
import { Button } from "../../../ui/common/Button";
import { PasswordRules } from "../components/PasswordRules";
import { Link } from "react-router-dom";

const CreatePassword: FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { handleSubmit, control, errors, watch } = useCreatePassword();

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <AuthWrapper>
      <div className="flex flex-col items-center gap-2">
        <h1 className="text-[32px] text-center w-full">Create a new password</h1>
        <p className="text-[16px] text-[#717984] text-center w-full mb-[20px]">
          You have successfully authenticated, so now create a new password
        </p>
        <form onSubmit={handleSubmit} className="w-full">
          <Controller
            name="password"
            control={control}
            rules={{ required: "Password is required" }}
            render={({ field }) => (
              <>
                <TextField
                  icon={
                    <div onClick={togglePassword}>
                      <PasswordIcon />
                    </div>
                  }
                  value={field.value}
                  label="New Password"
                  placeholder="Enter your password"
                  type={showPassword ? "text" : "password"}
                  onChange={field.onChange}
                />
                {errors.password && (
                  <p className="text-[red] text-[13px]">{errors?.password?.message}</p>
                )}
              </>
            )}
          />
          <PasswordRules password={watch().password} />
          <div className=" mt-5">
            <PasswordStrengthBar className="password-bar" password={watch().password} />
          </div>

          <Controller
            name="confirmPassword"
            control={control}
            rules={{ required: "New Password is required" }}
            render={({ field }) => (
              <>
                <TextField
                  icon={
                    <div onClick={toggleConfirmPassword}>
                      <PasswordIcon />
                    </div>
                  }
                  value={field.value}
                  label="Repeat Password"
                  placeholder="Enter your password"
                  type={showConfirmPassword ? "text" : "password"}
                  onChange={field.onChange}
                />
                {errors.confirmPassword && (
                  <p className="text-[red] text-[13px]">{errors?.confirmPassword?.message}</p>
                )}
              </>
            )}
          />
          <div className="w-full  mt-9 mb-4">
            <Button type="submit" text="Submit" />
          </div>
        </form>
      </div>
    </AuthWrapper>
  );
};

export default CreatePassword;
